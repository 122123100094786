import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { style: {"text-align":"right"} }
const _hoisted_4 = { style: {"padding":"10px"} }
const _hoisted_5 = {
  key: 0,
  style: {"padding":"20px"}
}
const _hoisted_6 = { class: "timing-totals" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_table, { "fixed-header": "" }, {
      default: _withCtx(() => [
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedPartValues, (pv) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: pv.id
            }, [
              (pv.id === 0 || pv.id === (_ctx.sortedPartValues.length - 1))
                ? (_openBlock(), _createElementBlock("td", _hoisted_1, _toDisplayString(pv.worldTime?.toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit'})), 1))
                : (_openBlock(), _createElementBlock("td", _hoisted_2, _cache[0] || (_cache[0] = [
                    _createElementVNode("div", null, null, -1)
                  ]))),
              _createElementVNode("td", null, _toDisplayString(pv.name), 1),
              _createElementVNode("td", _hoisted_3, [
                _createElementVNode("div", null, [
                  (_ctx.doneSteps[pv.stateName])
                    ? (_openBlock(), _createBlock(_component_v_icon, {
                        key: 0,
                        color: "green",
                        class: "mr-3",
                        icon: "mdi-check"
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_btn, {
                    color: "info",
                    icon: "mdi-circle-edit-outline",
                    density: "compact",
                    variant: "plain",
                    onClick: ($event: any) => (_ctx.openCurrentModal(pv))
                  }, null, 8, ["onClick"])
                ]),
                _createVNode(_component_v_dialog, {
                  modelValue: pv.showModal,
                  "onUpdate:modelValue": ($event: any) => ((pv.showModal) = $event),
                  style: {"margin-top":"48px"},
                  contained: "",
                  fullscreen: "",
                  "no-click-animation": "",
                  persistent: "",
                  scrim: false,
                  transition: "dialog-bottom-transition"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_toolbar, {
                          dark: "",
                          color: "white"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_toolbar_title, { style: {"text-align":"center"} }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(pv.name), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_btn, {
                              icon: "",
                              onClick: ($event: any) => (_ctx.closeModal(pv))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                                    _createTextVNode("mdi-close")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_list, null, {
                          default: _withCtx(() => [
                            (pv.id === 0 || pv.id === (_ctx.sortedPartValues.length - 1))
                              ? (_openBlock(), _createBlock(_component_v_list_item, { key: 0 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_VueDatePicker, {
                                      modelValue: pv.worldTime,
                                      "onUpdate:modelValue": ($event: any) => ((pv.worldTime) = $event),
                                      teleport: true,
                                      clearable: false
                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSteps(pv.stateName), (step) => {
                              return (_openBlock(), _createBlock(_component_v_list_item, {
                                key: step.scope
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(), _createBlock(_resolveDynamicComponent(`e-${step.type}`), {
                                    input: step,
                                    selected: _ctx.selected,
                                    onChange: ($event: any) => (_ctx.onChange(pv))
                                  }, null, 40, ["input", "selected", "onChange"]))
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_v_row, {
                            justify: "center",
                            align: "center"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "6" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    block: "",
                                    color: "grey",
                                    onClick: ($event: any) => (_ctx.previousModal(pv))
                                  }, {
                                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                                      _createTextVNode(" Föregående ")
                                    ])),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_col, { cols: "6" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    block: "",
                                    color: "info",
                                    onClick: ($event: any) => (_ctx.nextModal(pv))
                                  }, {
                                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                                      _createTextVNode(" Nästa ")
                                    ])),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue"])
              ])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }),
    (_ctx.trackingState.started && _ctx.trackingState.finished)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_v_tooltip, {
            text: _ctx.isValidToSave ? 'Spara detta resultatet' : 'Du behöver fylla i alla delar innan du kan spara',
            location: "bottom"
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_btn, _mergeProps(props, {
                style: {"margin-top":"10px"},
                color: "success",
                block: "",
                tooltip: "hej",
                onClick: _ctx.saveRun
              }), {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Spara ")
                ])),
                _: 2
              }, 1040, ["onClick"])
            ]),
            _: 1
          }, 8, ["text"]),
          _createElementVNode("div", _hoisted_6, " Total tid " + _toDisplayString(_ctx.timingTotals), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}