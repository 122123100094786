import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-weight-bold" }
const _hoisted_2 = { class: "pl-0 pr-2 py-2" }
const _hoisted_3 = { class: "pl-0 pr-2 py-2" }
const _hoisted_4 = { class: "uptake-summation" }
const _hoisted_5 = { class: "summation-drev" }
const _hoisted_6 = { class: "summation-tappt" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!

  return (_openBlock(), _createBlock(_component_v_expansion_panel, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_expansion_panel_title, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { "no-gutters": "" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { class: "d-flex align-center" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, "Släpp " + _toDisplayString(_ctx.release.id+1), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_expansion_panel_text, { class: "bg-white text-black" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "d-flex mt-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createElementVNode("h3", null, "Släpp", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_v_col, { class: "pb-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.release.results['release_time'],
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.release.results['release_time']) = $event)),
                    rules: [_ctx.rules.required],
                    variant: "outlined",
                    type: "time",
                    class: "time-input",
                    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.checkIfEmpty($event.target.value, 'release_time')))
                  }, null, 8, ["modelValue", "rules"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_row, { class: "d-flex mt-2 pt-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createElementVNode("h3", null, "Slag", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_v_col, { class: "pb-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.release.results['release_slag'],
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.release.results['release_slag']) = $event)),
                    rules: [
                            _ctx.generateRequiredRuleNormal('release_slag', '>', 'release_time')
                        ],
                    variant: "outlined",
                    type: "time",
                    class: "time-input",
                    onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.checkIfEmpty($event.target.value, 'release_slag')))
                  }, null, 8, ["modelValue", "rules"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_row, { class: "mt-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => _cache[17] || (_cache[17] = [
                  _createElementVNode("h3", null, "Upptag", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_v_col, { class: "d-flex justify-end" }, {
                default: _withCtx(() => [
                  _createElementVNode("h3", null, _toDisplayString(// eslint-disable-next-line max-len
                            _ctx.release.results['release_uptake']?.length > 1 ? _ctx.release.results['release_uptake'][0].from || '--:--' : '--:--'), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, { "no-gutters": "" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_table, {
                density: "compact",
                class: "mt-4",
                style: {"width":"100%"}
              }, {
                default: _withCtx(() => [
                  _cache[18] || (_cache[18] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", { class: "pl-0 pr-2" }, " Från "),
                      _createElementVNode("th", { class: "pl-0 pr-2" }, " Till "),
                      _createElementVNode("th", { class: "text-center" }, " Drev "),
                      _createElementVNode("th", { class: "text-center" }, " Tappt ")
                    ])
                  ], -1)),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.release.results['release_uptake'], (uptake, idx) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: uptake.id,
                        onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changeUptake()))
                      }, [
                        _createElementVNode("td", _hoisted_2, [
                          _createVNode(_component_v_text_field, {
                            ref_for: true,
                            ref: "fromRefs",
                            modelValue: uptake['from'],
                            "onUpdate:modelValue": ($event: any) => ((uptake['from']) = $event),
                            variant: "outlined",
                            type: "time",
                            class: "time-input",
                            "hide-details": "",
                            rules: [
                                        _ctx.generateBetweenUptakeRule(idx),
                                        uptake.id === 0 ?
                                            _ctx.generateRequiredRule(
                                                uptake['from'],
                                                _ctx.release.results['release_slag'],
                                                '>'
                                            ) : 
                                            () => true
                                    ],
                            disabled: _ctx.isInvalid(idx, 'from', uptake['from']),
                            style: {"min-width":"110px"}
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "rules", "disabled"])
                        ]),
                        _createElementVNode("td", _hoisted_3, [
                          _createVNode(_component_v_text_field, {
                            ref_for: true,
                            ref: "toRefs",
                            modelValue: uptake['to'],
                            "onUpdate:modelValue": ($event: any) => ((uptake['to']) = $event),
                            variant: "outlined",
                            type: "time",
                            class: "time-input",
                            "hide-details": "",
                            rules: [
                                        _ctx.generateRequiredRuleUptakeNormal('to', '>', 'from', idx)
                                    ],
                            disabled: _ctx.isInvalid(idx, 'to', uptake['to']),
                            style: {"min-width":"110px"}
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "rules", "disabled"])
                        ]),
                        _createElementVNode("td", {
                          style: {"text-align":"center","font-weight":"bold"},
                          class: _normalizeClass(
                                    _ctx.getChaseTime(idx) ? 'color-green' : '')
                        }, _toDisplayString(_ctx.getChaseTime(idx) || '-'), 3),
                        _createElementVNode("td", {
                          style: {"text-align":"center","font-weight":"bold"},
                          class: _normalizeClass(_ctx.droppedTimeBetweenUptake(idx) !== '-' ? 'color-red' : '')
                        }, _toDisplayString(_ctx.droppedTimeBetweenUptake(idx)), 3)
                      ], 32))
                    }), 128))
                  ])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.drevSummation), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.tapptSummation), 1)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_row, { class: "d-flex align-center mt-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "8" }, {
                default: _withCtx(() => _cache[19] || (_cache[19] = [
                  _createElementVNode("h3", null, "Sista Drevskall", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_v_col, { class: "d-flex justify-end" }, {
                default: _withCtx(() => [
                  _createElementVNode("h3", null, _toDisplayString(// eslint-disable-next-line max-len
                            _ctx.release.results['release_uptake']?.length > 1 ? _ctx.release.results['release_uptake'][_ctx.release.results['release_uptake'].length - 2].to || '--:--' : '--:--'), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          (!_ctx.eventIsTaxChase)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_v_row, { class: "d-flex mt-2" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "6" }, {
                      default: _withCtx(() => _cache[20] || (_cache[20] = [
                        _createElementVNode("h3", null, "Inkall", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { class: "pb-0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          modelValue: _ctx.release.results['recall'],
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.release.results['recall']) = $event)),
                          variant: "outlined",
                          type: "time",
                          class: "time-input",
                          rules: [
                                _ctx.rules.required,
                            ],
                          onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.checkIfEmpty($event.target.value, 'recall')))
                        }, null, 8, ["modelValue", "rules"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_divider)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_row, { class: "d-flex mt-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => _cache[21] || (_cache[21] = [
                  _createElementVNode("h3", null, "Kopplad", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_v_col, { class: "pb-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.release.results['leashed'],
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.release.results['leashed']) = $event)),
                    variant: "outlined",
                    type: "time",
                    class: "time-input",
                    rules: [
                            _ctx.rules.required,
                        ],
                    onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.checkIfEmpty($event.target.value, 'leashed')))
                  }, null, 8, ["modelValue", "rules"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          (!_ctx.eventIsTaxChase)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_v_row, { class: "d-flex mt-2" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "6" }, {
                      default: _withCtx(() => _cache[22] || (_cache[22] = [
                        _createElementVNode("h3", null, "Skallfrekvens", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { class: "pb-0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          modelValue: _ctx.release.results['bark_frequency'],
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.release.results['bark_frequency']) = $event)),
                          modelModifiers: { number: true },
                          variant: "outlined",
                          type: "number",
                          rules: [
                                _ctx.rules.bark_frequency
                            ],
                          onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.checkIfEmpty($event.target.value, 'bark_frequency')))
                        }, null, 8, ["modelValue", "rules"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_divider)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_row, { class: "d-flex align-center mt-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "8" }, {
                default: _withCtx(() => _cache[23] || (_cache[23] = [
                  _createElementVNode("h3", null, "Loss Tid", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_v_col, { class: "d-flex justify-end" }, {
                default: _withCtx(() => [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.lossTime[_ctx.index]), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_row, { class: "d-flex mt-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => _cache[24] || (_cache[24] = [
                  _createElementVNode("h3", null, "Viltslag", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_v_col, { class: "pb-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    modelValue: _ctx.release.results['gamekind'],
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.release.results['gamekind']) = $event)),
                    variant: "outlined",
                    rules: [
                            _ctx.rules.required
                        ],
                    items: _ctx.animals
                  }, null, 8, ["modelValue", "rules", "items"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_row, { class: "d-flex mt-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => _cache[25] || (_cache[25] = [
                  _createElementVNode("h3", null, "Använt pejl för inkallning?", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_v_col, { class: "pb-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    modelValue: _ctx.release.results['hunting_pejl'],
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.release.results['hunting_pejl']) = $event)),
                    variant: "outlined",
                    rules: [
                            _ctx.rules.required
                        ],
                    items: [
                            {title: 'Ja', value: 'yes'}, 
                            {title: 'Nej', value: 'no'}
                        ]
                  }, null, 8, ["modelValue", "rules"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, {
            "no-gutters": "",
            class: "d-flex align-center justify-end mt-8"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                variant: "plain",
                color: "error",
                onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.removeLetGo(_ctx.index)))
              }, {
                default: _withCtx(() => [
                  _cache[27] || (_cache[27] = _createTextVNode(" Ta bort släpp ")),
                  _createVNode(_component_v_icon, { class: "ml-1" }, {
                    default: _withCtx(() => _cache[26] || (_cache[26] = [
                      _createTextVNode(" mdi-close-circle ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                variant: "tonal",
                color: "info",
                class: "ml-2",
                onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.openPanel = []))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[28] || (_cache[28] = [
                      _createTextVNode(" mdi-chevron-up ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}