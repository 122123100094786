<template>
    <v-sheet
        class="pa-6"
    >
        <v-form
            ref="letGosForm"
            validate-on="input"
            @submit.prevent="saveReleases"
        >
            <v-expansion-panels v-model="openPanel">
                <ReleasePanel
                    v-for="(release, index) in letGos"
                    :key="release.id"
                    :release="release"
                    :index="index"
                    :open-panel="openPanel"
                    :rules="rules"
                    :loss-time="lossTime"
                    :animals="getAnimals"
                    :event-type="eventType"
                    :event-is-tax-chase="eventIsTaxChase"
                    :convert-to-secs="convertToSecs"
                    @remove-let-go="removeLetGo"
                    @check-if-empty="checkIfEmpty"
                    @change-uptake="changeUptake"
                />
            </v-expansion-panels>

            <v-btn
                class="mt-8"
                block
                color="info"
                @click="addLetGo"
            >
                Lägg till släpp
                <v-icon class="ml-1">
                    mdi-plus
                </v-icon>
            </v-btn>

            <v-btn
                class="mt-2"
                type="submit"
                block
                color="success"
                :loading="validating"
            >
                Spara
            </v-btn>
        </v-form>
    </v-sheet>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ReleasePanel from './ReleasePanel.vue';

export default defineComponent({
    name: 'ChaseInputLetGos',
    components: {
        ReleasePanel
    },
    props: {
        evaluations: {
            type: Array,
            required: true,
        },
        event: {
            type: Object,
            required: true,
        }
    },
    emits: ['updateReleases', 'saveReleases', 'formValid'],
    data() {
        return {
            validating: false,
            letGos: [
                {
                    id: 0,
                    scope: 'release_0',
                    results: {
                        release_uptake: [
                            {
                                id: 0,
                                from: '',
                                to: '',
                            } as { [key: string]: any }
                        ]
                    } as { [key: string]: any }
                }
            ],
            rules: {
                required: (value: any) => !!value || 'Fältet är obligatoriskt',
                bark_frequency: (value: number) => value >= 0 && value <= 150 || 'Fältet måste vara mellan 0 - 150'
            },
            nextLetGoId: 1,
            openPanel: null as any,
        }
    },
    computed: {
        lossTime() {
            const lossTimes = this.letGos.map((letRelease: any) => {
                const loss = Math.abs(this.convertToSecs(letRelease.results.release_time || '00:00') - this.convertToSecs(letRelease.results.leashed || '00:00'));
                const hr = Math.floor(loss / 3600);
                const min = (loss % 3600) / 60;

                return hr > 0 ? `${hr}h ${min}m` : `${min}m`;
            });

            return lossTimes;
        },

        getAnimals() {
            switch(this.eventType) {
                case 'chase_tax': {
                    return [
                        {title: 'Räv', value: 'fox'}, 
                        {title: 'Hare', value: 'hare'},
                        {title: 'Kanin', value: 'rabbit'},
                        {title: 'Rådjur', value: 'roe_deer'}, 
                        {title: 'Hjort', value: 'deer'}, 
                        {title: 'Annat', value: 'chase_other'}
                    ];
                }
                case 'chase_adb': {
                    return [
                        {title: 'Räv', value: 'fox'}, 
                        {title: 'Hare', value: 'hare'}, 
                        {title: 'Rådjur', value: 'roe_deer'}, 
                        {title: 'Hjort', value: 'deer'}, 
                        {title: 'Vildsvin', value: 'wild_boar'},
                        {title: 'Okänt', value: 'unknown'}, 
                        {title: 'Inget', value: 'none'}
                    ];
                }
                default: {
                    return [
                        {title: 'Räv', value: 'fox'}, 
                        {title: 'Hare', value: 'hare'}, 
                        {title: 'Rådjur', value: 'roe_deer'}, 
                        {title: 'Hjort', value: 'deer'}, 
                        {title: 'Okänt', value: 'unknown'}, 
                        {title: 'Inget', value: 'none'}
                    ];
                }
            }
        },

        eventType() {
            return this.event?.type || '';
        },

        eventIsTaxChase() {
            return this.eventType === 'chase_tax';
        }
    },
    watch: {
        letGos: {
            handler() {
                this.updateLetGos();
            },
            deep: true
        },
    },
    created() {
        const chase = this.evaluations.find((v: any) => v.doc_id === 'chase') as any;

        if (!chase) {
            return;
        }

        const chaseCopy = {...chase};
        delete chaseCopy.doc_id;
        delete chaseCopy.metadata;
        delete chaseCopy.path;
        delete chaseCopy.done;
        delete chaseCopy.chase_day;

        const chaseValues = Object.values(chaseCopy) as Array<{id: number, scope: string, results: Object }>;

        if (!chaseValues.length) {
            return;
        }

        // const chaseValuesSpread = [...chaseValues];
        chaseValues.sort((a, b) => a.id > b.id ? 1 : -1);
        this.letGos = chaseValues;
        this.setNextIdToHighest(chaseValues);
    },
    methods: {
        async openAll() {
            this.openPanel = Array.from(Array(this.letGos.length).keys())

            return setTimeout(() => {}, 1000);
        },

        async saveReleases() {
            await this.openAll();

            this.validating = true;
            const validity = await (this.$refs.letGosForm as any).validate();
            this.validating = false;

            if (!validity.valid) {
                console.log('form not valid');
                return;
            }

            this.$emit('saveReleases', validity);
        },

        changeUptake(
            relaseIndex: number,
            results: { [key: string]: any }
        ) {
            const resUp = this.letGos[relaseIndex].results.release_uptake;
            const latest = resUp.length - 1;

            const latestUptake = resUp.find((result: any) => result.id === latest);

            if (!latestUptake) {
                return;
            }

            if (latest >= 1) {
                const latestNotEmpty = resUp.findLast((result: any) => result.from !== '' && result.to !== '');
                
                results.last_bark = latestNotEmpty?.to || null;
            }

            if (latestUptake.from !== '' && latestUptake.to !== '') {
                
                results.release_uptake.push({
                    id: latest + 1,
                    from: '',
                    to: ''
                });
            }
        },

        updateLetGos(): void {
            this.$emit('updateReleases', this.letGos);
        },

        addLetGo(): void {
            this.letGos.push({
                id: this.nextLetGoId,
                scope: `release_${this.nextLetGoId}`,
                results: {
                    release_uptake: [
                        {
                            id: 0,
                            from: '',
                            to: '',
                        } as { [key: string]: any }
                    ]
                } as { [key: string]: any },
            });
            this.nextLetGoId += 1;
        },

        setNextIdToHighest(letGos: Array<{ id: number, scope: string, results: Object }>): void {
            const copyLetGos = [...letGos];
            const sortedByHighestId = copyLetGos.sort((a, b) => a.id < b.id ? 1 : -1);
            this.nextLetGoId = sortedByHighestId.length > 0 ? sortedByHighestId[0].id + 1 : 0;
        },

        removeLetGo(index: number): void {
            const copy = [...this.letGos];
            copy.splice(index, 1);
            this.setNextIdToHighest(copy)
            this.letGos = [...copy];
            this.updateLetGos();
        },

        convertToSecs(timeString: string): number {
            const splitTimeString = timeString.split(':');
            const hour = Number(splitTimeString[0]);
            const min = Number(splitTimeString[1]);

            const seconds = (hour * 3600) + (min * 60);
            return seconds;
        },

        checkIfEmpty(value: string, index: number, scope: string): void {
            if (value === '') {
                delete this.letGos[index].results[scope]
            }
        },
    }
});
</script>

<style>
.uptake-summation {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.summation-drev {
    margin-right: 55px;
    color: green;
    font-weight: bold;
}

.summation-tappt {
    margin-right: 30px;
    color: red;
    font-weight: bold;
}

.color-green {
    color: green;
}

.color-red {
    color: red;
}
</style>
