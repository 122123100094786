import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-8"
}
const _hoisted_2 = {
  key: 0,
  style: {"color":"green"}
}
const _hoisted_3 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 1,
  style: {"display":"flex","justify-content":"space-between"}
}
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "text-left" }
const _hoisted_9 = { class: "text-right" }
const _hoisted_10 = {
  key: 1,
  class: "mb-8"
}
const _hoisted_11 = {
  key: 0,
  style: {"color":"green"}
}
const _hoisted_12 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_13 = {
  key: 2,
  class: "mb-8"
}
const _hoisted_14 = {
  key: 0,
  style: {"color":"green"}
}
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_17 = {
  key: 1,
  style: {"display":"flex","flex-direction":"column"}
}
const _hoisted_18 = { style: {"text-align":"left"} }
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = {
  key: 3,
  class: "mb-8"
}
const _hoisted_21 = {
  key: 0,
  style: {"color":"green"}
}
const _hoisted_22 = { class: "evaluation-results" }
const _hoisted_23 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_28 = {
  key: 2,
  style: {"display":"flex","flex-direction":"column"}
}
const _hoisted_29 = { style: {"text-align":"left"} }
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = {
  key: 4,
  class: "mb-8"
}
const _hoisted_32 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_33 = {
  key: 0,
  style: {"opacity":"0.5"}
}
const _hoisted_34 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_PreviewResultsContent = _resolveComponent("PreviewResultsContent")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    align: "center",
    "max-height": "90vh"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { align: "center" }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" KONTROLLERA ")
        ])),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          (!_ctx.isNoShow)
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 0,
                align: "center"
              }, {
                default: _withCtx(() => [
                  (_ctx.controlsResults)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _cache[5] || (_cache[5] = _createElementVNode("h4", null, "Kontroll Resultat", -1)),
                        (_ctx.controlDone)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Klarmarkerat "))
                          : _createCommentVNode("", true),
                        _cache[6] || (_cache[6] = _createElementVNode("div", { style: {"display":"flex","justify-content":"space-between"} }, [
                          _createElementVNode("span", { style: {"font-weight":"bold"} }, " Värde ")
                        ], -1)),
                        _createElementVNode("div", _hoisted_3, [
                          _cache[3] || (_cache[3] = _createElementVNode("div", null, "Hundkontroll", -1)),
                          (_ctx.controlsResults.controll_status === 'not_ok')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, " Ej godkänd "))
                            : (_openBlock(), _createElementBlock("div", _hoisted_5, " Godkänd "))
                        ]),
                        (_ctx.controlsResults.reason)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                              _cache[4] || (_cache[4] = _createElementVNode("div", null, "Anledning", -1)),
                              _createElementVNode("div", null, _toDisplayString(_ctx.controlsResults.reason), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.swkForestWeather)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.swkForestWeather, (evaluation, idx) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: idx,
                                  class: "evaluation-weather",
                                  style: {"display":"flex","justify-content":"space-between"}
                                }, [
                                  _createElementVNode("div", _hoisted_8, _toDisplayString(evaluation.name), 1),
                                  _createElementVNode("div", _hoisted_9, _toDisplayString(evaluation.value), 1)
                                ]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.workingtestResults)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workingtestResults, (result) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: result.scope
                          }, [
                            _createElementVNode("h4", null, _toDisplayString(result.title), 1),
                            (result.done)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_11, " Klarmarkerat "))
                              : _createCommentVNode("", true),
                            _cache[7] || (_cache[7] = _createElementVNode("div", { style: {"display":"flex","justify-content":"space-between"} }, [
                              _createElementVNode("span", { style: {"font-weight":"bold"} }, " Rubrik "),
                              _createElementVNode("span", { style: {"font-weight":"bold"} }, " Värde ")
                            ], -1)),
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_12, [
                                _createElementVNode("div", null, [
                                  _createElementVNode("div", null, _toDisplayString(result.name), 1)
                                ]),
                                _createElementVNode("div", null, [
                                  _createElementVNode("div", null, _toDisplayString(result.value), 1)
                                ])
                              ])
                            ])
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.evaluationSteps)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _cache[8] || (_cache[8] = _createElementVNode("h4", null, "Spår Resultat", -1)),
                        (_ctx.evaluationStepDone)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_14, " Klarmarkerat "))
                          : _createCommentVNode("", true),
                        _cache[9] || (_cache[9] = _createElementVNode("div", { style: {"display":"flex","justify-content":"space-between"} }, [
                          _createElementVNode("span", { style: {"font-weight":"bold"} }, " Rubrik "),
                          _createElementVNode("span", { style: {"font-weight":"bold"} }, " Värde ")
                        ], -1)),
                        _createElementVNode("div", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.evaluationSteps, (steps, idx) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: idx,
                              class: "evaluation-results"
                            }, [
                              _createElementVNode("div", null, _toDisplayString(steps.name), 1),
                              _createElementVNode("div", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(steps.evaluation, (evals, index) => {
                                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                                    (!evals.name.includes('comment'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                          _createElementVNode("div", _hoisted_16, [
                                            _createElementVNode("div", null, _toDisplayString(evals.name), 1),
                                            _createElementVNode("div", null, _toDisplayString(evals.value), 1)
                                          ])
                                        ]))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                          _createElementVNode("div", null, [
                                            _createElementVNode("div", null, [
                                              _createElementVNode("div", null, _toDisplayString(evals.name), 1)
                                            ]),
                                            _createElementVNode("div", _hoisted_18, [
                                              _createElementVNode("div", {
                                                innerHTML: evals.value
                                              }, null, 8, _hoisted_19)
                                            ])
                                          ])
                                        ]))
                                  ]))
                                }), 128))
                              ])
                            ]))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.evaluationChase)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                        _cache[11] || (_cache[11] = _createElementVNode("h4", null, "Drev Resultat", -1)),
                        (_ctx.evaluationChaseDone)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_21, " Klarmarkerat "))
                          : _createCommentVNode("", true),
                        _cache[12] || (_cache[12] = _createElementVNode("div", { style: {"display":"flex","justify-content":"space-between"} }, [
                          _createElementVNode("span", { style: {"font-weight":"bold"} }, " Rubrik "),
                          _createElementVNode("span", { style: {"font-weight":"bold"} }, " Värde ")
                        ], -1)),
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_22, [
                            _createElementVNode("div", _hoisted_23, [
                              _cache[10] || (_cache[10] = _createElementVNode("div", null, " Drev dag ", -1)),
                              _createElementVNode("div", null, _toDisplayString(_ctx.evaluationChase.chase_day), 1)
                            ])
                          ]),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.evaluationChase.drev, (drev, idx) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: idx,
                              class: "evaluation-results"
                            }, [
                              _createElementVNode("div", null, _toDisplayString(drev.name), 1),
                              _createElementVNode("div", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(drev.results, (evals, index) => {
                                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                                    (evals.name === 'Upptag')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(evals.value, (uptake) => {
                                            return (_openBlock(), _createElementBlock("div", {
                                              key: uptake.name
                                            }, [
                                              _createElementVNode("div", _hoisted_25, [
                                                _createElementVNode("div", null, _toDisplayString(uptake.name), 1),
                                                _createElementVNode("div", null, _toDisplayString(uptake.value), 1)
                                              ])
                                            ]))
                                          }), 128))
                                        ]))
                                      : (!evals.name.includes('comment'))
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                            _createElementVNode("div", _hoisted_27, [
                                              _createElementVNode("div", null, _toDisplayString(evals.name), 1),
                                              _createElementVNode("div", null, _toDisplayString(evals.value), 1)
                                            ])
                                          ]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                            _createElementVNode("div", null, [
                                              _createElementVNode("div", null, [
                                                _createElementVNode("div", null, _toDisplayString(evals.name), 1)
                                              ]),
                                              _createElementVNode("div", _hoisted_29, [
                                                _createElementVNode("div", {
                                                  innerHTML: evals.value
                                                }, null, 8, _hoisted_30)
                                              ])
                                            ])
                                          ]))
                                  ]))
                                }), 128))
                              ])
                            ]))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.evaluationForestChase)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                        _cache[13] || (_cache[13] = _createElementVNode("h4", null, "Drev Resultat", -1)),
                        _cache[14] || (_cache[14] = _createElementVNode("div", { style: {"display":"flex","justify-content":"space-between"} }, [
                          _createElementVNode("span", { style: {"font-weight":"bold"} }, " Rubrik "),
                          _createElementVNode("span", { style: {"font-weight":"bold"} }, " Värde ")
                        ], -1)),
                        _createElementVNode("div", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.evaluationForestChase, (evaluation, idx) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: idx,
                              class: "evaluation-results"
                            }, [
                              _createElementVNode("h5", null, "Drev " + _toDisplayString(idx + 1), 1),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(evaluation, (value, key) => {
                                return (_openBlock(), _createElementBlock("div", { key: key }, [
                                  _createElementVNode("div", _hoisted_32, [
                                    _createElementVNode("div", null, [
                                      _createElementVNode("div", null, _toDisplayString(key), 1)
                                    ]),
                                    _createElementVNode("div", null, [
                                      _createElementVNode("div", null, _toDisplayString(value), 1)
                                    ])
                                  ])
                                ]))
                              }), 128))
                            ]))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.prepTracksResults)
                    ? (_openBlock(), _createBlock(_component_PreviewResultsContent, {
                        key: 5,
                        results: _ctx.prepTracksResults,
                        textareaFields: _ctx.textareaFields,
                        evaluationDone: _ctx.evaluationDone,
                        title: "Förutsättningar Spårläggning"
                      }, null, 8, ["results", "textareaFields", "evaluationDone"]))
                    : _createCommentVNode("", true),
                  (_ctx.prepEventResults)
                    ? (_openBlock(), _createBlock(_component_PreviewResultsContent, {
                        key: 6,
                        results: _ctx.prepEventResults,
                        textareaFields: _ctx.textareaFields,
                        evaluationDone: _ctx.evaluationDone,
                        title: "Förutsättningar Provtillfälle"
                      }, null, 8, ["results", "textareaFields", "evaluationDone"]))
                    : _createCommentVNode("", true),
                  (_ctx.evaluationResults)
                    ? (_openBlock(), _createBlock(_component_PreviewResultsContent, {
                        key: 7,
                        results: _ctx.evaluationResults,
                        textareaFields: _ctx.textareaFields,
                        evaluationDone: _ctx.evaluationDone,
                        title: "Bedömning Resultat"
                      }, null, 8, ["results", "textareaFields", "evaluationDone"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_v_col, {
                key: 1,
                align: "center"
              }, {
                default: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createElementVNode("h1", null, "Kom ej till start", -1)
                ])),
                _: 1
              }))
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { align: "center" }, {
            default: _withCtx(() => [
              (!_ctx.canSendToSKK)
                ? (_openBlock(), _createElementBlock("small", _hoisted_33, " Allt måste vara klarmarkerat för att kunna skicka in resultat "))
                : (!_ctx.isOnline)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_34, " Du måste ha internet för att skicka resultaten till SKK Start "))
                  : _createCommentVNode("", true),
              _createVNode(_component_v_btn, {
                color: "info",
                block: "",
                variant: "tonal",
                disabled: _ctx.skkAjaxLock || !_ctx.canSendToSKK || !_ctx.isOnline,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('previewGood')))
              }, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createTextVNode(" Skicka till SKK Start Web ")
                ])),
                _: 1
              }, 8, ["disabled"]),
              _createVNode(_component_v_btn, {
                color: "primary",
                block: "",
                variant: "tonal",
                style: {"margin-inline-start":"0px !important"},
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('previewBad')))
              }, {
                default: _withCtx(() => _cache[17] || (_cache[17] = [
                  _createTextVNode(" Tillbaka ")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}