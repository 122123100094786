import { TypeEvent, TypeEventDateTs } from "@/stores/store";

export const getEventId = (event: TypeEvent) => {
    const id = event.id;
    if (!id) {
        return '';
    }

    return id.toString().split('-').pop();
}

export const getEventTitleInfo = (title: string) => {
    if (!title) {
        return {
            eventType: '',
            eventLocation: '',
        }
    }

    return {
        eventType: title.split(', ')[0],
        eventLocation: title.split(', ')[1],
    }
}

export const getEventDate = (ts: TypeEventDateTs) => {
    if (!ts) {
        return 'Okänt datum';
    }

    const date = new Date(ts.seconds * 1000);
    const locale = 'sv-SE';
    const options = { 
        weekday: 'short', 
        month: 'short', 
        year: 'numeric', 
        day: 'numeric' 
    } as Intl.DateTimeFormatOptions;

    return date.toLocaleDateString(locale, options);
}

export const getEventCategoriesString = (categories: Array<string>) => {
    if (!categories?.length) {
        return '';
    }

    return categories.map((c: any) => c.value).join(' / ');
}

export const isDevided = (categories: Array<{ type:string, value: string}>)  => {
    const eventType = categories.some((c) => c.type === 'Provform' && c.value.includes('Odelat -'));
    return eventType;
}