import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showControl,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showControl) = $event)),
      contained: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          width: "450px",
          style: {"max-width":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { align: "center" }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" KONTROLLERA ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { align: "center" }, {
                  default: _withCtx(() => [
                    _cache[9] || (_cache[9] = _createElementVNode("span", null, "CHIPNUMMER", -1)),
                    _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.registration.chip), 1),
                    _cache[10] || (_cache[10] = _createElementVNode("span", null, "REGISTRERINGSNUMMER", -1)),
                    _createElementVNode("h4", null, _toDisplayString(_ctx.registration.regnum), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                (!_ctx.requestControlReason)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 0,
                      class: "v-card-button-col"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          style: {"margin":"0px 0px 0px 0px"},
                          variant: "tonal",
                          color: "green",
                          block: "",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.control('ok')))
                        }, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createTextVNode(" ID-KONTROLL AV HUND ÄR OK ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_btn, {
                          style: {"margin":"5px 0px 0px 0px"},
                          variant: "tonal",
                          color: "orange",
                          block: "",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.requestControlReason = true))
                        }, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createTextVNode(" ID-KONTROLL AV HUND ÄR FELAKTIGT ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_btn, {
                          style: {"margin":"5px 0px 0px 0px"},
                          variant: "tonal",
                          color: "error",
                          block: "",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('no-show')))
                        }, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createTextVNode(" KOM EJ TILL START ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_btn, {
                          style: {"margin":"5px 0px 0px 0px"},
                          block: "",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('cancle-control')))
                        }, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createTextVNode(" AVBRYT ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_v_col, {
                      key: 1,
                      class: "v-card-button-col",
                      align: "center"
                    }, {
                      default: _withCtx(() => [
                        _cache[17] || (_cache[17] = _createElementVNode("h3", null, "Anledning", -1)),
                        _createVNode(_component_v_textarea, {
                          modelValue: _ctx.controlBadReason,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.controlBadReason) = $event))
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_v_btn, {
                          style: {"margin":"0px"},
                          variant: "tonal",
                          color: "orange",
                          disabled: _ctx.controlBadReason === '',
                          block: "",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.control('bad')))
                        }, {
                          default: _withCtx(() => _cache[15] || (_cache[15] = [
                            _createTextVNode(" SPARA ")
                          ])),
                          _: 1
                        }, 8, ["disabled"]),
                        _createVNode(_component_v_btn, {
                          style: {"margin":"0px"},
                          block: "",
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.requestControlReason = false))
                        }, {
                          default: _withCtx(() => _cache[16] || (_cache[16] = [
                            _createTextVNode(" Tillbaka ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}