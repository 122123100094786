import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"60px"} }
const _hoisted_2 = { style: {"width":"70px"} }
const _hoisted_3 = { style: {"display":"flex","justify-content":"flex-end"} }
const _hoisted_4 = { style: {"width":"30px","padding":"5px","text-align":"center","border":"1px gray solid"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.input.values, (v, idx) => {
      return (_openBlock(), _createBlock(_component_v_col, {
        key: idx,
        style: {"margin-bottom":"5px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { style: {"justify-content":"space-between"} }, {
            default: _withCtx(() => [
              _createElementVNode("h4", _hoisted_1, _toDisplayString(v.name), 1),
              _createVNode(_component_v_select, {
                modelValue: _ctx.selected[v.scope],
                "onUpdate:modelValue": [($event: any) => ((_ctx.selected[v.scope]) = $event), ($event: any) => (_ctx.validateAndSet(v, idx))],
                class: "cuTextField",
                style: _normalizeStyle(`background: ${v.border === 'default' ? 'transparent' : v.border};`),
                variant: "outlined",
                density: "compact",
                items: v.items,
                "hide-details": "",
                outlined: "",
                onVnodeMounted: ($event: any) => (_ctx.validateAndSet(v, idx))
              }, null, 8, ["modelValue", "onUpdate:modelValue", "style", "items", "onVnodeMounted"]),
              _createElementVNode("span", _hoisted_2, _toDisplayString(v.coef), 1),
              _createElementVNode("span", {
                style: _normalizeStyle([{"width":"30px","padding":"5px","text-align":"center","border":"1px gray solid"}, `background: ${v.border === 'default' ? 'transparent' : v.border};`])
              }, [
                _createElementVNode("span", null, _toDisplayString((parseInt(_ctx.selected[v.scope]) || 0) * v.coef), 1)
              ], 4)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024))
    }), 128)),
    _createElementVNode("div", _hoisted_3, [
      _cache[0] || (_cache[0] = _createElementVNode("span", { style: {"margin-right":"10px","margin-top":"5px"} }, " Total poäng ", -1)),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.summary), 1)
    ])
  ]))
}