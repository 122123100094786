<template>
    <div style="padding: 20px;">
        <v-btn
            v-if="!trackingState.finished"
            style="margin-top: 10px;"
            color="info"
            block
            @click="$emit('next-part')"
        >
            {{ startNextButtonName }}
        </v-btn>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'TrackingActionControls',
    props: {
        trackingState: {
            type: Object,
            default: () => ({})
        },
        partsList: {
            type: Array,
            required: true,
        }
    },
    emits: ['next-part'],
    computed: {
        startNextButtonName() {
            if (!this.partsList.length || !this.partsList[this.trackingState.currentPart]) {
                return '';
            }

            if (this.trackingState.currentPart === 0) {
                // @ts-ignore
                return this.partsList[this.trackingState.currentPart].name
            }

            // @ts-ignore
            return `Starta ${this.partsList[this.trackingState.currentPart].name}`
        }
    },
})
</script>