import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, renderSlot as _renderSlot, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "form-head-sticky" }
const _hoisted_2 = {
  key: 0,
  class: "form-head"
}
const _hoisted_3 = { class: "d-flex flex-column" }
const _hoisted_4 = { class: "text-body-2" }
const _hoisted_5 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_6 = { class: "d-flex flex-wrap justify-space-between" }
const _hoisted_7 = { class: "text-body-1" }
const _hoisted_8 = ["textContent"]
const _hoisted_9 = {
  key: 0,
  class: "d-flex flex-wrap justify-space-between"
}
const _hoisted_10 = { class: "mt-3 mx-4" }
const _hoisted_11 = {
  key: 0,
  id: "tooltip",
  class: "section-title"
}
const _hoisted_12 = { class: "title" }
const _hoisted_13 = {
  key: 1,
  class: "layout-container-fullscreen layout-padding-top"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_evaluation_inputs = _resolveComponent("evaluation-inputs")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_list_item_content = _resolveComponent("v-list-item-content")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!

  return (!_ctx.isLoading && _ctx.evaluation)
    ? (_openBlock(), _createBlock(_component_v_container, {
        key: 0,
        class: "px-8 py-8 relative"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.registration && _ctx.registration.title)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      (_ctx.event.type !== 'kennelvisit')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("span", null, [
                                _cache[2] || (_cache[2] = _createTextVNode(" Prov-ID: ")),
                                _createElementVNode("strong", null, "#" + _toDisplayString(_ctx.getEventId(_ctx.event)), 1)
                              ])
                            ]),
                            _createElementVNode("div", null, [
                              _createElementVNode("span", null, [
                                _cache[3] || (_cache[3] = _createTextVNode(" Plats: ")),
                                _createElementVNode("strong", null, _toDisplayString(_ctx.getEventTitleInfo(_ctx.event.title).eventLocation), 1)
                              ])
                            ]),
                            _createElementVNode("div", null, [
                              _createElementVNode("span", null, [
                                _cache[4] || (_cache[4] = _createTextVNode(" Kategorier: ")),
                                _createElementVNode("strong", null, _toDisplayString(_ctx.getEventCategoriesString(_ctx.event.categories)), 1)
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", _hoisted_7, [
                        _createElementVNode("strong", null, _toDisplayString(_ctx.registration.title), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("span", {
                          textContent: _toDisplayString(_ctx.event.type === 'kennelvisit' ? 'Uppfödare: ' : 'Ägare: ')
                        }, null, 8, _hoisted_8),
                        _createElementVNode("strong", null, _toDisplayString(_ctx.registration.ownerName), 1),
                        (_ctx.registration.class && _ctx.registration.ts)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createElementVNode("span", null, [
                                _cache[5] || (_cache[5] = _createTextVNode(" Klass: ")),
                                _createElementVNode("strong", null, _toDisplayString(_ctx.registration.class), 1)
                              ]),
                              _createElementVNode("span", null, [
                                _cache[6] || (_cache[6] = _createTextVNode(" Datum: ")),
                                _createElementVNode("strong", null, _toDisplayString(_ctx.registrationDate), 1)
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_v_alert, {
                modelValue: _ctx.showError,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showError) = $event)),
                border: "start",
                position: "sticky",
                closable: "",
                variant: "flat",
                type: "warning",
                "close-label": "Close Alert",
                elevation: "3",
                class: "full-width mb-4"
              }, {
                default: _withCtx(() => [
                  _cache[7] || (_cache[7] = _createElementVNode("span", null, [
                    _createTextVNode(" De värden som du har fyllt i har sparats!"),
                    _createElementVNode("br"),
                    _createTextVNode(" Observera att värden saknas för: ")
                  ], -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.errorText), 1)
                ]),
                _: 1
              }, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.evaluation, (scope) => {
              return _withDirectives((_openBlock(), _createBlock(_component_v_row, {
                key: scope.scope,
                ripple: "",
                class: "cursor-pointer evaluation-section"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { align: "left" }, {
                    default: _withCtx(() => [
                      (!_ctx.$route.params.extraId && scope.title)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, _toDisplayString(scope.title), 1),
                            (scope.tooltip)
                              ? (_openBlock(), _createBlock(_component_v_dialog, {
                                  key: 0,
                                  modelValue: scope.showDialog,
                                  "onUpdate:modelValue": ($event: any) => ((scope.showDialog) = $event),
                                  class: "tooltip",
                                  "max-width": "600px"
                                }, {
                                  activator: _withCtx(({ props }) => [
                                    _createVNode(_component_v_btn, _mergeProps({
                                      icon: "",
                                      class: "mb-0",
                                      ref_for: true
                                    }, props, { flat: "" }), {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, null, {
                                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                                            _createTextVNode("mdi-information-outline")
                                          ])),
                                          _: 1
                                        })
                                      ]),
                                      _: 2
                                    }, 1040)
                                  ]),
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_card_title, { class: "headline" }, {
                                          default: _withCtx(() => [
                                            _renderSlot(_ctx.$slots, "title", {}, () => [
                                              _createTextVNode(_toDisplayString(scope.title), 1)
                                            ])
                                          ]),
                                          _: 2
                                        }, 1024),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((scope.tooltip).split('\n'), (s) => {
                                          return (_openBlock(), _createBlock(_component_v_card_text, {
                                            key: s,
                                            class: "headline"
                                          }, {
                                            default: _withCtx(() => [
                                              _renderSlot(_ctx.$slots, "text", {}, () => [
                                                _createTextVNode(_toDisplayString(s), 1)
                                              ])
                                            ]),
                                            _: 2
                                          }, 1024))
                                        }), 128)),
                                        _createVNode(_component_v_card_actions, null, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_btn, {
                                              color: "info",
                                              onClick: ($event: any) => (scope.showDialog = false)
                                            }, {
                                              default: _withCtx(() => _cache[9] || (_cache[9] = [
                                                _createTextVNode(" Stäng ")
                                              ])),
                                              _: 2
                                            }, 1032, ["onClick"])
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1032, ["modelValue", "onUpdate:modelValue"]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      scope
                        ? (_openBlock(), _createBlock(_component_evaluation_inputs, {
                            key: 1,
                            ref_for: true,
                            ref: "evaluation",
                            inputs: scope.inputs,
                            registration: _ctx.registration,
                            "all-changes": _ctx.evaluationChanges,
                            values: _ctx.values,
                            disable: _ctx.no_show_local,
                            onChange: _ctx.onChange
                          }, null, 8, ["inputs", "registration", "all-changes", "values", "disable", "onChange"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)), [
                [_vShow, _ctx.checkScopeRequirement(scope)]
              ])
            }), 128)),
            (_ctx.summeriseType)
              ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, null, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.summeriseType), {
                          values: [..._ctx.values || []],
                          changes: [..._ctx.evaluationChanges || []],
                          evaluations: _ctx.evaluation,
                          type: _ctx.summeriseType,
                          onChange: _ctx.onChange
                        }, null, 40, ["values", "changes", "evaluations", "type", "onChange"]))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_v_row, { class: "ma-0 pa-0 pt-8" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { class: "ma-0 pa-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_content, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        color: "success",
                        block: "",
                        loading: _ctx.isSaving,
                        onClick: _withModifiers(_ctx.saveChanges, ["prevent"])
                      }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode(" Spara ")
                        ])),
                        _: 1
                      }, 8, ["loading", "onClick"]),
                      _createVNode(_component_v_btn, {
                        style: {"margin-top":"20px"},
                        color: "info",
                        block: "",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'RegistrationView' })))
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode(" Stäng ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 3
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_13, [
        _createVNode(_component_v_progress_circular, {
          indeterminate: "",
          color: "var(--green-3)",
          size: "150",
          width: "20"
        })
      ]))
}