<template>
    <v-btn
        :color="getColor(hasEval.introduction, true)"
        :prepend-icon="getIcon(hasEval.introduction)"
        @click="$emit('click-bed', 'introduction')"
    >
        INTRODUKTION
    </v-btn>
    <v-btn
        :disabled="isNoShow.no_show"
        :color="getColor(hasEval.dogs)"
        :prepend-icon="getIcon(hasEval.dogs)" 
        @click="$emit('click-bed', 'dogs')"
    >
        HUNDARNA OCH AVTALEN
    </v-btn>
    <v-btn
        :disabled="isNoShow.no_show"
        :color="getColor(hasEval.litter)"
        :prepend-icon="getIcon(hasEval.litter)" 
        @click="$emit('click-bed', 'litter')"
    >
        VALPKULL
    </v-btn>
    <v-btn
        :disabled="isNoShow.no_show"
        :color="getColor(hasEval.indoor_spaces)"
        :prepend-icon="getIcon(hasEval.indoor_spaces)" 
        @click="$emit('click-bed', 'indoor_spaces')"
    >
        INOMHUSUTRYMMEN
    </v-btn>
    <v-btn
        :disabled="isNoShow.no_show"
        :color="getColor(hasEval.restyard)"
        :prepend-icon="getIcon(hasEval.restyard)" 
        @click="$emit('click-bed', 'restyard')"
    >
        UTOMHUSUTRYMMEN
    </v-btn>
    <v-btn
        :disabled="isNoShow.no_show"
        :color="getColor(hasEval.other)"
        :prepend-icon="getIcon(hasEval.other)" 
        @click="$emit('click-bed', 'other')"
    >
        ÖVRIGT
    </v-btn>
    <v-btn
        :disabled="isNoShow.no_show && isNoShow.hideRevisit"
        :color="getColor(hasEval.follow_up, !isNoShow.hideRevisit)"
        :prepend-icon="getIcon(hasEval.follow_up)" 
        @click="$router.push({
            name: 'kennelvisit_follow_up'
        })"
    >
        UPPFÖLJNING
    </v-btn>
    <v-btn
        :color="getColor(hasEval.visit, true)"
        :prepend-icon="getIcon(hasEval.visit)" 
        @click="$emit('click-bed', 'visit')"
    >
        BESÖKET
    </v-btn>

    <v-divider class="mt-2 mb-5" />
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';

import {
    useRegistrationsStore
} from '@/stores/store'

export default defineComponent({
    props: {
        controlPassed: {
            type: Number,
            default: -1
        },
        paid: {
            type: Boolean,
            default: false
        },
        currReg: {
            type: Object,
            default: () => ({})
        },
        currEvent: {
            type: Object,
            default: () => ({})
        },
        isNoShow: {
            type: Object,
            default: () => ({
                no_show: false, 
                hideRevisit: false
            }),
        }
    },
    emits: {
        'click-bed': null,
        'click-paid': null,
        'click-control': null
    },
    setup(props) {
        const registration = reactive(useRegistrationsStore());
        const hasEval = reactive({
            introduction: -1,
            visit: -1,
            dogs: -1,
            litter: -1,
            indoor_spaces: -1,
            restyard: -1,
            other: -1,
            follow_up: -1,
        }) as any;

        Object.keys(hasEval).forEach((type) => {
            registration.getHasResult(props.currReg.id, type).then((done: number) => {
                if (Object.prototype.hasOwnProperty.call(hasEval, type)) {
                    hasEval[type] = done;
                    return;
                }
                throw Error('[kennelvisit@Actions] Object dosent have eval type');
            }).catch(console.log);
        });

        return {
            registration,
            hasEval
        }
    },
    data() {
        return {
            removeVisitDialog: false
        }
    },
    methods: {
        castValue(toCast: number|boolean): number {
            // eslint-disable-next-line no-nested-ternary
            return (typeof toCast === 'boolean') ? (toCast ? 1 : -1) : toCast
        },

        getIcon(val: number|boolean) {
            return this.castValue(val) === 1 ? 'mdi-check-circle-outline' : '';
        },

        getColor(val: number|boolean, skipNoShowCheck = false) {
            if (!skipNoShowCheck && (this.isNoShow && this.isNoShow.no_show)) {
                return 'grey'
            }

            switch (this.castValue(val)) {
                case -1:
                    return 'grey'
                case 0:
                    return 'warning'
                case 1:
                    return 'success'
                default:
                    return 'grey'
            }
        }
    },
})
</script>
