<template>
    <DynamicScroller
        class="scroller"
        direction="horizontal"
        :items="partsList"
        :min-item-size="54"
        key-field="id"
    >
        <template #default="{ item, index, active }">
            <DynamicScrollerItem
                :item="item"
                :active="active"
                :size-dependencies="[
                    item.id
                ]"
                :data-index="index"
            >
                <div :class="{ 'list-item': true, 'active': item.id === trackingState.currentPart}">
                    {{ item.name }} <v-icon v-if="item.id !== partsList.length - 1">mdi-chevron-right</v-icon>
                </div>
            </DynamicScrollerItem>
        </template>
    </DynamicScroller>
</template>

<script lang="ts">
// @ts-ignore
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'TrackingTopBar',
    components: {
        DynamicScroller,
        DynamicScrollerItem
    },
    props: {
        trackingState: {
            type: Object,
            default: () => {}
        },
        partsList: {
            type: Array,
            required: true,
        }
    },
});
</script>

<style>
.scroller {
    width: 100%;
    max-width: 500px;
    height: 65px !important;
    padding-left: 12px;
    padding-right: 12px;
}

.list-item {
    opacity: 0.5;
    font-size: 14px;
}

.list-item.active {
    opacity: 1;
}
</style>
