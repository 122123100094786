import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@resources/kk-logo-negative.png'
import _imports_1 from '@resources/skk-start-logo-negative.png'


const _hoisted_1 = { class: "d-flex justify-space-between pr-1" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "KK-logo",
  height: "35",
  class: "mt-1"
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  alt: "SKK-logo",
  height: "35",
  class: "mt-1"
}
const _hoisted_4 = { class: "d-flex justify-end pl-1" }
const _hoisted_5 = { class: "d-flex flex-column align-center bg-grey-lighten-4 px-4 py-2" }
const _hoisted_6 = { class: "text-center text-subtitle-1" }
const _hoisted_7 = { class: "d-flex flex-column pa-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_app_bar, {
      id: "main-navbar",
      density: "compact",
      absolute: "",
      color: "var(--color-accent)",
      class: "text-white"
    }, {
      prepend: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_v_btn, {
            icon: "",
            size: "42",
            class: "mr-2",
            onClick: _ctx.back
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                size: "32",
                icon: "mdi-arrow-left"
              })
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_v_btn, {
            icon: "",
            size: "42",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(0)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                size: "32",
                icon: "mdi-refresh"
              })
            ]),
            _: 1
          })
        ])
      ]),
      append: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_v_btn, {
            icon: "",
            size: "42",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.drawer = true))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                size: "32",
                icon: "mdi-menu"
              })
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_app_bar_title, { class: "d-flex justify-center align-center ma-0" }, {
          default: _withCtx(() => [
            (['kk', 'kk_dev', 'kk_test', 'kk_local'].includes(_ctx.userStore.getEndpoint))
              ? (_openBlock(), _createElementBlock("img", _hoisted_2))
              : (_openBlock(), _createElementBlock("img", _hoisted_3))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_navigation_drawer, {
      modelValue: _ctx.drawer,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.drawer) = $event)),
      location: "right",
      temporary: "",
      absolute: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_v_avatar, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                icon: "mdi-account",
                size: "30"
              })
            ]),
            _: 1
          }),
          _createElementVNode("h6", _hoisted_6, _toDisplayString(_ctx.getUserName), 1)
        ]),
        _createVNode(_component_v_divider),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_v_btn, {
            block: "",
            variant: "flat",
            color: "info",
            class: "flex-between mb-2",
            disabled: _ctx.events.isSyncing,
            onClick: _ctx.logout
          }, {
            default: _withCtx(() => [
              _cache[4] || (_cache[4] = _createElementVNode("span", null, "Logga ut", -1)),
              _createVNode(_component_v_icon, { icon: "mdi-logout-variant" })
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          _createVNode(_component_v_btn, {
            block: "",
            variant: "tonal",
            class: "flex-between",
            disabled: _ctx.events.isSyncing,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.events.sync()))
          }, {
            default: _withCtx(() => [
              _cache[5] || (_cache[5] = _createElementVNode("span", null, "Synkronisera", -1)),
              _createVNode(_component_v_icon, { icon: "mdi-cloud-sync" })
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}