import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"
import _imports_0 from '@resources/skk-start-logo.png'
import _imports_1 from '@resources/kk-logo.png'
import _imports_2 from '@resources/skk-logo.png'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  alt: "SKK-start-logo",
  height: "50",
  class: "skk-logo mb-8 mt-8"
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  alt: "KK-logo",
  height: "50",
  class: "skk-logo mb-8 mt-8"
}
const _hoisted_3 = {
  key: 2,
  src: _imports_2,
  alt: "SKK-logo",
  height: "50",
  class: "skk-logo mb-8 mt-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    modelValue: _ctx.valid,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.valid) = $event)),
    class: _normalizeClass(_ctx.getSiteName),
    onSubmit: _withModifiers(_ctx.authenticate, ["prevent"])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "d-flex justify-center" }, {
                default: _withCtx(() => [
                  (_ctx.getSiteName === 'skk')
                    ? (_openBlock(), _createElementBlock("img", _hoisted_1))
                    : (_ctx.getSiteName === 'kk')
                      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
                      : (_openBlock(), _createElementBlock("img", _hoisted_3))
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.email,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                    name: "email",
                    rules: [_ctx.formRules.required, _ctx.formRules.email],
                    disabled: _ctx.requestInProgress,
                    label: "E-post",
                    variant: "solo",
                    density: "compact",
                    "hide-details": true
                  }, null, 8, ["modelValue", "rules", "disabled"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.password,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                    name: "password",
                    label: "Lösenord",
                    rules: [_ctx.formRules.required],
                    "append-icon": _ctx.showPassword ? 'mdi-eye' : 'mdi-eye-off',
                    type: _ctx.showPassword ? 'text' : 'password',
                    disabled: _ctx.requestInProgress,
                    variant: "solo",
                    density: "compact",
                    "hide-details": true,
                    class: "mb-4",
                    "onClick:append": _cache[2] || (_cache[2] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword))
                  }, null, 8, ["modelValue", "rules", "append-icon", "type", "disabled"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    modelValue: _ctx.site,
                    "onUpdate:modelValue": [
                      _cache[3] || (_cache[3] = ($event: any) => ((_ctx.site) = $event)),
                      _ctx.setSite
                    ],
                    items: _ctx.siteOptions[_ctx.appNamespace],
                    "item-title": "name",
                    "item-value": "url",
                    label: "Log in to",
                    rules: [_ctx.formRules.required],
                    disabled: _ctx.requestInProgress,
                    variant: "outlined",
                    density: "compact",
                    "hide-details": true,
                    class: "mb-4"
                  }, null, 8, ["modelValue", "items", "rules", "disabled", "onUpdate:modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    type: "submit",
                    color: "success",
                    block: "",
                    loading: _ctx.requestInProgress,
                    disabled: !_ctx.valid || _ctx.requestInProgress,
                    "append-icon": "mdi-login"
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode(" Login ")
                    ])),
                    _: 1
                  }, 8, ["loading", "disabled"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_dialog, {
                    modelValue: _ctx.wipeDialog,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.wipeDialog) = $event)),
                    contained: ""
                  }, {
                    activator: _withCtx(({ props }) => [
                      _createVNode(_component_v_btn, _mergeProps({
                        color: "warning",
                        variant: "text",
                        block: "",
                        disabled: _ctx.requestInProgress
                      }, props), {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode(" Rensa lokala databasen ")
                        ])),
                        _: 2
                      }, 1040, ["disabled"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode(" Denna funktion ska endast användas på uppmaning av IT-supporten. Är du säker på att du vill rensa den lokala databasen? (Åtgärden går ej att ångra) ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_card_actions, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                color: "primary",
                                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.wipeDialog = false))
                              }, {
                                default: _withCtx(() => _cache[11] || (_cache[11] = [
                                  _createTextVNode(" Avbryt ")
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_v_btn, {
                                color: "warning",
                                variant: "text",
                                disabled: !_ctx.canWipe,
                                onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => {_ctx.wipeDialog = false; _ctx.wipe()}, ["prevent"]))
                              }, {
                                default: _withCtx(() => _cache[12] || (_cache[12] = [
                                  _createTextVNode(" Rensa ")
                                ])),
                                _: 1
                              }, 8, ["disabled"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "class", "onSubmit"]))
}