<template>
    <v-container 
        v-if="!events.synced && !kkInstance"
        :class="{'green-bg': kkInstance}"
    >
        <Transition>
            <v-col cols="12">
                <div
                    v-if="!events.isSyncing"
                    class="layout-container-fullscreen layout-padding-top"
                >
                    <v-icon
                        icon="mdi-cloud-sync"
                        size="120"
                        color="var(--green-3)"
                        class="mb-8"
                    />
                    <div class="d-flex flex-column align-center">
                        <v-btn
                            variant="flat"
                            color="info"
                            class="mb-2"
                            @click="syncEvents()"
                        >
                            Synkronisera
                        </v-btn>
                        <v-btn
                            variant="text"
                            @click="events.synced = true"
                        >
                            Avbryt
                        </v-btn>
                    </div>
                </div>
                <div
                    v-else
                    class="layout-container-fullscreen layout-padding-top"
                >
                    <v-progress-circular
                        indeterminate
                        color="var(--green-3)"
                        size="150"
                        width="20"
                    />
                </div>
            </v-col>
        </Transition>
    </v-container>

    <Transition v-else>
        <v-container
            :class="{'green-bg': kkInstance}"
        >
            <v-col cols="12">
                <div v-if="!events.isLoading">
                    <div class="page-title d-flex justify-center mb-4">
                        <h1 v-if="kkInstance">
                            Välj dag
                        </h1>
                        <h1 v-else>
                            Välj event
                        </h1>
                    </div>
                
                    <div class="mb-8">
                        <v-text-field
                            v-model="search"
                            placeholder="Sök"
                            variant="solo"
                            append-inner-icon="mdi-magnify"
                            class="rounded-md"
                        />
                
                        <div class="d-flex justify-end mt-n4 text-subtitle-2">
                            <strong
                                v-if="search !== ''"
                                class="mr-auto"
                            >
                                Sökträffar: {{ filteredEvents.length }}
                            </strong>
                            <strong>
                                Registrerade
                                <span v-if="kkInstance">
                                    besök:
                                </span>
                                <span v-else>provtillfällen:</span>
                                {{ events.events.length }}
                            </strong>
                        </div>
                    </div>
                    <v-list
                        class="pa-0"
                        style="background: none;"
                    >
                        <v-list-item
                            v-for="(event) in filteredEvents"
                            v-show="event.visible"
                            :key="event.id"
                            elevation="2"
                            class="rounded-md mb-2 px-4 py-2 bg-white"
                            @click="toEvent(event)"
                        >
                            <v-list-item-title class="text-subtitle-1">
                                <strong v-if="!kkInstance">
                                    #{{ getEventId(event) }} - 
                                </strong>

                                <strong> {{ event.title }}</strong>
                            </v-list-item-title>
                            <v-list-item-subtitle 
                                v-if="classList(event)" 
                                class="text-subtitle-2"
                            >
                                {{ classList(event) }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="text-subtitle-2">
                                {{ eventDate(event) }}
                            </v-list-item-subtitle>
                            <template #append>
                                <v-icon color="info">
                                    mdi-arrow-right
                                </v-icon>
                            </template>
                        </v-list-item>
                    </v-list>
                </div>
                
                <div
                    v-else
                    class="layout-container-fullscreen layout-padding-top"
                >
                    <v-progress-circular
                        indeterminate
                        color="var(--green-3)"
                        size="150"
                        width="20"
                    />
                </div>
            </v-col>
        </v-container>
    </Transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useEventStore, TypeEvent, useUserStore } from '@/stores/store';
import { getEventId } from '@/helpers/eventInfoHelper';
import { Timestamp } from "firebase/firestore";

export default defineComponent({
    name: 'EventList',
    data() {
        return {
            userStore: useUserStore().getUser(),
            events: useEventStore(),
            search: '',
        }
    },
    computed: {
        filteredEvents() {
            return this.events.events.filter((event) =>
                event.title.toLowerCase().match(this.search.toLowerCase()));
        },

        isOnline() {
            return window.navigator.onLine;
        },

        kkInstance() {
            return ['kk', 'kk_dev', 'kk_test', 'kk_local'].includes(this.userStore.getEndpoint);
        }
    },
    async mounted() {
        await this.events.init();
    },
    methods: {
        classList(event: TypeEvent) {
            return event.categories.map((c: any) => c.value).join(' / ');
        },

        async syncEvents() {
            await this.events.destroy();
            await this.events.sync();
            await this.events.init();
        },

        eventDate(event: TypeEvent) {
            const locale = 'sv-SE';
            const options = { 
                weekday: 'short', 
                month: 'short', 
                year: 'numeric', 
                day: 'numeric' 
            } as Intl.DateTimeFormatOptions;

            if (typeof event.ts !== 'string') {
                if (!event.ts.toDate) {
                    return Timestamp.fromMillis(event.ts.seconds * 1000).toDate().toLocaleDateString(locale, options);
                }
                return event.ts.toDate().toLocaleDateString(locale, options);
            }
            return new Date(event.ts).toLocaleDateString(locale, options);
        },

        toEvent(event: TypeEvent) {
            this.$router.push({
                name: 'RegistrationList',
                params: {
                    'eventId': event.id,
                }
            })
        },

        getEventId(event: TypeEvent) {
            return getEventId(event);
        }
    }
})
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
