<template>
    <div class="info-container">
        <v-avatar
            color="surface-variant"
            size="100"
            style="margin-bottom: 20px; font-size: 50px;"
        >
            <span v-if="showStartOrder">{{ reg.start_order + 1 }}</span>
        </v-avatar>

        <h1 class="text-center">
            {{ reg.title }}
        </h1>

        <v-container v-if="reg">
            <v-row>
                <v-col
                    align="center"
                    class="info-col"
                >
                    <span class="category-span">Prov-ID</span>
                    <h4>#{{ getEventId(event) }}</h4>

                    <span class="category-span">Plats</span>
                    <h4>{{ getEventTitleInfo(event.title).eventLocation }}</h4>

                    <span class="category-span">Datum</span>
                    <h4
                        style="text-transform: capitalize;"
                    >
                        {{ getEventDate(event.ts) }}
                    </h4>

                    <span class="category-span">Kategorier</span>
                    <h4>
                        {{ getEventCategoriesString(event.categories) }}
                    </h4>
                </v-col>
                <v-col
                    align="center"
                    class="info-col"
                >
                    <span class="category-span">Ras</span>
                    <h4>{{ reg.breed }}</h4>

                    <template v-if="showClass">
                        <span class="category-span">Klass</span>
                        <h4>{{ reg.class }}</h4>
                    </template>

                    <span class="category-span">Kön</span>
                    <h4>{{ reg.gender === 'F' ? 'T' : 'H' }}</h4>

                    <template v-if="reg.chip">
                        <span class="category-span">Chipnummer</span>
                        <h4>{{ reg.chip }}</h4>
                    </template>

                    <span class="category-span">Reg. nummer</span>
                    <h4>{{ reg.regnum }}</h4>
                </v-col>
            </v-row>
            <v-row v-if="showShooting">
                <v-col
                    align="center"
                    cols="6"
                    class="info-col"
                >
                    <span class="category-span">GK SKOTTPRÖVNING GENOMFÖRD</span>
                    <h4>{{ getWordValue(reg.shooting?.test_approved) }}</h4>
                </v-col>

                <v-col
                    v-if="showShootingInfo"
                    align="center"
                    cols="6"
                    class="info-col"
                >
                    <span class="category-span">DATUM</span>
                    <h4>{{ reg.shooting?.test_date }}</h4>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    align="center"
                    cols="6"
                >
                    <span class="category-span">Ägare / förare</span>
                    <h4>{{ reg.ownerName }}</h4>
                </v-col>
                <v-col
                    align="center"
                    cols="6"
                >
                    <span class="category-span">Mobilnummer</span>
                    <h4>{{ reg.ownerPhoneNumber }}</h4>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { TypeRegistration, TypeEvent, TypeEventDateTs } from '@/stores/store';
import { getEventId, getEventTitleInfo, getEventDate, getEventCategoriesString } from '@/helpers/eventInfoHelper';

export default defineComponent({
    props: {
        reg: {
            type: Object as PropType<TypeRegistration>,
            default: () => {}
        },
        event: {
            type: Object as PropType<TypeEvent>,
            default: () => {}
        }
    },
    computed: {
        showClass() {
            return this.event.type === 'fa';
        },
        showStartOrder() {
            if (!['hunting_retriever', 'workingtest'].includes(this.event.type)) {
                return false;
            }

            return this.reg.start_order !== null || !Number.isNaN(this.reg.start_order)
        },
        avatarColor() {
            if (this.reg.sentTo) {
                return 'green';
            }

            return 'surface-variant';
        },
        showShooting() {
            return this.event.type === 'bloodtracking';
        },
        showShootingInfo() {
            return this.reg.shooting?.test_approved === 1;
        }
    },
    methods: {
        getEventId(event: TypeEvent) {
            return getEventId(event);
        },

        getEventTitleInfo(title: string) {
            return getEventTitleInfo(title);
        },

        getEventDate(ts: TypeEventDateTs) {
            return getEventDate(ts);
        },

        getEventCategoriesString(categories: Array<string>) {
            return getEventCategoriesString(categories);
        },

        getWordValue(value: number) {
            return value === 1 ? 'Ja' : 'Nej';
        }
    }
})
</script>

<style scoped>
.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
