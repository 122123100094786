<template>
    <v-textarea
        v-model="selected[input.scope]"
        :hide-details="hideDetails"
        outlined
        :placeholder="input.value ?? 'Kommentar'"
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { TypeRegistration, useBreedStore } from '@/stores/store';

export default defineComponent({
    name: 'EvaluationBreedSelect',
    props: {
        dynamic: {
            type: Object,
            default: null
        },
        dynamicIndex: {
            type: Number,
            default: null
        },
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {},
        },
        input: {
            type: Object,
            default: () => {},
        },
        values: {
            type: Array,
            default: () => [],
        },
        registration: {
            type: Object,
            default: () => {},
        }
    },
    emits: ['setScope'],
    data() {
        return {
            breedsStore: useBreedStore(),
        }
    },
    async created() {
        if (this.selected[this.input.scope]) {
            return;
        }

        if (!this.breedsStore.initialized) {
            await this.breedsStore.init();
        }

        const currentBreeds = await this.breedsStore.getKennelBreeds(this.registration as TypeRegistration);
        const breedNames = currentBreeds.map((b) => {
            return b.name
        });

        this.$emit('setScope', this.input.scope, breedNames.join('\n'));
    }
});

</script>
