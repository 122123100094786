import 'core-js/actual/array/at';
import 'core-js/actual/array/find-last-index';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import { getTest, getTests } from '@/api/Api';
import { useUserStore } from '@/stores/store';
import '@mdi/font/css/materialdesignicons.css'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'

import { loadFonts } from './plugins/webfontloader'

import { createPinia } from "pinia";

import VueDatePicker from '@vuepic/vue-datepicker';

import '@vuepic/vue-datepicker/dist/main.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { Capacitor } from '@capacitor/core';
import { JeepSqlite } from 'jeep-sqlite/dist/components/jeep-sqlite';
import { defineCustomElements as pwaElements} from '@ionic/pwa-elements/loader';
import SQLiteService from '@/services/sqliteService';
import DbVersionService from '@/services/dbVersionService';
import StorageService from '@/services/storageService';
import InitializeAppService from '@/services/initializeAppService';
import { initializeApp } from 'firebase/app';

pwaElements(window);
customElements.define('jeep-sqlite', JeepSqlite);
const platform = Capacitor.getPlatform();

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        }
    },
})

loadFonts()

if (process.env.VUE_APP_NAMESPACE !== 'dev' && platform === 'web') {
    let sha: string | null = null;
    const checkUpdate = async function () {
        try {
            const res = await axios.get('/hash.txt');

            if (res.data && res.data.length > 0) {
                if (sha && sha !== res.data) {
                    alert('Det finns en ny version tillgänglig, sidan kommer nu laddas om.');
                    window.location.reload();
                } else {
                    sha = res.data;
                }
            }
        } catch (e) {
            // NOTE: ignore errors, we don't care
        }
    };

    setTimeout(checkUpdate, 1000 * 60 * 60);
    checkUpdate();
}

const app = createApp(App)
    .use(router)
    .use(createPinia())
    .use(vuetify)
    .component('VueDatePicker', VueDatePicker);

app.config.globalProperties.$platform = platform;

// Services
const sqliteServ = new SQLiteService();
const dbVersionServ = new DbVersionService();
const storageServ = new StorageService(sqliteServ, dbVersionServ);

app.config.globalProperties.$sqliteServ = sqliteServ;
app.config.globalProperties.$dbVersionServ = dbVersionServ;
app.config.globalProperties.$storageServ = storageServ;

const initAppServ = new InitializeAppService(sqliteServ, storageServ);

const mountApp = () => {
    initAppServ.initializeApp().then(() => {
        syncTestTypes().then(() => {
            router.isReady().then(() => {
                app.mount('#app');
            });
        });
    }).catch((error) => {
        console.error('App Initialization error:', error);
    });
}

const syncTestTypes = async () => {
    const ep = window.localStorage.getItem('endpoint')
    if (!ep || ['kk', 'kk_dev', 'kk_test', 'kk_local'].includes(ep)) {
        return;
    }
    try {
        const skkTestVersions = (await getTests()).data;
        const storageTestVersions = await storageServ.getTestTypeVersions();

        let updateAvailable = [] as string[];

        if (!storageTestVersions) {
            updateAvailable = Object.keys(skkTestVersions);
        } else {
            updateAvailable = storageTestVersions
                .flatMap((v) => skkTestVersions[v.code] !== v.version ? v.code : null)
                .filter((v) => v !== null)
        }

        if (!updateAvailable.length) {
            return;
        }

        const promises = updateAvailable.map(async (test_type) => {
            const test_json = (await getTest(test_type)).data as any;
            const exists = await storageServ.getTestTypeByCode(test_type);

            if (!exists) {
                return storageServ.addTestType({
                    id: Date.now(), // do not care the id is generated by sqlite
                    code: test_type,
                    protocol: JSON.stringify(test_json),
                    version: test_json.version
                });
            } else {
                return storageServ.updateTestTypeByCode(
                    test_type,
                    JSON.stringify(test_json),
                    test_json.version
                );
            }
        });
        await Promise.all(promises);

        if (platform === 'web') {
            await sqliteServ.saveToStore(storageServ.getDatabaseName());
        }
    } catch(error) {
        console.error('SyncTestTypes error:', error);
    }
}

const initializeFirebase = () => {
    const firebaseConfig = {
        apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
        authDomain: process.env.VUE_APP_GOOGLE_AUTH_DOMAIN,
        databaseURL: process.env.VUE_APP_GOOGLE_DATABASE_URL,
        projectId: process.env.VUE_APP_GOOGLE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_GOOGLE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_GOOGLE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_GOOGLE_APP_ID
    };

    initializeApp(firebaseConfig);
}

app.config.globalProperties.$syncTestTypes = syncTestTypes

if (platform !== 'web') {
    mountApp();
} else {
    initializeFirebase();
    window.addEventListener('DOMContentLoaded', async () => {
        const jeepEl = document.createElement('jeep-sqlite');
        document.body.appendChild(jeepEl);
        customElements.whenDefined('jeep-sqlite').then(() => {;
            mountApp();
        }).catch ((err) => {
            console.error('jeep-sqlite creation error:', err);
        });
    });
}

