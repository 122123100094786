<!-- eslint-disable vue/no-v-html -->
<template>
    <v-card
        align="center"
        max-height="90vh"
    >
        <v-card-title align="center">
            KONTROLLERA
        </v-card-title>
        <v-card-text>
            <v-col
                v-if="!isNoShow"
                align="center"
            >
                <div
                    v-if="controlsResults"
                    class="mb-8"
                >
                    <h4>Kontroll Resultat</h4>
                    <div
                        v-if="controlDone"
                        style="color: green"
                    >
                        Klarmarkerat
                    </div>
                    <div style="display: flex; justify-content: space-between">
                        <!-- <span style="font-weight: bold"> Titel </span> -->
                        <span style="font-weight: bold"> Värde </span>
                    </div>
                    <div style="display: flex; justify-content: space-between">
                        <div>Hundkontroll</div>
                        <div v-if="controlsResults.controll_status === 'not_ok'">
                            Ej godkänd
                        </div>
                        <div v-else>
                            Godkänd
                        </div>
                    </div>
                    <div
                        v-if="controlsResults.reason"
                        style="display: flex; justify-content: space-between"
                    >
                        <div>Anledning</div>
                        <div>
                            {{ controlsResults.reason }}
                        </div>
                    </div>
                    <div
                        v-if="swkForestWeather"
                    >
                        <div
                            v-for="(evaluation, idx) in swkForestWeather"
                            :key="idx"
                            class="evaluation-weather"
                            style="display: flex; justify-content: space-between"
                        >
                            <div class="text-left">
                                {{ evaluation.name }}
                            </div>
                            
                            <div class="text-right">
                                {{ evaluation.value }}
                            </div>
                        </div>
                    </div>
                </div>
                <div 
                    v-if="workingtestResults"
                    class="mb-8"
                >
                    <div 
                        v-for="result in workingtestResults"
                        :key="result.scope"
                    >
                        <h4>{{ result.title }}</h4>
                        <div
                            v-if="result.done"
                            style="color: green"
                        >
                            Klarmarkerat
                        </div>
                        <div style="display: flex; justify-content: space-between">
                            <span style="font-weight: bold"> Rubrik </span>
                            <span style="font-weight: bold"> Värde </span>
                        </div>
                        <div>
                            <div style="display: flex; justify-content: space-between">
                                <div>
                                    <div>
                                        {{ result.name }}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        {{ result.value }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="evaluationSteps"
                    class="mb-8"
                >
                    <h4>Spår Resultat</h4>
                    <div
                        v-if="evaluationStepDone"
                        style="color: green"
                    >
                        Klarmarkerat
                    </div>
                    <div style="display: flex; justify-content: space-between">
                        <span style="font-weight: bold"> Rubrik </span>
                        <span style="font-weight: bold"> Värde </span>
                    </div>
                    <div>
                        <div
                            v-for="(steps, idx) in evaluationSteps"
                            :key="idx"
                            class="evaluation-results"
                        >
                            <div>
                                {{ steps.name }}
                            </div>
                            <div>
                                <div 
                                    v-for="(evals, index) in steps.evaluation"
                                    :key="index"
                                >
                                    <div
                                        v-if="!evals.name.includes('comment')"
                                    >
                                        <div style="display: flex; justify-content: space-between">
                                            <div>
                                                {{ evals.name }}
                                            </div>
                                            <div>
                                                {{ evals.value }}
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        v-else
                                        style="display: flex; flex-direction: column;"
                                    >
                                        <div>
                                            <div>
                                                <div>
                                                    {{ evals.name }}
                                                </div>
                                            </div>
                                            <div style="text-align: left;">
                                                <div
                                                    v-html="evals.value"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="evaluationChase"
                    class="mb-8"
                >
                    <h4>Drev Resultat</h4>
                    <div
                        v-if="evaluationChaseDone"
                        style="color: green"
                    >
                        Klarmarkerat
                    </div>
                    <div style="display: flex; justify-content: space-between">
                        <span style="font-weight: bold"> Rubrik </span>
                        <span style="font-weight: bold"> Värde </span>
                    </div>
                    <div>
                        <div
                            class="evaluation-results"
                        >
                            <div style="display: flex; justify-content: space-between">
                                <div>
                                    Drev dag
                                </div>
                                <div>
                                    {{ evaluationChase.chase_day }}
                                </div>
                            </div>
                        </div>
                        <div
                            v-for="(drev, idx) in evaluationChase.drev"
                            :key="idx"
                            class="evaluation-results"
                        >
                            <div>
                                {{ drev.name }}
                            </div>
                            <div>
                                <div 
                                    v-for="(evals, index) in drev.results"
                                    :key="index"
                                >
                                    <div v-if="evals.name === 'Upptag'">
                                        <div
                                            v-for="(uptake) in evals.value"
                                            :key="uptake.name"
                                        >
                                            <div style="display: flex; justify-content: space-between">
                                                <div>
                                                    {{ uptake.name }}
                                                </div>
                                                <div>
                                                    {{ uptake.value }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-else-if="!evals.name.includes('comment')"
                                    >
                                        <div style="display: flex; justify-content: space-between">
                                            <div>
                                                {{ evals.name }}
                                            </div>
                                            <div>
                                                {{ evals.value }}
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        v-else
                                        style="display: flex; flex-direction: column;"
                                    >
                                        <div>
                                            <div>
                                                <div>
                                                    {{ evals.name }}
                                                </div>
                                            </div>
                                            <div style="text-align: left;">
                                                <div
                                                    v-html="evals.value"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="evaluationForestChase"
                    class="mb-8"
                >
                    <h4>Drev Resultat</h4>
                    <div style="display: flex; justify-content: space-between">
                        <span style="font-weight: bold"> Rubrik </span>
                        <span style="font-weight: bold"> Värde </span>
                    </div>
                    <div>
                        <div
                            v-for="(evaluation, idx) in evaluationForestChase"
                            :key="idx"
                            class="evaluation-results"
                        >
                            <h5>Drev {{ idx + 1 }}</h5>
                            <div
                                v-for="(value, key) in evaluation"
                                :key="key"
                            >
                                <div
                                    style="display: flex; justify-content: space-between"
                                >
                                    <div>
                                        <div>
                                            {{ key }}
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            {{ value }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <PreviewResultsContent
                    v-if="prepTracksResults"
                    :results="prepTracksResults"
                    :textareaFields="textareaFields"
                    :evaluationDone="evaluationDone"
                    title="Förutsättningar Spårläggning"
                />

                <PreviewResultsContent
                    v-if="prepEventResults"
                    :results="prepEventResults"
                    :textareaFields="textareaFields"
                    :evaluationDone="evaluationDone"
                    title="Förutsättningar Provtillfälle"
                />

                <PreviewResultsContent
                    v-if="evaluationResults"
                    :results="evaluationResults"
                    :textareaFields="textareaFields"
                    :evaluationDone="evaluationDone"
                    title="Bedömning Resultat"
                />
            </v-col>
            <v-col
                v-else
                align="center"
            >
                <h1>Kom ej till start</h1>
            </v-col>
        </v-card-text>
        <v-card-actions>
            <v-col align="center">
                <small
                    v-if="!canSendToSKK"
                    style="opacity: 0.5;"
                >
                    Allt måste vara klarmarkerat för att kunna skicka in resultat
                </small>
                <small v-else-if="!isOnline">
                    Du måste ha internet för att skicka resultaten till SKK Start
                </small>
                <v-btn
                    color="info"
                    block
                    variant="tonal"
                    :disabled="skkAjaxLock || !canSendToSKK || !isOnline"
                    @click="$emit('previewGood')"
                >
                    Skicka till SKK Start Web
                </v-btn>
                <v-btn
                    color="primary"
                    block
                    variant="tonal"
                    style="margin-inline-start: 0px !important"
                    @click="$emit('previewBad')"
                >
                    Tillbaka
                </v-btn>
            </v-col>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts">
import { 
    useEvaluationsStore,
    TypeRegistration,
    useUserStore,
    TypeEvent
} from '@/stores/store';

import {
    getRawJsonnetFile,
    getEventJsonnetType
} from '@/helpers/InputHelpers'
import { IStorageService } from '@/services/storageService';

import { defineComponent } from 'vue';

import skk from './translations/skk.json';
import { isDevided } from '@/helpers/eventInfoHelper';
import PreviewResultsContent from './PreviewResultsContent.vue';

const extrasMap = {
    'bloodtracking': ['conditions_tracks', 'conditions_event'],
    'hunting_tolling': ['workshot', 'summary', 'price'],
    'hunting_tolling_practical': ['condition', 'workshot', 'summary', 'price'],
    'chase': ['extra', 'chasestory'],
    'chase_basset': ['extra', 'chasestory'],
    'chase_adb': ['extra', 'chasestory'],
    'chase_tax': ['extra', 'chasestory'],
    'hunting_swk': ['property_workmoments', 'release', 'price', 'test_story'],
    'hunting_tjtk': ['property_workmoments', 'release', 'price', 'test_story'],
    'hunting_tjtk_anlagsprov_skog': ['teeth', 'work_moments', 'price'],
    'hunting_tjtk_skott_vatten': ['teeth', 'work_moments'],
    'hunting_tjtk_anlagsprov_falt': ['teeth', 'work_moments', 'price'],
    'hunting_tjtk_anlagsprov_vildsvin': ['teeth', 'work_moments', 'price'],
} as { [key: string]: Array<String> };

function checkExtras(
    evals: Array<{doc_id: string, done: boolean|undefined}>,
    extras: Array<String>
): boolean {
    const filteredEvals = evals.filter((v) => extras.includes(v.doc_id));
    const evalDone = filteredEvals.flatMap((v) => v?.done || false);
    return evalDone.every((v) => v) || false
};

export default defineComponent({
    name: 'PreviewSkkResult',
    components: {
        PreviewResultsContent
    },
    props: {
        currEvent: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        currReg: {
            type: Object,
            required: true,
        },
        skkAjaxLock: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['previewBad', 'previewGood'],
    data() {
        const textareaFields = [
            'Domarberättelse', 
            'Domarens kommentarer', 
            'Domarkommentar',
            'Drevprovsberättelse',
            'Sammanfattande Kritik',
            'Provberättelse',
            'Anteckningsruta',
            'Första lockarbetet, sammanfattning',
            'Andra lockarbetet, sammanfattning',
            'Ev ytterligare lockarbeten, sammanfattning',
            'Arbete efter skott'
        ];
        return {
            evaluationStore: useEvaluationsStore(),
            userStore: useUserStore().getUser(),
            tracker: 0,
            currentJson: null as any,
            textareaFields,
            cancelled: false,
        };
    },
    methods: {
        getPrepResults(doc: string) {
            const preps = this.evaluationStore.evaluations.find(
                (v) => v.doc_id === doc
            ) ?? {};

            const excludes = ['done', 'metadata', 'path', 'doc_id'];

            return Object.entries(preps)
                .reduce((acc: any[], [key, value]: [string, any]) => {
                    if (excludes.includes(key)) {
                        return acc;
                    }

                    if (key === 'registration_track_laid_out') {
                        const date = new Date(value.value);
                        value.value = date.toLocaleDateString('sv-SE', {
                            hour: '2-digit',
                            minute: '2-digit'
                        });
                    }

                    if (key === 'registration_terrain_types' && Array.isArray(value.value)) {
                        value.value.forEach((v: any) => {
                            acc.push({
                                name: this.translations[v] || v,
                                scope: key,
                                value: 'Ja',
                                value_text: value.value_text,
                                comment: null
                            });
                        });
                        return acc;
                    }

                    acc.push({
                        name: this.translations[key] || key,
                        scope: key,
                        value: this.translations[value.value] || value.value,
                        value_text: value.value_text,
                        comment: key.includes('comment') ? null : value.comment
                    });

                    return acc;
                }, []);
        },

        secondsToHms(seconds: number): string {
            if (!seconds) {
                return '0h 0m 0s'
            };

            const h = Math.floor(seconds / 3600);
            const m = Math.floor((seconds % 3600) / 60);
            const s = seconds % 60;
            return `${h}h ${m}m ${s}s`;
        },
    },
    computed: {
        isOnline() {
            return window.navigator.onLine;
        },

        translations() {
            return skk.translations as { [key: string]: string };
        },

        isSearch() {
            return this.currEvent.categories.findIndex((type:any) => type.value === 'Eftersök') !== -1;
        },

        canSendToSKK() {
            if (this.currEvent.type === 'working_test' ) {
                return (this.controlDone && this.workingtestResults?.length) || this.isNoShow;
            }

            if (this.evaluationSteps) {
                return this.controlDone && 
                    this.evaluationDone &&
                    this.evaluationStepDone || 
                    this.isNoShow;
            }

            if (this.evaluationChase) {
                return this.controlDone && 
                    this.evaluationDone &&
                    this.evaluationChaseDone || 
                    this.isNoShow;
            }

            return this.controlDone && this.evaluationDone || this.isNoShow;
        },

        controlDone() {
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }
            const findResult = this.evaluationStore.evaluations.find((v) => v.doc_id === 'control');

            if (!findResult) {
                return null;
            }

            return findResult.done || null;
        },

        controlsResults() {
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }
            const findResult = this.evaluationStore.evaluations.find((v) => v.doc_id === 'control');

            if (!findResult) {
                return null;
            }

            return findResult.control_result || null;
        },

        swkHuntingExtraDone() {
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }

            const evals = this.evaluationStore.evaluations;
            const hasChase = evals.find((v) => v.doc_id === 'chase');

            const evalDone = [
                ...evals.find((v) => v.doc_id === 'moment')?.done || false,
                ...hasChase ? (hasChase?.done || false) : true,
                ...evals.find((v) => v.doc_id === 'price')?.done || false,
            ];

            return evalDone.every((v) => v) || null;
        },

        evaluationDone() {
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }
            let evalDocName = 'evaluation'

            if (
                this.currEvent.type === 'bloodtracking' ||
                this.currEvent.type === 'chase' ||
                this.currEvent.type === 'chase_tax' ||
                this.currEvent.type === 'chase_adb' ||
                this.currEvent.type === 'chase_basset'
            ) {
                evalDocName = 'registration'
            }

            if (['hunting_tolling', 'hunting_tolling_practical'].includes(this.currEvent.type)) {
                evalDocName = 'baitwork'
            }

            if ([
                'hunting_swk',
                'hunting_tjtk',
                'hunting_tjtk_skott_vatten',
                'hunting_tjtk_anlagsprov_skog',
                'hunting_tjtk_anlagsprov_falt',
                'hunting_tjtk_anlagsprov_vildsvin'
            ].includes(this.currEvent.type)) {
                evalDocName = 'basis'
            }

            if (this.currEvent.type === 'hunting_swk_hunting') {
                evalDocName = 'condition'
            }

            if (this.currEvent.type === 'hunting_swk_forest') {
                evalDocName = 'performance'
            }

            if (this.currEvent.type === 'bird_skf' && isDevided(this.currEvent.categories)) {
                const evals = this.evaluationStore.evaluations;
                const partOneDone = evals.some((v: { doc_id: string, done: boolean }) => {
                    return v.doc_id === 'devidedFirst' && v.done;
                });
                const partTwoDone = evals.some((v: { doc_id: string, done: boolean }) => {
                    return v.doc_id === 'devidedSecond' && v.done;
                });
                if (partOneDone) {
                    evalDocName = 'devidedFirst';
                }
                if (partTwoDone) {
                    evalDocName = 'devidedSecond';
                }
            }
            const evalDone = this.evaluationStore.evaluations.find((v) => v.doc_id === evalDocName);

            if (!evalDone) {
                return null;
            }

            if (this.currEvent.type === 'hunting_swk_hunting') {
                return (evalDone.done && this.swkHuntingExtraDone) || null;
            }

            const extras = extrasMap[this.currEvent.type];
            if (extras) {
                const evals = this.evaluationStore.evaluations;

                return evalDone.done && checkExtras(evals, extras);
            }

            return evalDone.done || null;
        },

        evaluationStepDone() {
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }
            const evalDone = this.evaluationStore.evaluations.find((v) => v.doc_id === 'steps');

            if (!evalDone) {
                return null;
            }

            return evalDone.done || null;
        },

        evaluationChaseDone() {
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }
            const evalDone = this.evaluationStore.evaluations.find((v) => v.doc_id === 'chase');

            if (!evalDone) {
                return null;
            }

            return evalDone.done || null;
        },

        evaluationSteps() {
            if (!this.currentJson) {
                return null;
            }

            if (!this.evaluationStore.evaluations.length) {
                return null;
            }

            const findResult = this.evaluationStore.evaluations.find((v) => v.doc_id === 'steps');

            if (!findResult) {
                return null;
            }

            const valueObject = { ...findResult };
            delete valueObject.doc_id;
            delete valueObject.metadata;
            delete valueObject.path;
            delete valueObject.done;

            const retArray = [] as any;
            let evals: any[] = [];
            let steps;
            let totalTime = null;

            switch (this.currEvent.type) {
                case 'bloodtracking':
                    if (this.currReg.class === 'Anlagsklass') {
                        evals = this.currentJson.steps.anlagsklass;
                        steps = this.currentJson.step_list.anlagsklass;
                    } else if (this.currReg.class === 'Öppen klass') {
                        evals = this.currentJson.steps.oppen_klass;
                        steps = this.currentJson.step_list.oppen_klass;
                    }

                    const start = valueObject?.start?.worldTime?.seconds;
                    const stop = valueObject?.stop?.worldTime?.seconds;

                    if (start && stop) {
                        totalTime = this.secondsToHms(stop - start);
                    }

                    break;
                default:
                    return [];
            }

            if (!evals.length) {
                return [];
            }

            if (!this.cancelled && totalTime) {
                retArray.push({
                    evaluation: [
                        {
                            name: 'Total tid',
                            value: totalTime
                        }
                    ]
                })
            }

            steps.forEach((step: { id: number, name: string, stateName: string }) => {
                const evaluation = evals.find((ival: any) => ival.state === step.stateName);

                // @ts-ignore
                const header = this.translations[evaluation.state] || evaluation.state;
                const children: any[] = [];

                evaluation.evaluation.inputs.forEach((input: { scope: string, type: string, value: unknown }) => {
                    const inputValue = valueObject[input.scope];

                    if (!inputValue) {
                        return;
                    }

                    if (inputValue.value === 'tracking_cancelled') {
                        this.cancelled = true;
                    }

                    children.push({
                        // @ts-ignore
                        name: this.translations[inputValue.type] || inputValue.type,
                        // @ts-ignore
                        value: this.translations[inputValue.value_text] || inputValue.value_text
                    });
                });

                if (children.length) {
                    retArray.push(
                        {
                            name: header,
                            evaluation: children
                        }
                    );
                }
            })

            return retArray;
        },

        workingtestResults() {
            if (!this.evaluationStore.evaluations.length || this.currEvent.type !== 'working_test') {
                return null;
            }

            const stations = [ ...this.currEvent.stations ];
            const allowedStations = stations.filter((v: any) => v.referee_user_ids.includes(this.userStore.user.id));
            const evals = [] as any[];

            allowedStations.forEach((allowedStation: any) => {
                const stationResults = this.evaluationStore.evaluations.find((v) => v.doc_id === `station_${allowedStation.station_number}`);
                if (!stationResults) {
                    return;
                }

                if (!Object.prototype.hasOwnProperty.call(stationResults, 'station_input')) {
                    return;
                }
                const { station_input } = stationResults;
                const { done } = stationResults;

                evals.push(
                    {
                        title: `Station ${allowedStation.station_number}`,
                        scope: station_input.type,
                        // @ts-ignore
                        name: this.translations[station_input.type] || station_input.type,
                        // @ts-ignore
                        value: typeof station_input.value === 'string' ? this.translations[station_input.value] : station_input.value,
                        done: done || false
                    }
                );
                
            });

            return evals;
        },

        evaluationForestChase() {
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }

            const findResult = { ...this.evaluationStore.evaluations.find((v) => v.doc_id === 'chase') };

            if (!Object.prototype.hasOwnProperty.call(findResult, 'forest_runs')) {
                return null;
            }

            const fruns = findResult.forest_runs;

            if (!(Object.prototype.hasOwnProperty.call(fruns, 'value') && fruns.value.length)) {
                return null;
            }

            return fruns.value.map((run: any) => {
                if (run.animal_other === '') {
                    delete run.animal_other;
                }
                const e = Object.entries(run).sort((a, b) => a[0].localeCompare(b[0]));
                const ret = e.flatMap((ent: any) => 
                    [
                        [this.translations[ent[0]], this.translations[ent[1]] ?? ent[1]]
                    ]
                );

                return Object.fromEntries(ret);
            });
        },

        evaluationChase() {
            if (['hunting_swk_hunting', 'hunting_swk_forest'].includes(this.currEvent.type)) {
                return null;
            }
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }

            const findResult = { ...this.evaluationStore.evaluations.find((v) => v.doc_id === 'chase') };

            if (!Object.values(findResult).length) {
                return null;
            }

            const chaseOrdering = {
                'Släpp': 1,
                'Slag': 2,
                'Upptag': 3,
                'Sista skall': 4,
                'Inkall': 5,
                'Kopplad': 6,
                'Skallfrekvens': 7,
                'Viltslag': 8,
                'Använt pejl för inkallning?': 9
            } as { [key: string]: number };

            const chaseRet = {
                chase_day: findResult.chase_day ? findResult.chase_day.value : 'Ingen dag vald',
                drev: [] as Array<{name: string, results: Array<{name: string, value: any}>}>
            } as { [key: string]: any };


            delete findResult.done;
            delete findResult.doc_id;
            delete findResult.chase_day;
            delete findResult.metadata;
            delete findResult.path;

            Object.entries(findResult).forEach(([key, value]: [string, any]) => {
                const keySplit = key.split('_');
                // @ts-ignore
                const relaeaseName = `${this.translations[keySplit[0]]} ${Number(keySplit[1]) + 1}`

                const drev = {
                    name: relaeaseName,
                    results: [] as Array<{name: string, value: any}>
                }
                
                Object.entries(value.results).forEach(([resKey, resValue]: [string, any]) => {

                    if (typeof resValue === 'string') {
                        if (resValue.split(':').length === 2) {
                            drev.results.push({
                                name: this.translations[resKey] || resKey,
                                value: resValue
                            })
                        } else {
                            drev.results.push({
                                name: this.translations[resKey] || resKey,
                                value: this.translations[resValue]
                            })
                        }
                    }
                    
                    if (typeof resValue === 'number') {
                        drev.results.push({
                            name: this.translations[resKey] || resKey,
                            value: resValue
                        })
                    }
                    if (Array.isArray(resValue)) {
                        resValue.forEach((uptake, index) => {
                            if (uptake.from === '' && uptake.to === '') {
                                return;
                            }

                            let upptag = drev.results.find((v) => v.name === 'Upptag');

                            if (!upptag) {
                                upptag = {
                                    name: 'Upptag',
                                    value: []
                                }

                                drev.results.push(upptag);
                            }

                            upptag.value.push({
                                name: `Drev ${index + 1}`,
                                value: `från ${uptake.from} till ${uptake.to}` 
                            })
                        })
                    }


                })
                
                drev.results.sort((a, b) => chaseOrdering[a.name] - chaseOrdering[b.name]);
                chaseRet.drev.push(drev);
            })

            chaseRet.drev.sort((a: any, b: any) => Number(a.name.split(' ')[1]) - Number(b.name.split(' ')[1]));
            return chaseRet;
        },

        swkForestWeather() {
            if (this.currEvent.type !== 'hunting_swk_forest') {
                return false;
            }
            const evals = this.evaluationStore.evaluations.find((v) => v.doc_id === 'performance');
            if (!evals) {
                return false;
            }
            return [
                {
                    name: 'Temperatur', 
                    value: evals.temperature.value, 
                },
                {
                    name: 'Särskilda väderförhållanden',
                    value: evals.weather_conditions.comment,
                },
                {
                    name: 'Barmark',
                    value:  this.translations[evals.bareground.value] ?? evals.bareground.value,
                }
            ]
        },

        prepTracksResults() {
            if (this.currEvent.type !== 'bloodtracking') {
                return null;
            }
            return this.getPrepResults('conditions_tracks');
        },

        prepEventResults() {
            if (this.currEvent.type !== 'bloodtracking') {
                return null;
            }
            return this.getPrepResults('conditions_event');
        },

        evaluationResults() {
            if (!this.currentJson) {
                return null;
            }
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }
            if (this.currEvent.type === 'working_test') {
                return null;
            }
            let additionalEvals = null;
            let evalDocName = 'evaluation'

            if (this.currEvent.type === 'bloodtracking') {
                evalDocName = 'registration'
                additionalEvals = this.evaluationStore.evaluations.find(
                    (v) => v.doc_id === 'conditions_event'
                );
            }

            if (['hunting_tolling', 'hunting_tolling_practical'].includes(this.currEvent.type)) {
                const evals = this.evaluationStore.evaluations;
                evalDocName = 'baitwork';
                const evalIds = ['workshot', 'summary', 'price'];
                if (this.currEvent.type === 'hunting_tolling_practical') {
                    evalIds.push('condition');
                }
                additionalEvals = evals
                    .filter((v) => evalIds.includes(v.doc_id))
                    .reduce((a, c) => Object.assign(a, c), {});
            }

            if (this.currEvent.type === 'hunting_swk_forest') {
                const evals = this.evaluationStore.evaluations;
                evalDocName = 'performance';
                additionalEvals = evals
                    .filter((v) => ['test_story', 'results'].includes(v.doc_id))
                    .reduce((a, c) => Object.assign(a, c), {});
            }

            if (
                this.currEvent.type === 'chase' || 
                this.currEvent.type === 'chase_basset' ||
                this.currEvent.type === 'chase_tax' ||
                this.currEvent.type === 'chase_adb'
            ) {
                evalDocName = 'registration'
                additionalEvals = { ...this.evaluationStore.evaluations.find((v) => v.doc_id === 'chasestory'), ...this.evaluationStore.evaluations.find((v) => v.doc_id === 'extra')}
            }

            if (['hunting_swk', 'hunting_tjtk'].includes(this.currEvent.type)) {
                const evals = this.evaluationStore.evaluations;
                evalDocName = 'basis'
                additionalEvals = {
                    ...evals.find((v) => v.doc_id === 'property_workmoments'),
                    ...evals.find((v) => v.doc_id === 'release'),
                    ...evals.find((v) => v.doc_id === 'price'),
                    ...evals.find((v) => v.doc_id === 'test_story'),
                }
            }

            if (this.currEvent.type === 'hunting_swk_hunting') {
                const evals = this.evaluationStore.evaluations;
                evalDocName = 'condition'
                const chaseEval = evals.find((v) => v.doc_id === 'chase') ? evals.find((v) => v.doc_id === 'chase') : {};

                additionalEvals = {
                    ...evals.find((v) => v.doc_id === 'moment'),
                    ...chaseEval,
                    ...evals.find((v) => v.doc_id === 'price'),
                }
            }
            if ([
                'hunting_tjtk_skott_vatten',
                'hunting_tjtk_anlagsprov_skog',
                'hunting_tjtk_anlagsprov_falt',
                'hunting_tjtk_anlagsprov_vildsvin'
            ].includes(this.currEvent.type)) {
                const evals = this.evaluationStore.evaluations;
                evalDocName = 'basis'
                additionalEvals = {
                    ...evals.find((v) => v.doc_id === 'teeth'),
                    ...evals.find((v) => v.doc_id === 'work_moments'),
                }
            }
            if ([
                'hunting_tjtk_anlagsprov_skog',
                'hunting_tjtk_anlagsprov_falt',
                'hunting_tjtk_anlagsprov_vildsvin'
            ].includes(this.currEvent.type)) {
                const evals = this.evaluationStore.evaluations;
                additionalEvals = {
                    ...additionalEvals,
                    ...evals.find((v) => v.doc_id === 'price'),
                }
            }
            if (this.currEvent.type === 'bird_skf' && isDevided(this.currEvent.categories)) {
                const evals = this.evaluationStore.evaluations
                const partOneDone = evals.some((v: { doc_id: string, done: boolean }) => {
                    return v.doc_id === 'devidedFirst' && v.done
                });
                const partTwoDone = evals.some((v: { doc_id: string, done: boolean }) => {
                    return v.doc_id === 'devidedSecond' && v.done
                });
                if (partOneDone) {
                    evalDocName = 'devidedFirst';
                }
                if (partTwoDone) {
                    evalDocName = 'devidedSecond';
                }
            }

            let findResult = this.evaluationStore.evaluations.find((v) => v.doc_id === evalDocName);
            if (additionalEvals) {
                findResult = { ...findResult, ...additionalEvals };
            }

            if (!findResult) {
                return null;
            }

            const valueObject = { ...findResult };
            delete valueObject.doc_id;
            delete valueObject.metadata;
            delete valueObject.path;
            delete valueObject.done;

            const retArray = [] as any;
            let evals;

            const currJsonnet = getEventJsonnetType(this.currentJson, this.currEvent.id, this.currReg.id);

            switch (this.currEvent.type) {
                case 'bird_skf':
                case 'bird_fa':
                case 'fa_highstatus':
                case 'hunting_retriever_a':
                case 'hunting_retriever_b':
                case 'hunting_spaniel_falt':
                case 'hunting_mock_trial':
                case 'hunting_retriever_function':
                    evals = currJsonnet.evaluation;
                    break;
                case 'hunting_spaniel_vatten':
                    evals = currJsonnet.water.evaluation;
                    break;
                case 'hunting_spaniel_nyborjare':
                    evals = currJsonnet.beginner.evaluation;
                    break;
                case 'hunting_spaniel_wt':
                    evals = currJsonnet.working.evaluation;
                    break;
                case 'bloodtracking':
                    evals = currJsonnet?.registration ?? [];
                    break;
                case 'chase_basset':
                    evals = [
                        ...currJsonnet.registration,
                        ...currJsonnet.extra,
                        ...currJsonnet.chasestory
                    ];
                    break;
                case 'chase_tax':
                    evals = [
                        ...currJsonnet.registration,
                        ...currJsonnet.extra,
                        ...currJsonnet.chasestory
                    ];
                    break;
                case 'chase_adb':
                    evals = [
                        ...currJsonnet.registration,
                        ...currJsonnet.extra,
                        ...currJsonnet.chasestory
                    ];
                    break;
                case 'chase':
                    evals = [
                        ...currJsonnet.registration, 
                        ...currJsonnet.extra, 
                        ...currJsonnet.chasestory
                    ];
                    break;
                case 'hunting_tjtk':
                case 'hunting_swk':
                    evals = [
                        ...currJsonnet.basis,
                        ...currJsonnet.property_workmoments,
                        ...currJsonnet.release,
                        ...currJsonnet.price,
                        ...currJsonnet.test_story
                    ];
                    break;
                case 'hunting_swk_hunting': {
                    switch (this.currReg.class) {
                        case 'Öppen klass':
                            evals = [
                                ...currJsonnet.okl.condition,
                                ...currJsonnet.okl.moment,
                                ...currJsonnet.okl.price,
                            ];
                            break;
                        default:
                            evals = [
                                ...currJsonnet.other.condition,
                                ...currJsonnet.other.moment,
                                ...currJsonnet.other.price,
                            ];
                            break;
                    }
                    break;
                }
                case 'hunting_swk_forest': {
                    const performance = currJsonnet.performance.filter((evaluation:any) => !['Temperatur', 'Särskilda väderförhållanden', 'Barmark'].includes(evaluation.title));
                    evals = [
                        ...performance,
                        ...currJsonnet.test_story,
                        ...currJsonnet.results,
                    ];
                    break;
                }
                case 'hunting_tolling':
                    evals = [
                        ...currJsonnet.baitwork,
                        ...currJsonnet.workshot,
                        ...currJsonnet.summary,
                        ...currJsonnet.price
                    ];
                    break;
                case 'hunting_tolling_practical':
                    evals = [
                        ...currJsonnet.condition,
                        ...currJsonnet.baitwork,
                        ...currJsonnet.workshot,
                        ...currJsonnet.summary,
                        ...currJsonnet.price
                    ];
                    break;
                case 'hunting_tjtk_anlagsprov_skog':
                    evals = [
                        ...currJsonnet.teeth,
                        ...currJsonnet.basis,
                        ...currJsonnet.work_moments,
                        ...currJsonnet.price
                    ];
                    break;
                case 'hunting_tjtk_skott_vatten':
                    evals = [
                        ...currJsonnet.teeth,
                        ...currJsonnet.basis,
                        ...currJsonnet.work_moments
                    ];
                    break;
                case 'hunting_tjtk_anlagsprov_falt':
                    evals = [
                        ...currJsonnet.teeth,
                        ...currJsonnet.basis,
                        ...currJsonnet.work_moments,
                        ...currJsonnet.price
                    ];
                    break;
                case 'hunting_tjtk_anlagsprov_vildsvin':
                    evals = [
                        ...currJsonnet.teeth,
                        ...currJsonnet.basis,
                        ...currJsonnet.work_moments,
                        ...currJsonnet.price
                    ];
                    break;
                default:
                    evals = [];
                    break;
            }

            if (!evals.length) {
                return [];
            }

            if (this.isSearch) {
                evals = evals.filter((item:any) => item.title !== 'Tilläggspris')
            }

            evals.forEach((evaluation: any) => {
                const scopes = evaluation.inputs.map((input: any) => ({
                    scope: input.scope,
                    // @ts-ignore
                    name: this.translations[input.scope] || input.scope,
                    type: input.type,
                    values: input.values ?? null,
                }));

                scopes.forEach((scope: any) => {
                    if (!scope.scope) {
                        return;
                    }

                    if (scope.name.includes('Sammanfattande kritik') && valueObject[scope.scope]) {
                        retArray.push({
                            name: scope.name,
                            value: valueObject[scope.scope].value_text,
                        });
                        return;
                    }

                    if (scope.name === 'Summering' && scope.values) {
                        const value_scopes = scope.values.map((v2: any) => ({
                            scope: v2.scope,
                            // @ts-ignore
                            name: this.translations[v2.scope] || v2.scope,
                        }));

                        value_scopes.forEach((value_scope: any) => {
                            const value = valueObject[value_scope.scope];
                            if (!value) {
                                return;
                            }

                            retArray.push({
                                name: value_scope.name,
                                // @ts-ignore
                                value: this.translations[value.value_text] || value.value,
                            });
                        });
                        return;
                    }

                    const value = valueObject[scope.scope];

                    if (!value) {
                        return;
                    }

                    if (scope.name.includes('koder') && scope.values) {
                        if (!value) return;
                        const codes = value.value;

                        if (!codes) return;

                        const stringArray = codes.map((code: { value: string }) => (
                            `${scope.values.find((v: {value: string}) => v.value === code.value).name}`
                        )) as Array<String>;

                        retArray.push({
                            name: scope.name,
                            value: stringArray.join(', '),
                        });
                        return;
                    }

                    if (scope.name === 'Tilläggsregistrering tassdjur' || scope.name === 'Tilläggsregistrering klövdjur') {
                        const chaseResults = this.evaluationStore.evaluations.find((v) => v.doc_id === 'chase');

                        if (!chaseResults) {
                            return;
                        }

                        const chaseEntries = Object.entries(chaseResults);

                        const [,selectedChase] = chaseEntries.find(
                            ([,cValue]: [string, any]) => cValue.id === value.value
                        ) as [string, any];

                        if (!selectedChase) {
                            return;
                        }

                        if (!selectedChase.results && !Object.prototype.hasOwnProperty.call(selectedChase.results, 'gamekind')) {
                            return;
                        }

                        retArray.push({
                            name: scope.name,
                            value: this.translations[selectedChase.results.gamekind] || selectedChase.results.gamekind
                        });
                        return;
                    }


                    if (Array.isArray(value.value)) {
                        if (scope.type === 'checkbox_group') {
                            const translated = value.value.map((v: string) => this.translations[v]);
                            retArray.push({
                                name: this.translations[scope.scope],
                                value: translated.join(', '),
                                comment: value.comment ?? null
                            });
                            return;
                        }

                        if (['registration_terrain_types'].includes(scope.scope)) {
                            value.value.forEach((v: string) => {
                                retArray.push({
                                    // @ts-ignore
                                    name: this.translations[v],
                                    value: 'Ja'
                                });
                            });
                            return;
                        }

                        value.value.forEach((valueObj: any, idx: number) => {
                            const entriesC = Object.entries(valueObj).map((val) => [
                                // @ts-ignore
                                `${['bloodtracking', 'hunting_swk_hunting', 'bird_skf'].includes(this.currEvent.type) ? 'Släpp' : 'Pris'} ${idx + 1} - ${this.translations[val[0]] || val[0]}`,
                                val[1],
                            ]) as [string, unknown][];

                            entriesC.forEach((v: [string, unknown]) => {
                                retArray.push({
                                    name: v[0],
                                    // @ts-ignore
                                    value: typeof v[1] === 'string' ? this.translations[v[1]] : v[1],
                                });
                            });
                        });
                        return;
                    }

                    if (this.currEvent.type === 'hunting_swk_hunting') {
                        const inclArr = [
                            'searchwork_forest',
                            'waterwork',
                            'chase_nose',
                            'chase_bark',
                            'chase_track_will',
                            'chase_track_security',
                            'rigour',
                            'shot_reaction',
                        ];
                        if (inclArr.includes(scope.scope)) {
                            let text_value = `${value.value} - ${this.translations[value.value_text] ?? value.value_text}`
                            if (scope.scope === 'shot_reaction') {
                                text_value = value.value
                            }

                            retArray.push({
                                name: scope.name,
                                value: text_value,
                                comment: value.comment ?? null,
                            });
                            return;
                        }

                        if (scope.type === 'chase-compact' || scope.type === 'comment') {
                            value.value = 0;

                            retArray.push({
                                name: scope.name,
                                value: value.value_text,
                            });
                            return;
                        }
                    }

                    if (['Provslag', 'Provtyp', 'Provform', 'Klass'].includes(scope.name)) {
                        value.value_text = value.value;
                    }
                    
                    if (['Spår lagt', 'Datum gjord'].includes(scope.name)) {
                        const date = new Date(value.value);
                        value.value = date.toLocaleDateString('sv-SE', { hour: '2-digit', minute: '2-digit' });
                    }

                    if (value.value === -1 && this.currEvent.type !== 'chase_tax') {
                        retArray.push({
                            name: scope.name,
                            value: 'KEB',
                        });
                        return;
                    }
               
                    if (this.currEvent.type === 'chase_tax' && scope.type === 'range') {
                        let valueToshow = `${value.value_text}(${value.value})`;
                        if (value.value === 0) {
                            valueToshow = 'Ingen... (-)';
                        }
                        if (scope.scope === 'reward') {
                            valueToshow = value.value;
                        }
                        if (value.value === -1) {
                            valueToshow = 'KEP';
                        }
                        retArray.push({
                            name: scope.name,
                            value: valueToshow,
                        });
                        return;
                    }

                    if (this.currEvent.type === 'hunting_tolling' && scope.scope === 'price') {
                        retArray.push({
                            name: scope.name,
                            value: value.value_text,
                            comment: value.comment ? value.comment : ''
                        });
                        return;
                    }
                    if (this.currEvent.type === 'hunting_tolling_practical' && scope.scope === 'price') {
                        retArray.push({
                            name: scope.name,
                            value: this.translations[value.value_text],
                            comment: value.comment ? value.comment : ''
                        });
                        return;
                    }

                    if (scope.type === 'range') {
                        let pushValue =`${value.value_text}(${value.value})`

                        if (value.value === 0) {
                            pushValue = value.value_text
                        }

                        if (this.currEvent.type === 'hunting_swk_forest') {
                            pushValue = value.value;
                        }
                        if (this.currEvent.type === 'hunting_tjtk_skott_vatten' && value.value === 5) {
                            pushValue = `${value.value_text}(4h)`
                        }

                        retArray.push({
                            name: this.translations[scope.scope],
                            value: pushValue,
                            comment: value.comment ? value.comment : ''
                        });
                        return;
                    }

                    const comment = !scope.scope.includes('comment') && value.comment ? value.comment : '';

                    retArray.push({
                        name: scope.name,
                        // @ts-ignore
                        value: this.translations[value.value_text] || value.value,
                        comment
                    });
                });
            });

            if (Object.prototype.hasOwnProperty.call(valueObject, 'no_show')) {
                retArray.unshift({
                    // @ts-ignore
                    name: valueObject.no_show.type,
                    value: valueObject.no_show.value,
                });
            }
            return retArray.filter(
                (value: any, index: number, self: any) =>
                    index === self.findIndex((t: any) => t.name === value.name && t.value === value.value),
            );
        },

        isNoShow() {
            if (!this.evaluationResults) {
                return 0;
            }
            if (this.evaluationResults.length && this.evaluationResults[0].name === 'no_show') {
                return this.evaluationResults[0].value;
            }
            return 0;
        },
    },
    async mounted() {
        const storageServ = (this as any).$storageServ as IStorageService;
        this.currentJson = await getRawJsonnetFile(storageServ, this.currEvent.id, this.currReg.id);
    },
    created() {
        this.evaluationStore.init(this.currReg as TypeRegistration);
    },
});
</script>

<style>
.evaluation-results:nth-child(even) {
  background-color: rgb(240, 240, 240);
}
.evaluation-weather:nth-child(odd) {
    background-color: rgb(240, 240, 240);
}
</style>
