import { getEvents, getRegistrations, login } from '@/api/Api';

import { FirebaseFirestore, SnapshotMetadata, DocumentReference } from "@capacitor-firebase/firestore";
import { Timestamp } from "firebase/firestore";

import moment from 'moment';
import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';

import router from '@/router';

import { yesOrNoString } from '@/types';
import setVisitStarted from '@/helpers/SetVisitStarted';

type UserProperties = {
    name: string,
    id: number,
}
type EventProperties = {
    id: string;
    title: string;
    ts: Timestamp;
    visible?: boolean;
    categories: Array<{
        type: string
        value: string
    }>;
    type: string;
    stations?: Array<any>
}
type RegistrationProperties = {
    result: any;
    kennel: any;
    id: string;
    title: string;
    class: string;
    breed: string;
    chip: string;
    regnum: string;
    gender: string;
    ownerName: string;
    visible: boolean;
    paid: boolean;
    ts: Timestamp|string;
    sentTo: string | undefined;
    start_order: number | null;
    shooting: {
        champion: number,
        test_approved: number,
        test_date: string,
    };
}
type EventDateTs = {
    seconds: number;
}
type EvaluationProperties = {
    type: string,
    value_text: string,
    value: any,
    dynamic: any,
    scope: any,
}
export type DocumentProperties = {
    doc_id: string;
    path: string;
    metadata: SnapshotMetadata;
};

export type TypeRegistration = RegistrationProperties & DocumentProperties;
export type TypeEvent = EventProperties & DocumentProperties;
export type TypeEvaluation = EvaluationProperties;
export type TypeUser = UserProperties;
export type TypeEventDateTs = EventDateTs;

// @ts-ignore
const devNamespace = window.env.VUE_APP_NAMESPACE || process.env.VUE_APP_NAMESPACE;
const devEnv = devNamespace === 'dev'

const createDocumentReference = (id: string, path: string) => {
    return {
        id,
        path
    } as DocumentReference
}

export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        user: {} as TypeUser,
        endpoint: '',
    }),
    getters: {
        getEndpoint: (state) => state.endpoint,
        getUserID: (state) => {
            if (state.endpoint.includes('skk')) {
                return `${state.endpoint}User-${state.user.id}`;
            }

            return `kkUser-${state.user.id}`;
        },
        getCollectionPrefix: (state) => {
            if (devEnv) {
                if (state.endpoint === 'kk_local') {
                    return 'kk_test_';
                }
                return `${state.endpoint}_`;
            }
            return '';
        },
    },
    actions: {
        async auth(
            formData: FormData,
            ep: string
        ): Promise<{ success: boolean, reason: string, user: unknown }> {

            const response = await login(formData);
            if (response.status === 200) {
                this.user = response.data.user
                this.endpoint = ep as string;
                localStorage.setItem('user', JSON.stringify(response.data.user));
                localStorage.setItem('endpoint', this.endpoint)
                localStorage.setItem('jwtoken', response.data.token);

                return { success: true, reason: 'Succesfully logged in!', user: this.user };
            }
            return { success: false, reason: 'Could not login!', user: {} };
        },

        getUser() {
            if (localStorage.getItem('jwtoken')
                && localStorage.getItem('user')
                && localStorage.getItem('endpoint')
            ) {
                this.user = JSON.parse(localStorage.getItem('user') as string);
                this.endpoint = localStorage.getItem('endpoint') as string;

                return this;
            }

            console.error('User data corrupt, redirecting to login!');

            localStorage.removeItem('jwtoken');
            localStorage.removeItem('user');
            localStorage.removeItem('endpoint');

            router.push({
                name: 'auth'
            });

            return this;
        },

        destroy(): void {
            this.user = {} as TypeUser;
            this.endpoint = ''
            localStorage.removeItem('jwtoken');
            localStorage.removeItem('user');
            localStorage.removeItem('endpoint');
        }
    }
});

const range = () => {
    const getDaysOfMonth = (year: number, month: number) => new Date(year, month, 0).getDate()

    const today = new Date();
    const start = new Date();
    start.setMonth(today.getMonth() - 3);
    start.setDate(getDaysOfMonth(today.getFullYear(), today.getMonth()) - 3);

    const end = new Date();
    end.setMonth(today.getMonth() + 2);
    end.setDate(3);

    return {
        start,
        end
    }
};

const testtypes = [
    'bloodtracking',
    'chase',
    'chase_basset',
    'chase_tax',
    'chase_adb',
    'bird_skf',
    'bird_fa',
    'fa_highstatus',
    'working_test',
    'hunting_swk',
    'hunting_swk_hunting',
    'hunting_swk_forest',
    'hunting_retriever_a',
    'hunting_retriever_b',
    'hunting_retriever_function',
    'hunting_spaniel_vatten',
    'hunting_spaniel_nyborjare',
    'hunting_spaniel_falt',
    'hunting_spaniel_wt',
    'hunting_mock_trial',
    'hunting_tolling',
    'hunting_tolling_practical',
    'hunting_tjtk',
    'hunting_tjtk_skott_vatten',
    'hunting_tjtk_anlagsprov_skog',
    'hunting_tjtk_anlagsprov_falt',
    'hunting_tjtk_anlagsprov_vildsvin',
];

export const useEventStore = defineStore({
    id: 'events',
    state: () => ({
        events: [] as TypeEvent[],
        isLoading: false,
        initialized: false,
        synced: false,
        isSyncing: false,
        callbackId: null as null | string,
    }),
    getters: {
        getEventByID: (state) => (eventId: string) => state.events.find((event: TypeEvent) => event.id === eventId) as TypeEvent
    },
    actions: {
        async init(): Promise<void> {
            if (this.events.length) {
                return;
            }
            if (this.callbackId) {
                FirebaseFirestore.removeSnapshotListener({ callbackId: this.callbackId })
                this.callbackId = null
            }

            this.isLoading = true;
            const userStore = useUserStore().getUser();

            const dateRange = range();
            const registrationStore = useRegistrationsStore();

            this.callbackId = await FirebaseFirestore.addCollectionSnapshotListener({
                reference: `${userStore.getCollectionPrefix}events`,
                compositeFilter: {
                    type: 'and',
                    queryConstraints: [
                        {
                            type: 'where',
                            fieldPath: 'userId',
                            opStr: '==',
                            value: userStore.getUserID
                        },
                        {
                            type: 'where',
                            fieldPath: 'visible',
                            opStr: '==',
                            value: true
                        }
                    ]
                }
            }, (event, error) => {
                // Save the requests
                if (this.isSyncing) {
                    return;
                }

                const eventsArray = [] as TypeEvent[];
                event?.snapshots.forEach((doc) => {
                    const pushDoc = doc.data as TypeEvent;
                    let docTs = null;
                    if (typeof pushDoc.ts !== 'string') {
                        if (!pushDoc.ts.toDate) {
                            pushDoc.ts = Timestamp.fromMillis(pushDoc.ts.seconds * 1000);
                        }
                        docTs = pushDoc.ts.toDate();
                    } else {
                        docTs = new Date(pushDoc.ts)
                    }

                    if (docTs <= dateRange.start ||
                        docTs >= dateRange.end) {
                        return;
                    }
                    pushDoc.doc_id = doc.id
                    pushDoc.path = doc.path
                    pushDoc.metadata = doc.metadata
                    eventsArray.push(pushDoc);
                });
                this.events = eventsArray;

                if (this.initialized === false) {
                    this.events.forEach((e: TypeEvent) => {
                        registrationStore.prepareForOffline(e.path);
                    });
                    this.initialized = true;
                }
            });

            this.isLoading = false;
        },

        destroy(): void {
            if (this.callbackId) {
                FirebaseFirestore.removeSnapshotListener({ callbackId: this.callbackId })
                this.callbackId = null
            }
            this.events = [];
            this.isLoading = false;
            this.synced = false;
            this.isSyncing = false;
        },

        async sync(): Promise<void> {
            const user = useUserStore().getUser();

            if (user.endpoint.includes('skk')) {
                this.isSyncing = true
                try {
                    // sync with skk-start
                    const events = await getEvents();
                    const registrations = await getRegistrations();

                    if (events.status === 200 && registrations.status === 200) {
                        await this.hideFinishedEvents(events, user.endpoint, user.user.id);

                        await events.data.forEach(async (e: any) => {
                            const date = new Date(0)
                            date.setUTCSeconds(e.date.from);
                            const formattedDate = moment(date).format('YYYY-MM-DD');

                            const regs = registrations.data.filter((r: any) => r.eventId === e.id);

                            await this.addEvent(formattedDate, e.categories, e.type, user.endpoint as any, e.refereeStations, regs, e.title, e.id);
                        });
                        this.synced = true;
                    }
                } catch (error: any) {
                    if (error && error.status === 401) {
                        alert('Din session har gått ut. Logga in igen.');
                        useUserStore().destroy();
                    } else {
                        alert('Serverproblem, var vänlig försök igen');
                    }
                } finally {
                    this.isSyncing = false;
                }
            }
        },

        async hideFinishedEvents(
            events: any,
            ep: string,
            userId: number
        ): Promise<{ success: boolean, reason: string }> {

            const recivedIds = events.data.flatMap((revievedEvent: any) => ([`${ep}-${userId}-${revievedEvent.id}`]))
            const userStore = useUserStore().getUser();

            const { snapshots } = await FirebaseFirestore.getCollection({
                reference: `${userStore.getCollectionPrefix}events`,
                compositeFilter: {
                    type: 'and',
                    queryConstraints: [
                        {
                            type: 'where',
                            fieldPath: 'userId',
                            opStr: '==',
                            value: userStore.getUserID
                        }
                    ]
                }
            })

            snapshots.forEach(async (v) => {
                const d = v.data;

                if (!d) {
                    return;
                }

                if (recivedIds.includes(d.id)) {
                    return;
                }
                if (!d.visible) {
                    return;
                }

                await this.hideEvent(d.id, userStore);
            });

            return { success: true, reason: "" };
        },

        async hideEvent(
            eventId: string,
            userStore: any
        ): Promise<void> {
            await FirebaseFirestore.updateDocument({
                reference: `${userStore.getCollectionPrefix}events/${eventId}`,
                data: {
                    visible: false
                }
            });
            /* updateDoc(docRef, {
                visible: false,
            });*/
            // await deleteDoc(docRef);
        },

        async addEvent(
            date: string,
            categories: unknown[],
            type: string,
            ep: 'kk' | 'skktest' | 'skk' | 'skklocal' | 'skkdev',
            stations?: unknown[],
            registrations?: unknown[],
            title?: string,
            eventId?: number
        ): Promise<{ success: boolean, reason: string }> {

            const userStore = useUserStore().getUser()
            if (!userStore.user || date === '') {
                return { success: false, reason: 'Kunde inte hitta planeringsdag/användare' };
            }

            const eventName = title || `${userStore.user.name} - ${date}`;
            const id = `${ep}-${userStore.user.id}-${eventId || date}`;
            const { snapshot } = await FirebaseFirestore.getDocument({ reference: `${userStore.getCollectionPrefix}events/${id}` });

            if (!snapshot) {
                return { success: false, reason: 'Kunde inte hämta data' };
            }

            if (!snapshot.data) {
                const eventData = {
                    categories,
                    id,
                    title: eventName,
                    visible: true,
                    ts: Timestamp.fromDate(new Date(date)),
                    type,
                    stations: stations || [],
                    userId: `${ep}User-${userStore.user.id}`
                };

                await FirebaseFirestore.setDocument({ reference: snapshot.path, data: eventData })

                await this.hideFinishedRegs(id, registrations);

                if (testtypes.includes(type)) {
                    registrations?.forEach(async (reg: any) => {
                        const registrationData = {
                            result: reg.results || null,
                            breed: reg.competitor.metadata.breed || null,
                            chip: reg.competitor.metadata.chip || null,
                            gender: reg.competitor.metadata.gender || null,
                            regnum: reg.competitor.metadata.regId || null,
                            ownerName: `${reg.competitor.metadata.owner.firstname || '-'} ${reg.competitor.metadata.owner.lastname || '-'}`,
                            ownerPhoneNumber: reg.competitor.metadata.owner.phone_number || '-',
                            visible: true,
                            title: reg.competitor.name || null,
                            paid: reg.payment.paid || null,
                            ts: Timestamp.fromMillis(reg.scheduled_at || 0),
                            class: reg.class || null,
                            id: reg.id || null,
                            start_order: reg.start_order ?? null,
                            shooting: reg.shooting || null,
                        }

                        if (Object.prototype.hasOwnProperty.call(reg, 'bestAftersearch') && Array.isArray(reg.bestAftersearch) && reg.bestAftersearch.length) {
                            const evalPath = `${snapshot.path}/registrations/${reg.id}/results/evaluation`;
                            const evalDoc = await FirebaseFirestore.getDocument({ reference: evalPath });

                            const provform = categories.find((v: any) => v.type === 'Provform') as any

                            if (!evalDoc.snapshot.data && ['Fält', 'Fjäll', 'Skog'].includes(provform?.value)) {
                                const addData = new Map<string, any>;
                                reg.bestAftersearch.filter((d: any) => d.type !== 'undefined').forEach((v: any) => {
                                    addData.set((v as any).type, v);
                                });

                                const dataObj = Object.fromEntries(addData);

                                await FirebaseFirestore.setDocument({ reference: evalPath, data: dataObj })
                            }
                        }

                        await FirebaseFirestore.setDocument({
                            reference: `${snapshot.path}/registrations/${reg.id}`,
                            data: registrationData
                        });
                    });
                }
                return { success: true, reason: 'Event sparat!' };

            }

            if (testtypes.includes(type) && snapshot.data) {
                const dateRange = range();
                const eventDate = new Date(date);

                if (eventDate <= dateRange.start ||
                    eventDate >= dateRange.end) {

                    return { success: true, reason: '' };
                }

                const eventData = {
                    categories,
                    id,
                    title: eventName,
                    ts: Timestamp.fromDate(new Date(date)),
                    visible: true,
                    type,
                    stations: stations || [],
                    userId: `${ep}User-${userStore.user.id}`
                };
                await FirebaseFirestore.updateDocument({ reference: snapshot.path, data: eventData });

                await this.hideFinishedRegs(snapshot.id, registrations);

                const { snapshots } = await FirebaseFirestore.getCollection({ reference: `${snapshot.path}/registrations/` });

                registrations?.forEach(async (reg: any) => {
                    // const regPath = doc(db, `${userStore.getCollectionPrefix}events/${snap.id}/registrations/${reg.id}`);

                    const data = {
                        result: reg.results || null,
                        breed: reg.competitor.metadata.breed || null,
                        chip: reg.competitor.metadata.chip || null,
                        gender: reg.competitor.metadata.gender || null,
                        regnum: reg.competitor.metadata.regId || null,
                        ownerName: `${reg.competitor.metadata.owner.firstname || '-'} ${reg.competitor.metadata.owner.lastname || '-'}`,
                        ownerPhoneNumber: reg.competitor.metadata.owner.phone_number || '-',
                        title: reg.competitor.name || null,
                        paid: reg.payment.paid || null,
                        visible: true,
                        ts: Timestamp.fromMillis(reg.scheduled_at || 0),
                        class: reg.class || null,
                        id: reg.id || null,
                        start_order: reg.start_order ?? null,
                        shooting: reg.shooting || null,
                    }

                    if (Object.prototype.hasOwnProperty.call(reg, 'bestAftersearch') && Array.isArray(reg.bestAftersearch) && reg.bestAftersearch.length) {
                        const evalPath = `${snapshot.path}/registrations/${reg.id}/results/evaluation`;
                        const evalDoc = await FirebaseFirestore.getDocument({ reference: evalPath });

                        const provform = categories.find((v: any) => v.type === 'Provform') as any
                        if (!evalDoc.snapshot.data && ['Fält', 'Fjäll', 'Skog'].includes(provform?.value)) {
                            const addData = new Map<string, any>;
                            reg.bestAftersearch.filter((d: any) => d.type !== 'undefined').forEach((v: any) => {
                                addData.set((v as any).type, v);
                            });

                            const dataObj = Object.fromEntries(addData);

                            FirebaseFirestore.setDocument({ reference: evalPath, data: dataObj });
                        }
                    }

                    const regPath = `${snapshot.path}/registrations/${reg.id}`;
                    if (snapshots.findIndex((v) => v.data?.id === data.id) !== -1) {
                        await FirebaseFirestore.updateDocument({ reference: regPath, data });
                        return;
                    }

                    await FirebaseFirestore.setDocument({ reference: regPath, data });
                });
                return { success: true, reason: 'Updated Event info!' };

            }
            return { success: false, reason: 'Kennelbesök kunde inte sparas!' };
        },

        async hideFinishedRegs(
            id: string,
            registrations?: unknown[],
        ): Promise<void> {
            if (!registrations) {
                return;
            }

            if (!registrations.length) {
                return
            }

            const userStore = useUserStore().getUser()
            const { snapshots } = await FirebaseFirestore.getCollection({ reference: `${userStore.getCollectionPrefix}events/${id}/registrations` });
            const recivedIds = registrations.flatMap((v: any) => v.id);

            if (!snapshots) {
                return;
            }

            snapshots.forEach(async (snap) => {
                if (!snap.data) {
                    return;
                }

                const data = snap.data;
                if (recivedIds.includes(data.id)) {
                    return;
                }

                if (!data.visible) {
                    return;
                }

                await this.hideReg(id, snap.id, userStore);
            });
        },

        async hideReg(id: string, regId: string, userStore: any): Promise<void> {
            return FirebaseFirestore.updateDocument({
                reference: `${userStore.getCollectionPrefix}events/${id}/registrations/${regId}`,
                data: {
                    visible: false
                }
            });
        }
    }
});

export const useRegistrationsStore = defineStore({
    id: 'registrations',
    state: () => ({
        registrations: [] as TypeRegistration[],
        callbackId: null as null | string
    }),
    getters: {
        getRegistrationByID: (state) => (registrationID: string) => state.registrations.find((reg: TypeRegistration) => reg.id === registrationID)
    },
    actions: {
        async prepareForOffline(eventPath: string) {
            const { snapshots } = await FirebaseFirestore.getCollection({ reference: `${eventPath}/registrations/` });
            const userStore = useUserStore();

            snapshots.forEach(async (r: any) => {
                if (!r.data) {
                    return;
                }
                await useEvaluationsStore().prepareForOffline(`${eventPath}/registrations/${r.data.id}`);
                if (['kk', 'kk_dev', 'kk_test', 'kk_local'].includes(userStore.getEndpoint)) {
                    await useBreedStore().init();
                    await this.getKennelData({
                        ...r.data,
                        doc_id: r.id,
                        path: r.path,
                        metadata: r.metadata,
                    } as TypeRegistration);
                }
            })
        },

        async init(
            event: TypeEvent
        ): Promise<void> {
            if (!event) {
                this.destroy();
                return;
            }

            if (event.type !== 'kennelvisit') {
                if (this.callbackId) {
                    this.destroy();
                }

                // await bind(this, 'registrations', collection(db, `${event.path}/registrations/`));
                this.callbackId = await FirebaseFirestore.addCollectionSnapshotListener({
                    reference: `${event.path}/registrations/`
                }, (event, error) => {
                    if (!event) {
                        return;
                    }
                    const { snapshots } = event;

                    const registrationsArray = [] as TypeRegistration[];
                    snapshots.forEach((doc) => {
                        const pushDoc = doc.data as TypeRegistration;
                        pushDoc.doc_id = doc.id
                        pushDoc.path = doc.path
                        pushDoc.metadata = doc.metadata
                        registrationsArray.push(pushDoc);
                    });
                    this.registrations = registrationsArray;
                });
                return;
            }

            this.registrations = [];

            const { snapshots } = await FirebaseFirestore.getCollection({ reference: `${event.path}/registrations/` });
            snapshots.forEach((r) => {
                const data = r.data;
                this.registrations.push(
                    {
                        ...data as any,
                        id: r.id,
                        path: r.path,
                        metadata: r.metadata
                    } as TypeRegistration
                )
            })
        },

        async getKennelData(registration: TypeRegistration): Promise<TypeRegistration> {
            if (!registration.kennel) {
                return registration;
            }

            const userStore = useUserStore().getUser()
            if (!userStore.user) {
                return registration;
            }

            let kennelDocPath = registration.kennel.path;
            if (!kennelDocPath && registration.kennel) {
                kennelDocPath = `${userStore.getCollectionPrefix}kennels/${registration.kennel}`
            }

            const kennelSnap = await FirebaseFirestore.getDocument({ reference: kennelDocPath });
            const kennelRet = { ...registration };

            if (kennelSnap.snapshot.data) {
                const data = kennelSnap.snapshot.data;
                kennelRet.kennel = data;
            }

            if (kennelRet.kennel.breeds.length) {
                const promises = kennelRet.kennel.breeds.map((breed: any) => FirebaseFirestore.getDocument({ reference: breed.path ? breed.path : `${userStore.getCollectionPrefix}breeds/${breed}` }));
                try {
                    const filtered = await Promise.all(promises).then((ret) =>
                        ret.filter((r) => r.snapshot.data).map((f) => f.snapshot.data)
                    )
                    kennelRet.kennel.breeds = filtered;
                } catch (error) {
                    console.error(error);
                }
            }

            if (kennelRet.kennel.litters.length) {
                for (let i = 0; i < kennelRet.kennel.litters.length; i++) {
                    const litter = kennelRet.kennel.litters[i];

                    if (!litter?.breed?.path) {
                        continue;
                    }

                    const r = await FirebaseFirestore.getDocument({ reference: litter.breed.path ? litter.breed.path : `${userStore.getCollectionPrefix}breeds/${litter.breed}` });
                    if (r.snapshot.data) {
                        litter.breed = r.snapshot.data;
                    }

                }
            }

            return kennelRet
        },

        destroy(): void {
            if (this.callbackId) {
                FirebaseFirestore.removeSnapshotListener({ callbackId: this.callbackId })
                this.callbackId = null
            }
            this.registrations = [];
        },

        async removeRegistration(
            regId: string
        ): Promise<{ success: boolean, reason: string }> {

            if (!this.registrations.length) {
                return { success: false, reason: 'Kan inte hitta några registreringar!' };;
            }

            const registration = this.getRegistrationByID(regId);

            if (!registration) {
                return { success: false, reason: `Kan inte hitta registeringen med reg nr ${regId}!` };
            }

            await FirebaseFirestore.deleteDocument({ reference: registration.path });
            return { success: true, reason: 'Registrering bort tagen!' };
        },

        async addRegistration(
            event: TypeEvent,
            registrations: any[]
        ): Promise<{ success: boolean, reason: string }> {

            if (!this.registrations.length) {
                return { success: false, reason: 'Kunde inte hitta nuvarande besök!' };
            }

            const userStore = useUserStore().getUser();
            if (!userStore.user) {
                return { success: false, reason: 'Kunde inte hitta användare' };
            }
            const existingRegsIds = this.registrations.map((reg) => reg.kennel.id);
            const unsaveableRegs = [] as any;

            const date = new Date(0);
            date.setUTCSeconds(event.ts.seconds);

            const { id } = event;
            let snap = null;
            try {
                snap = await FirebaseFirestore.getDocument({ reference: `${userStore.getCollectionPrefix}events/${id}` });
            } catch (e) {
                console.error(e);
            }

            if (!snap) {
                return { success: false, reason: 'Kunde inte skapa nytt event' };
            }

            registrations.forEach(async (kennel) => {
                if (existingRegsIds.includes(kennel.id)) {
                    unsaveableRegs.push(kennel.name);
                    return;
                }

                const registrationData = {
                    kennel: kennel.id,
                    title: `Besök ${kennel.name}`,
                    ts: event.ts,
                }

                await FirebaseFirestore.setDocument({ reference: `${snap.snapshot.path}/registrations`, data: registrationData });
            });

            if (unsaveableRegs.length >= registrations.length) {
                return { success: false, reason: 'Alla besök finns redan inlaggda på den här dagen' };
            }

            if (unsaveableRegs.length) {
                const usRegsString = unsaveableRegs.join(', ');
                return { success: true, reason: `Alla besök sparade förutom ${usRegsString}` };
            }

            return { success: true, reason: 'Alla besök sparade' };
        },

        async addResult(
            regId: string,
            type: string,
            data: Array<unknown>,
            nameKey?: string,
            mode?: 'set' | 'update',
        ): Promise<void> {
            if (!this.registrations.length) {
                console.error('Registrations is empty!');
                return;
            }

            const registration = this.getRegistrationByID(regId);

            if (!registration) {
                return;
            }

            const docPath = `${registration.path}/results/${type}`;
            let docSnap = null as any;
            try {
                docSnap = await FirebaseFirestore.getDocument({ reference: docPath });
            } catch (e) {
                console.log('Could not find existing doc with that type');
            }

            const addData = new Map<string, any>;
            cloneDeep(data).filter((d: any) => d.type !== 'undefined').forEach((v) => {
                if (nameKey) {
                    addData.set((v as any)[nameKey], v);
                    return;
                }
                addData.set((v as any).type, v);
            });

            const dataObj = Object.fromEntries(addData);

            if (docSnap && docSnap.snapshot.data && mode !== 'set') {
                FirebaseFirestore.updateDocument({
                    reference: docPath,
                    data: dataObj
                });
            } else {
                FirebaseFirestore.setDocument({
                    reference: docPath,
                    data: dataObj
                })
            }
        },

        setPaidStatus(
            regId: string,
            value: boolean
        ): void {
            if (!this.registrations.length) {
                return;
            }

            const registration = this.getRegistrationByID(regId);

            if (!registration) {
                return;
            }

            FirebaseFirestore.updateDocument({
                reference: registration.path,
                data: { paid: value }
            });
        },

        async MarkDone(
            regId: string,
            type: string
        ): Promise<void> {

            if (!this.registrations.length) {
                return;
            }

            const registration = this.getRegistrationByID(regId);

            if (!registration) {
                return;
            }

            const docPath = `${registration.path}/results/${type}`;
            let docSnap = null as any;
            try {
                docSnap = await FirebaseFirestore.getDocument({ reference: docPath });
            } catch {
                console.log('Could not find existing doc with that type');
            }

            if (docSnap && docSnap.snapshot.data) {
                FirebaseFirestore.updateDocument({
                    reference: docPath,
                    data: { done: true }
                });
            } else {
                FirebaseFirestore.setDocument({
                    reference: docPath,
                    data: { done: true }
                });
            }
        },

        async MarkIncomplete(
            regId: string,
            type: string
        ): Promise<void> {
            if (!this.registrations.length) {
                return;
            }

            const registration = this.getRegistrationByID(regId);

            if (!registration) {
                return;
            }

            const docPath = `${registration.path}/results/${type}`;
            let docSnap = null as any;
            try {
                docSnap = await FirebaseFirestore.getDocument({ reference: docPath });
            } catch (e) {
                console.log('Could not find existing doc with that type');
            }

            if (docSnap && docSnap.snapshot.data) {
                FirebaseFirestore.updateDocument({
                    reference: docPath,
                    data: { done: false }
                })
            } else {
                FirebaseFirestore.setDocument({
                    reference: docPath,
                    data: { done: false }
                })
            }
        },

        async isEvaluationDone(
            regId: string,
            type: string
        ): Promise<boolean> {
            if (!this.registrations.length) {
                return false;
            }

            const registration = this.getRegistrationByID(regId);

            if (!registration) {
                return false;
            }

            let snapshot = null as any;
            try {
                snapshot = await FirebaseFirestore.getDocument({ reference: `${registration.path}/results/${type}` })
            } catch (e) {
                console.log('Could not get document done data');
                return false;
            }

            if (snapshot && snapshot.data) {
                const isDone = snapshot.data.done
                if (isDone !== undefined) {
                    return isDone;
                }
                return false;
            }
            return false;
        },

        MarkSent(
            regId: string,
            link: string
        ): void {
            if (!this.registrations.length) {
                return;
            }

            const registration = this.getRegistrationByID(regId);

            if (!registration) {
                return;
            }

            FirebaseFirestore.updateDocument({ reference: registration.path, data: { sentTo: link } });
        },

        async markFollowUp(
            regId: string,
            data: Array<Object>
        ): Promise<void> {
            const userStore = useUserStore().getUser();
            const registration = this.getRegistrationByID(regId);

            if (!registration) {
                return;
            }

            const kennelPath = `${userStore.getCollectionPrefix}kennels/${registration.kennel.id}`;

            const needFollowUp = data.filter((d: any) => d.type === 'need_follow_up')[0] as any;
            const needFollowUpWhen = data.filter((d: any) => d.type === 'need_follow_up_when')[0] as any;

            if (needFollowUp.value && needFollowUpWhen.value) {
                FirebaseFirestore.updateDocument({
                    reference: kennelPath,
                    data: {
                        'status': createDocumentReference(needFollowUp.value === 'yes' ? '5' : '3', `${userStore.getCollectionPrefix}statuses/${needFollowUp.value === 'yes' ? '5' : '3'}`),
                        'visit.revisit_latest': Timestamp.fromMillis(needFollowUpWhen.value)
                    },
                });
            }
        },

        async markVisitComplete(
            regId: string,
            mark: yesOrNoString
        ): Promise<void> {
            const registration = this.getRegistrationByID(regId);

            if (!registration) {
                return;
            }

            const userStore = useUserStore().getUser();

            const kennelMark = mark === 'yes' ? 'completed' : 'completed_failed';
            const { kennel } = registration;

            FirebaseFirestore.updateDocument({ reference: registration.path, data: { done: { mark } } });
            FirebaseFirestore.updateDocument({
                reference: `${userStore.getCollectionPrefix}kennels/${kennel.id}`,
                data: {
                    "visit.booked": null,
                    "visit.booked_by": null,
                    "visit.last_status": kennelMark,
                    "visit.last": kennel.visit.booked ? kennel.visit.booked : kennel.visit.last,
                    "visit.last_by": kennel.visit.booked_by ? kennel.visit.booked_by : kennel.visit.last_by
                }
            });
        },

        async setVisitStarted(
            regId: string, 
            dateString: number
        ): Promise<void> {
            const registration = this.getRegistrationByID(regId);

            if (!registration) {
                return;
            }

            setVisitStarted(registration.path, dateString);
        },

        async getHasResult(
            regId: string,
            type: string
        ): Promise<-1 | 0 | 1> {

            if (!this.registrations.length) {
                return -1;
            }

            const registration = this.getRegistrationByID(regId);

            if (!registration) {
                return -1;
            }

            let docSnap = null as any;
            try {
                docSnap = await FirebaseFirestore.getDocument({ reference: `${registration.path}/results/${type}` });
            } catch (e) {
                console.log('Could not find existing doc with that type');
            }

            if (docSnap && docSnap.snapshot.data) {
                const data = docSnap.snapshot.data;

                if (data.control_result) {
                    if (data.control_result.controll_status === 'not_ok') {
                        return 0;
                    }
                }

                const done = data.done ? 1 : 0;

                return done;
            }
            return -1;
        },

        async getResultData(
            regId: string,
            type: string
        ): Promise<unknown | null> {
            if (!this.registrations.length) {
                return null;
            }

            const registration = this.getRegistrationByID(regId);

            if (!registration) {
                return;
            }

            let docSnap = null as any
            try {
                docSnap = await FirebaseFirestore.getDocument({ reference: `${registration.path}/results/${type}` })
            } catch (e) {
                console.log('Could not find existing doc with that type');
            }

            if (docSnap && docSnap.snapshot.data) {
                return docSnap.snapshot.data;
            }
            return null;
        }
    }
});

export const useEvaluationsStore = defineStore({
    id: 'evaluations',
    state: () => ({
        evaluations: [] as any[],
        loaded: '' as string,
        initialized: false,
        callbackId: null as null | string
    }),
    getters: {
    },
    actions: {
        async prepareForOffline(registrationPath: string) {
            const { snapshots } = await FirebaseFirestore.getCollection({ reference: `${registrationPath}/results/` });
            return snapshots;
        },

        async evaluationStarted(registrationPath: string) {
            const evals = await FirebaseFirestore.getCollection({ reference: `${registrationPath}/results/` });
            return evals.snapshots.length > 0;
        },

        async init(
            registration: TypeRegistration
        ): Promise<void> {
            if (!registration) {
                this.destroy();
            }
            if (!registration?.path) {
                return;
            }
            if (this.callbackId) {
                this.destroy();
            }

            // await bind(this, 'evaluations', collection(db, `${registration.path}/results/`));
            this.callbackId = await FirebaseFirestore.addCollectionSnapshotListener(
                {
                    reference: `${registration.path}/results/`
                },
                (event, error) => {
                    if (!event) {
                        return;
                    }
                    const { snapshots } = event;

                    const evaluationArray = [] as any[];
                    snapshots.forEach((doc) => {
                        const pushDoc = doc.data as any;
                        pushDoc.doc_id = doc.id
                        pushDoc.path = doc.path
                        pushDoc.metadata = doc.metadata
                        evaluationArray.push(pushDoc);
                    });
                    this.evaluations = evaluationArray;
                    if (this.evaluations.length) {
                        this.initialized = true;
                        this.loaded = registration.id
                    }
                }
            );
        },

        async cleanChanges(type: string, registration: TypeRegistration, nonRemovable: Array<string>): Promise<void> {
            const folder = this.evaluations.find((folders) => folders.doc_id === type);

            if (!folder) {
                console.error(`No evaluation folder found with name: ${type}`);
                return;
            }
            const folderValues = Object.values(folder) as Array<EvaluationProperties>

            const removables = folderValues
                .filter((value: EvaluationProperties) => (value.type && value.type !== 'no_show') && !nonRemovable.includes(value.type))
                .flatMap((value: EvaluationProperties) => value.type);

            if (!removables.length) {
                console.info('[cleanChanges]: Nothing to clean.');
                return;
            }

            const deleteFieldPayload = {} as { [key: string]: any }

            removables.forEach((type) => {
                deleteFieldPayload[type] = null;
            });

            FirebaseFirestore.updateDocument({ reference: `${registration.path}/results/${type}`, data: deleteFieldPayload });
        },

        isNoShow(evalDocName: string, kennelvisitNoShow = false) {
            if (!this.evaluations.length) {
                return kennelvisitNoShow ? { no_show: true, hideRevisit: true } : {};
            }

            if (kennelvisitNoShow) {
                const intruduction = this.evaluations.find((v) => v.doc_id === 'introduction')

                if (!intruduction) {
                    return { no_show: true, hideRevisit: true };
                }

                const { visit_type, member_home } = intruduction

                if ((visit_type && visit_type.value === 'revisit_remotely') || !member_home) {
                    return { no_show: true, hideRevisit: false };
                }

                return {
                    no_show: ['no', 'member_has_no_time', 'member_dont_want_visitors', 'member_other'].includes(member_home.value),
                    hideRevisit: true
                }
            }

            const evaluation = this.evaluations.find((v) => v.id === evalDocName);
            if (!evaluation) {
                return false;
            }

            const { no_show } = evaluation;
            if (!no_show) {
                return false;
            }

            return Boolean(no_show.value);
        },

        destroy(): void {
            if (this.callbackId) {
                FirebaseFirestore.removeSnapshotListener({ callbackId: this.callbackId });
                this.callbackId = null;
            }
            this.evaluations = [];
        }
    }
});

export const useBreedStore = defineStore({
    id: 'breeds',
    state: () => ({
        breeds: [] as any[],
        initialized: false,
    }),
    actions: {
        async init() {
            try {
                const breedCollection = await FirebaseFirestore.getCollection({ reference: 'breeds' });
                this.breeds = breedCollection.snapshots.filter((v) => v.data).map((v) => v.data);
                this.initialized = true;
            } catch (error) {
                console.error('Could not get breeds from firestore', error);
            }
        },

        async getKennelBreeds(
            registration: TypeRegistration
        ): Promise<Array<{ id: number, name: string }>> {
            if (!registration.kennel) {
                return [];
            }

            const userStore = useUserStore().getUser()
            if (!userStore.user) {
                return [];
            }

            let kennelDocPath = registration.kennel.path;
            if (!kennelDocPath && registration.kennel) {
                kennelDocPath = `${userStore.getCollectionPrefix}kennels/${registration.kennel}`
            }

            const kennelSnap = await FirebaseFirestore.getDocument({ reference: kennelDocPath });
            const kennelRet = { ...registration };

            if (kennelSnap.snapshot.data) {
                const data = kennelSnap.snapshot.data;
                kennelRet.kennel = data;
            }

            if (kennelRet.kennel.breeds.length) {
                const promises = kennelRet.kennel.breeds.map((breed: any) => FirebaseFirestore.getDocument({ reference: breed.path ? breed.path : `${userStore.getCollectionPrefix}breeds/${breed}` }));
                try {
                    const filtered = await Promise.all(promises).then((ret) =>
                        ret.filter((r) => r.snapshot.data).map((f) => f.snapshot.data)
                    )
                    return filtered;
                } catch (error) {
                    console.error(error);
                }
            }

            return [];
        }
    }
})
