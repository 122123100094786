import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { style: {"white-space":"pre"} }
const _hoisted_3 = { style: {"border":"2px dotted black","padding":"15px"} }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { style: {"max-height":"310px","overflow-y":"auto"} }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_ctx.breeds.breeds.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.getCurrentBreedNames.join('\n')), 1),
        _cache[1] || (_cache[1] = _createTextVNode()),
        _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
        _cache[3] || (_cache[3] = _createTextVNode()),
        _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.breedSearch,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.breedSearch) = $event)),
            "append-icon": "mdi-magnify",
            variant: "underlined",
            label: "Sök hundras"
          }, null, 8, ["modelValue"]),
          (!_ctx.input.single)
            ? (_openBlock(), _createElementBlock("small", _hoisted_4, "Lägg till/ta bort hundraser nedanför."))
            : (_openBlock(), _createElementBlock("small", _hoisted_5, "Välj hundras nedanför")),
          _createElementVNode("ul", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredBreeds, (item) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.name,
                class: "text-no-wrap",
                onClick: ($event: any) => (_ctx.handleBreed($event, item))
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(["mdi v-icon v-theme--light v-icon--size-default", _ctx.getCurrentBreedNames.includes(item.name) ? _ctx.classes.selectedBreed : _ctx.classes.breed])
                }, null, 2),
                _createElementVNode("span", {
                  class: "text-truncate",
                  innerHTML: _ctx.highlight(item.name, _ctx.breedSearch)
                }, null, 8, _hoisted_8)
              ], 8, _hoisted_7))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}