<template>
        <v-btn
            :color="isNoShow ? 'error' : getColor(controlPassed)"
            :prepend-icon="getIcon(controlPassed)"
            @click="$emit('click-control')"
        >
            FÖRHANDSKONTROLL
        </v-btn>
        <v-btn
            v-show="!isDevided"
            :disabled="isNoShow"
            :color="getColor(hasEval.evaluation)"
            :prepend-icon="getIcon(hasEval.evaluation)" 
            @click="$emit('click-bed', 'evaluation')"
        >
            BEDÖMNING
        
        </v-btn>
        <v-btn
            v-show="isDevided"
            :disabled="isNoShow"
            :color="getColor(hasEval.devidedFirst)"
            :prepend-icon="getIcon(hasEval.devidedFirst)" 
            @click="$emit('click-bed', 'devidedFirst')"
        >
            Bedömning Eftersöksgrenar
        
        </v-btn>
        <v-btn
            v-show="isDevided"
            :disabled="isNoShow"
            :color="getColor(hasEval.devidedSecond)"
            :prepend-icon="getIcon(hasEval.devidedSecond)" 
            @click="$emit('click-bed', 'devidedSecond')"
        >
            Bedömning Jaktprov
        
        </v-btn>
    
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { isDevided } from '@/helpers/eventInfoHelper';

import {
    useRegistrationsStore
} from '@/stores/store'

export default defineComponent({
    props: {
        controlPassed: {
            type: Number,
            default: -1
        },
        currReg: {
            type: Object,
            default: () => {}
        },
        isNoShow: {
            type: Boolean,
            default: false,
        },
        currEvent: {
            type: Object,
            default: () => {}
        },    
    },
    emits: {
        'click-bed': null,
        'click-paid': null,
        'click-control': null
    },
    data() {
        return {
            registration: useRegistrationsStore(),
            hasEval: {
                evaluation: -1,
                devidedFirst: -1,
                devidedSecond: -1,
            }
        }
    },
    created() {
        Object.keys(this.hasEval).forEach((type) => {
            this.registration.getHasResult(this.currReg.id, type).then((done: number) => {
                if (Object.prototype.hasOwnProperty.call(this.hasEval, type)) {
                    // @ts-ignore
                    this.hasEval[type] = done;
                    return;
                }
                throw Error('[skf@Actions] Object dosent have eval type');
            }).catch(console.log);
        });
    },

    computed: {
        isDevided(): boolean {
            return isDevided(this.currEvent.categories)
        }
    },
    methods: {
        castValue(toCast: number|boolean): number {
            // eslint-disable-next-line no-nested-ternary
            return (typeof toCast === 'boolean') ? (toCast ? 1 : -1) : toCast
        },

        getIcon(val: number|boolean) {
            return this.castValue(val) === 1 ? 'mdi-check-circle-outline' : '';
        },

        getColor(val: number|boolean) {
            switch (this.castValue(val)) {
                case -1:
                    return 'grey'
                case 0:
                    return 'warning'
                case 1:
                    return 'success'
                default:
                    return 'grey'
            }
        }
    },
})
</script>
