import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FollowUpTitle = _resolveComponent("FollowUpTitle")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_FollowUpTitle, null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" Förbättringspunkter att följa upp ")
      ])),
      _: 1
    }),
    _createVNode(_component_v_row, { class: "my-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_table, null, {
              default: _withCtx(() => [
                _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", { class: "test-left" }, " Förbättringspunkter "),
                    _createElementVNode("th", { class: "test-left" }, " Svarat ")
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deficiencies, (deficiency) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: deficiency.key
                    }, [
                      _createElementVNode("td", null, _toDisplayString(deficiency.name), 1),
                      _createElementVNode("td", null, _toDisplayString(deficiency.value), 1)
                    ]))
                  }), 128))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}