import { FirebaseFirestore } from "@capacitor-firebase/firestore";

const setVisitStarted = async (path: string, dateString: number) => {
    try {

        await FirebaseFirestore.updateDocument({
            reference: path,
            data: { visit_started: dateString }
        });
    } catch (error) {
        console.error(error)
    }
}

export default setVisitStarted;
