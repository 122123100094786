import {
    useEvaluationsStore,
    useRegistrationsStore,
} from '@/stores/store';

import {
    checkScopeRequirements,
    checkMandatoryInputs,
    getCurrentJsonnetFile
} from '@/helpers/InputHelpers';

import {
    getEvent,
    getRegistration
} from '@/helpers/Getters';

import { IStorageService } from '@/services/storageService';
import setVisitStarted from './SetVisitStarted';
import { yesOrNoString } from '@/types';

const cleanChanges = async (
        storageServ: IStorageService,
        event: any,
        registration: any,
        type: any,
        savedValues:any, 
        changes: any,
    ) => {
    if (['bird_skf', 'hunting_swk_hunting'].includes(event.type)) {
        return [...savedValues, ...changes];
    }
    const file = await getCurrentJsonnetFile(storageServ, event.id, registration.id);
    if (!file) {
        return [...savedValues, ...changes];
    }

    let evals = null;
    if (file.type === 'working_test') {
        evals = file.evaluations;
    } else {
        evals = file[type];
    }

    const shownInputs = evals.filter((input: any) => checkScopeRequirements(input, savedValues, changes));
    const nonRemovableScopes = [] as Array<string>;

    shownInputs.forEach((input: any) => {
        const nonRemovable = input.inputs.flatMap((block: any) => block.scope);
        nonRemovableScopes.push(...nonRemovable);
    });

    return [...savedValues, ...changes].filter((block: any) => nonRemovableScopes.includes(block.type))
}

const saveToFirestore =
    async (
        storageServ: IStorageService,
        eventId: string,
        regId: string,
        type: string,
        no_show: number,
        savedValues: any,
        changes: any
    ) => {
    const event = getEvent(eventId);
    const registration = getRegistration(regId);
    const registrationsStore = useRegistrationsStore();

    if(!registration || !event) {
        console.error('Failed to get registration or event');
        return { valid: false, missing: []};
    }

    if (event.type !== 'kennelvisit') {
        try {
            await registrationsStore.addResult(
                registration.id.toString(), 
                type, 
                [
                    {
                        comment: '',
                        type: 'no_show',
                        value: no_show,
                        value_text: no_show ? 'Kom ej till start' : 'Kom till start',
                        scope: null,
                        dynamic: null,
                    }
                ]
            );
        } catch (error) {
            console.error('Failed setting no show values for kennelvist with', error);
        }
    }

    if (no_show === 1) {
        try {
            await registrationsStore.MarkDone(registration.id.toString(), type);
        } catch (error) {
            console.error('Failed marking done when no show with', error);
        }
        return { valid: false, missing: [] };
    }

    let valid = {} as { valid: boolean, missing: any[] };
    let cleanedChanges = [] as any[];

    try {
        valid = await checkMandatoryInputs(storageServ, eventId, regId, type, savedValues, changes);
    } catch (error) {
        console.error('Input validation failed with', error)
    }
    try {
        cleanedChanges = await cleanChanges(storageServ, event, registration, type, savedValues, changes);
    } catch (error) {
        console.error('Input cleaning failed with', error)
    }

    if (!cleanedChanges.length) {
        console.info('No changes to save')
        return valid;
    }

    try {
        await registrationsStore.addResult(
            regId, 
            type, 
            cleanedChanges,
            '',
            'set'
        );
    } catch (error) {
        console.error('Failed adding results with', error);
    }

    const regStore = useRegistrationsStore();
    if (valid.valid) {
        console.info(`${type}: Marked as Done`);
        await regStore.MarkDone(regId, type);
    } else {
        console.info(`${type}: Marked as Done`);
        await regStore.MarkIncomplete(regId, type);
    }

    if (event.type === 'kennelvisit') {
        const visitStarted = changes.filter((value: {type: string}) => value.type === 'visit_started');
        const timeStamp = visitStarted.reduce((acc:object, current: {value: number}) => current.value, {});
        
        if (timeStamp) {
            try {
                await registrationsStore.setVisitStarted(
                    regId, 
                    timeStamp
                );
            } catch (error) {
                console.error('Failed setting kennelvist as started with', error);
            }
        }

        const markVisit = changes.filter((c: {type: string}) => c.type === 'visit_completed') as Array<{value: yesOrNoString}>;
        if (markVisit.length) {
            try {
                await registrationsStore.markVisitComplete(
                    regId, 
                    markVisit[0].value
                );
            } catch (error) {
                console.error('Failed setting kennelvist as complete with', error);
            }
        }
    }

    return valid;
}

export default saveToFirestore;
