<template>
    <div
        v-if="evaluationStore.evaluations.length || initialLoad"
        class="d-flex flex-column"
        style="width: 100%;"
    >
        <div>
            <ChaseInputDaySelect
                v-model="chaseDate"
            />
            <v-divider />
            <ChaseInputLetGos
                :evaluations="evaluationStore.evaluations"
                :event="currEvent"
                @update-releases="updateReleasesFirebase"
                @save-releases="(validity) => saveReleasesFirebase(validity)"
            />
        </div>
        <ChaseInputSummation
            :evaluations="evaluationStore.evaluations"
            style="margin-top: auto;"
        />
    </div>
    <v-container 
        v-else 
        style="height: 400px;"
    >
        <v-row
            class="fill-height"
            align-content="center"
            justify="center"
        >
            <v-col
                class="text-subtitle-1 text-center"
                cols="12"
            >
                Laddar data...
            </v-col>
            <v-col cols="6">
                <v-progress-linear
                    v-model="initialLoadProgress"
                    color="green-darken-1"
                    rounded
                    height="6"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import ChaseInputDaySelect from '@/components/registration/chase/chasing/ChaseInputDaySelect.vue';
import ChaseInputLetGos from '@/components/registration/chase/chasing/ChaseInputLetGos.vue';
import ChaseInputSummation from '@/components/registration/chase/chasing/ChaseInputSummation.vue';

import {
    useEventStore,
    useRegistrationsStore,
    useEvaluationsStore 
} from '@/stores/store';

export default defineComponent({
    name: 'ChaseInput',
    components: {
        ChaseInputDaySelect,
        ChaseInputLetGos,
        ChaseInputSummation
    },
    data() {
        return {
            chaseDate: '',
            relases: null,
            eventStore: useEventStore(),
            registrationStore: useRegistrationsStore(),
            evaluationStore: useEvaluationsStore(),
            initialLoad: false,
            initialLoadProgress: 0,
        }
    },
    computed: {
        currEvent() {
            return this.eventStore.getEventByID(this.$route.params.eventId.toString());
        }
    },
    async created() {
        if (!this.eventStore.events.length) {
            await this.eventStore.init();
        }

        this.initialLoadProgress = 33;

        if (!this.registrationStore.registrations.length) {
            const event = this.eventStore.getEventByID(this.$route.params.eventId.toString());
            if (!event) {
                console.error('Could not find event');
                return;
            }
            await this.registrationStore.init(event)
        }

        if (!this.evaluationStore.evaluations.length) {
            const registration = this.registrationStore.getRegistrationByID(this.$route.params.regId.toString());
            if (!registration) {
                console.error('Could not find registration');
                return;
            }
            await this.evaluationStore.init(registration);
        }
        this.initialLoadProgress *= 2;
        
        const chase = this.evaluationStore.evaluations.find((v: any) => v.doc_id === 'chase') as any;
        if (chase && Object.prototype.hasOwnProperty.call(chase, 'chase_day')) {
            this.chaseDate = chase.chase_day?.value;
        }

        this.initialLoad = true;
        this.initialLoadProgress = 100;
    },
    methods: {
        setChaseDay() {
            this.registrationStore.addResult(
                this.$route.params.regId.toString(),
                'chase',
                [{
                    scope: 'chase_day',
                    value: this.chaseDate
                }],
                'scope',
                'update'
            )
        },

        updateReleasesFirebase(releases: Array<{id: number, scope: string, results: Object }>) {
            const values = [{scope: 'chase_day', value: this.chaseDate}, ...releases];
            this.registrationStore.addResult(
                this.$route.params.regId.toString(),
                'chase',
                values,
                'scope',
                'set'
            )
        },

        saveReleasesFirebase(validity: { valid: boolean }) {
            if (this.chaseDate === '') {
                return;
            }

            this.setChaseDay();

            // We check for false as null means unknown status/no error for the form
            if (!validity.valid) {
                return;
            }

            this.registrationStore.MarkDone(
                this.$route.params.regId.toString(), 
                'chase'
            ).then(() => {
                this.$router.push({
                    name: 'RegistrationView'
                });
                return true;
            }).catch(console.error);
        }
    }

});
</script>
