import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-8 name-div" }
const _hoisted_2 = { class: "stationName mb-2" }
const _hoisted_3 = { style: {"color":"black"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.stationName), 1),
      _createVNode(_component_v_divider)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workingTestInputs, (btnInput) => {
      return (_openBlock(), _createBlock(_component_v_btn, {
        key: btnInput.id,
        color: btnInput.id === _ctx.currentSelected ? btnInput.active_color : btnInput.color,
        class: "mb-2",
        style: _normalizeStyle((btnInput.id !== _ctx.currentSelected) ? 'opacity: 0.5' : ''),
        block: "",
        variant: "elevated",
        onClick: ($event: any) => (_ctx.toggleSelected(btnInput.id))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, _toDisplayString(btnInput.name), 1)
        ]),
        _: 2
      }, 1032, ["color", "style", "onClick"]))
    }), 128))
  ]))
}