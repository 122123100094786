import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("footer", null, [
    _createVNode(_component_v_card, {
      elevation: "0",
      rounded: "0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, {
          "no-gutters": "",
          class: "py-3 px-4 bg-grey-lighten-3"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("h3", null, "Summering", -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_divider),
        _createVNode(_component_v_row, {
          "no-gutters": "",
          class: "py-3 px-4 bg-grey-lighten-5"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" Provet avbröts kl. ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "d-flex justify-end font-weight-bold" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.lastReleaseRecoupled), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_divider),
        _createVNode(_component_v_row, {
          "no-gutters": "",
          class: "py-3 px-4 bg-grey-lighten-5"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Inkallningar med pejl ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "d-flex justify-end font-weight-bold" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.amoutUsedPejl), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_divider),
        _createVNode(_component_v_row, {
          "no-gutters": "",
          class: "py-3 px-4 bg-grey-lighten-5"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Hunden lös ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "d-flex justify-end font-weight-bold" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.howLongDogLose), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}