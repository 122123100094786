import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showControl,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showControl) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, { width: "400px" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { align: "center" }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" KONTROLLERA ")
              ])),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { align: "center" }, {
                  default: _withCtx(() => [
                    _cache[10] || (_cache[10] = _createElementVNode("h4", null, " Klubbnamn ", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.getClubs), 1),
                    _cache[11] || (_cache[11] = _createElementVNode("h4", { class: "mt-5" }, " Medlemsnummer ", -1)),
                    _cache[12] || (_cache[12] = _createElementVNode("span", null, "123456789", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getContactInfo, (value, key) => {
                      return (_openBlock(), _createElementBlock("div", { key: key }, [
                        _createElementVNode("h4", _hoisted_1, _toDisplayString(key), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value, (v, idx) => {
                          return (_openBlock(), _createElementBlock("span", { key: idx }, [
                            _createTextVNode(_toDisplayString(v) + " ", 1),
                            _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1))
                          ]))
                        }), 128))
                      ]))
                    }), 128)),
                    _cache[13] || (_cache[13] = _createElementVNode("h4", { class: "mt-5" }, " RASER ", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.registration.kennel.breeds, (breed, idx) => {
                      return (_openBlock(), _createElementBlock("span", { key: idx }, _toDisplayString(breed.name), 1))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                (!_ctx.requestControlReason)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 0,
                      class: "v-card-button-col"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          class: "mb-4",
                          style: {"margin":"0px"},
                          variant: "flat",
                          color: "green",
                          block: "",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.controll('ok')))
                        }, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createElementVNode("span", { class: "text-white" }, "ALLT ÄR OK", -1)
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_btn, {
                          class: "mb-4",
                          style: {"margin":"0px"},
                          variant: "flat",
                          color: "orange",
                          block: "",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.requestControlReason = true))
                        }, {
                          default: _withCtx(() => _cache[15] || (_cache[15] = [
                            _createElementVNode("span", { class: "text-white" }, "NÅGOT ÄR FELAKTIGT", -1)
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_btn, {
                          style: {"margin":"0px"},
                          block: "",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('cancle-control')))
                        }, {
                          default: _withCtx(() => _cache[16] || (_cache[16] = [
                            _createTextVNode(" AVBRYT ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_v_col, {
                      key: 1,
                      class: "v-card-button-col",
                      align: "center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          style: {"margin":"0px"},
                          variant: "flat",
                          color: "green",
                          block: "",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.controll('ok')))
                        }, {
                          default: _withCtx(() => _cache[17] || (_cache[17] = [
                            _createElementVNode("span", { class: "text-white" }, " ALLT ÄR OK ", -1)
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_v_divider, { style: {"margin-top":"20px","margin-bottom":"20px"} }),
                        _cache[20] || (_cache[20] = _createElementVNode("h4", null, "Vad är fel?", -1)),
                        _createVNode(_component_v_textarea, {
                          modelValue: _ctx.controlBadReason,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.controlBadReason) = $event))
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_v_btn, {
                          class: "mb-4",
                          style: {"margin":"0px"},
                          variant: "flat",
                          color: "blue",
                          disabled: _ctx.controlBadReason === '',
                          block: "",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.controll('bad')))
                        }, {
                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                            _createElementVNode("span", { class: "text-white" }, " SPARA ", -1)
                          ])),
                          _: 1
                        }, 8, ["disabled"]),
                        _createVNode(_component_v_btn, {
                          style: {"margin":"0px"},
                          block: "",
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('cancle-control')))
                        }, {
                          default: _withCtx(() => _cache[19] || (_cache[19] = [
                            _createTextVNode(" AVBRYT ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}