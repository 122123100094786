<template>
    <div id="count-up-timer">
        <p>
            <b>{{ displayTime }}</b>
        </p>
    </div>
</template>

<script>
export default {
    props: {
        startTime: {
            type: Number, // seconds
            default: 0,
        },
        shouldRun: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
    },
    watch: {
        shouldRun(newVal) {
            if (newVal === true) {
                this.startTimer();
            } else {
                this.stopTimer();
            }
        },
    },
    methods: {
        startTimer() {
            this.countInterval = setInterval(() => {
                this.seconds += 1;
                if (this.seconds === 60) {
                    this.seconds = 0;
                    this.minutes += 1;
                }
                if (this.minutes === 60) {
                    this.minutes = 0;
                    this.hours += 1;
                }
            }, 1000);
        },
        stopTimer() {
            clearInterval(this.countInterval);
        },
        handleStartTime() {
            if (this.startTime) {
                this.hours = Math.floor(this.startTime / 3600);
                this.minutes = Math.floor((this.startTime % 3600) / 60);
                this.seconds = this.startTime % 60;
            }
        },
    },
    computed: {
        displayTime() {
            const displays = {
                hours: this.hours < 10 ? '0' + this.hours : this.hours,
                minutes: this.minutes < 10 ? '0' + this.minutes : this.minutes,
                seconds: this.seconds < 10 ? '0' + this.seconds : this.seconds,
            }
            return `${displays.hours}:${displays.minutes}:${displays.seconds}`;
        }
    },
    created() {
        this.handleStartTime();
    },
    beforeDestroy() {
        this.stopTimer();
    },
};
</script>

<style>
#count-up-timer {
    position: absolute;
    right: 1rem;
    top: 1.5rem;
}
</style>