<template>
    <v-radio
        :model-value="true"
        color="info"
        :disabled="true"
    >
        <template #label>
            {{ selectedName }}
        </template>
    </v-radio>
</template>

<script lang="ts">
import { useEventStore, useRegistrationsStore, TypeEvaluation } from '@/stores/store';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ERecived',
    props: {
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {}
        },
        input: {
            type: Object,
            default: () => {}
        },
        values: {
            type: Array<TypeEvaluation>,
            default: () => [] 
        },
    },
    emits: ['setScope'],
    data() {
        return {
            registrations: useRegistrationsStore(),
            events: useEventStore(),
            selectedName: ''
        }
    },
    mounted() {
        switch (this.input.scope) {
            case 'terrang':
            case 'test_type': {
                const event = this.events.getEventByID(this.$route.params.eventId.toString());
                if (!event) {
                    return;
                }

                const eventType = event.categories.find((v) => v.type === 'Provform')?.value;
                if (!eventType) {
                    return;
                }

                const eventValue = this.input.value.find((v: any) => v.name === eventType);
                if (!eventValue) {
                    return;
                }

                const savedInput = this.values.find((value: TypeEvaluation) => value.type === this.input.scope);
                if (savedInput && savedInput.value === eventValue.value) {
                    this.selectedName = eventValue.name;
                    return;
                }

                this.$emit('setScope', this.input.scope, eventValue.value);
                this.selectedName = eventValue.name;
                break;
            }
            case 'provslag': {
                const event = this.events.getEventByID(this.$route.params.eventId.toString());
                if (!event) {
                    return;
                }

                const eventType = event.categories.find((v) => v.type === 'Provslag')?.value;
                if (!eventType) {
                    return;
                }

                const eventValue = this.input.value.find((v: any) => v.value === eventType?.toLowerCase());
                if (!eventValue) {
                    return;
                }

                const savedInput = this.values.find((value: TypeEvaluation) => value.type === this.input.scope);
                if (savedInput && savedInput.value === eventValue.value) {
                    this.selectedName = eventValue.name;
                    return;
                }

                this.$emit('setScope', this.input.scope, eventValue.value);
                this.selectedName = eventValue.name;
                break;
            }
            case 'provtyp': {
                const event = this.events.getEventByID(this.$route.params.eventId.toString());
                if (!event) {
                    return;
                }

                const eventType = event.categories.find((v) => v.type === 'Provtyp')?.value;
                if (!eventType) {
                    return;
                }

                const eventValue = this.input.value.find((v: any) => v.name === eventType);
                if (!eventValue) {
                    return;
                }

                const savedInput = this.values.find((value: TypeEvaluation) => value.type === this.input.scope);
                if (savedInput && savedInput.value === eventValue.value) {
                    this.selectedName = eventValue.name;
                    return;
                }

                this.$emit('setScope', this.input.scope, eventValue.value);
                this.selectedName = eventValue.name;
                break;
            }
            case 'class': {
                const currReg = this.registrations.getRegistrationByID(this.$route.params.regId as string);
                if (!currReg) {
                    return;
                }
                const regValue = this.input.value.find((v: any) => v.name === currReg.class);
                
                if (!regValue) {
                    return;
                }
                const savedInput = this.values.find((value: TypeEvaluation) => value.type === this.input.scope);
                if (savedInput && savedInput.value === regValue.value) {
                    this.selectedName = regValue.name;
                    return;
                }

                this.$emit('setScope', this.input.scope, regValue.value);
                this.selectedName = regValue.name;
                break;
            }
            default:
                break;
        }
    }
})

</script>
