import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[1] || (_cache[1] = _createElementVNode("input", {
      id: "timezone",
      type: "hidden",
      name: "timezone",
      value: "+01:00"
    }, null, -1)),
    _createVNode(_component_v_text_field, {
      modelValue: _ctx.selected[_ctx.input.scope],
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected[_ctx.input.scope]) = $event)),
      type: "datetime-local",
      "hide-details": _ctx.hideDetails,
      variant: "outlined"
    }, null, 8, ["modelValue", "hide-details"])
  ]))
}