import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "compact-wrapper" }
const _hoisted_2 = { class: "row row-wrapper" }
const _hoisted_3 = { class: "row input-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.input.name), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_v_text_field, {
          modelValue: _ctx.meters,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.meters) = $event)),
          modelModifiers: { number: true },
          class: "input",
          type: "number",
          density: "compact",
          "hide-details": "",
          min: "0",
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateAndSave($event, 'meters')))
        }, null, 8, ["modelValue"]),
        _cache[4] || (_cache[4] = _createElementVNode("span", null, " m ", -1)),
        _createVNode(_component_v_text_field, {
          modelValue: _ctx.minutes,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.minutes) = $event)),
          modelModifiers: { number: true },
          class: "input",
          type: "number",
          density: "compact",
          "hide-details": "",
          min: "0",
          onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validateAndSave($event, 'minutes')))
        }, null, 8, ["modelValue"]),
        _cache[5] || (_cache[5] = _createElementVNode("span", null, " min ", -1))
      ])
    ])
  ]))
}