<template>
    <div
        class="mb-8"
    >
        <h4>{{ title }}</h4>

        <div
            v-if="evaluationDone"
            style="color: green"
        >
            Klarmarkerat
        </div>

        <div style="display: flex; justify-content: space-between">
            <span style="font-weight: bold"> Rubrik </span>
            <span style="font-weight: bold"> Värde </span>
        </div>

        <div
            v-for="(result, idx) in results"
            :key="idx"
            class="evaluation-results"
        >
            <div 
                v-if="!textareaFields.includes(result.name)"
            >
                <div
                    v-if="result.name !== 'no_show'"
                    style="display: flex; justify-content: space-between; flex-wrap: wrap;"
                >
                    <div>
                        <div style="white-space: nowrap; padding-right: 4em;">
                            {{ result.name }}
                        </div>
                    </div>
                    <div>
                        <div style="text-align: right;">
                            {{ result.value }}
                        </div>
                    </div>
                </div>
                <div 
                    v-if="result.comment"
                    style="display: flex; justify-content: space-between; flex-wrap: wrap;"
                >
                    <div>
                        <div style="white-space: nowrap; padding-right: 4em;">
                            <small><b>- Kommentar</b></small><br>
                        </div>
                    </div>
                    <div>
                        <div style="text-align: left;">
                            {{ result.comment }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div
                    style="display: flex; flex-direction: column;"
                >
                    <div>
                        <div>
                            {{ result.name }}
                        </div>
                    </div>
                    <div style="text-align: left;">
                        <div
                            v-html="result.value"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PreviewResultsContent',
    props: {
        results: {
            type: Array,
            required: true
        },
        textareaFields: {
            type: Array,
            required: true
        },
        evaluationDone: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    }
}
</script>