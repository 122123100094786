<template>
    <v-text-field
        v-model="internalValue"
        class="pl-6 pt-6 pr-5"
        type="date"
        :error="internalValue === ''"
        :error-messages="internalValue === '' ? 'Provdag måste väljas!' : undefined"
        variant="outlined"
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ChaseInputDaySelect',
    props: {
        modelValue: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            internalValue: ''
        };
    },
    created() {
        this.internalValue = this.modelValue;
    },
    watch: {
        internalValue() {
            this.$emit('update:modelValue', this.internalValue)
        }
    },
    emits: ['update:modelValue']
})
</script>
