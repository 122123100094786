import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_table = _resolveComponent("v-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[7] || (_cache[7] = _createElementVNode("h3", null, "Summering", -1)),
    _createVNode(_component_v_table, null, {
      default: _withCtx(() => [
        _cache[6] || (_cache[6] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", { class: "text-left" }, " Namn "),
            _createElementVNode("th", { class: "text-left" }, " Summa ")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          _createElementVNode("tr", null, [
            _cache[0] || (_cache[0] = _createElementVNode("td", null, "Släpptid (min)", -1)),
            _createElementVNode("td", null, _toDisplayString(_ctx.calculatedValues.run_time), 1)
          ]),
          _createElementVNode("tr", null, [
            _cache[1] || (_cache[1] = _createElementVNode("td", null, "Egna stånd", -1)),
            _createElementVNode("td", null, _toDisplayString(_ctx.calculatedValues.run_own_stond), 1)
          ]),
          _createElementVNode("tr", null, [
            _cache[2] || (_cache[2] = _createElementVNode("td", null, "Egna stötar", -1)),
            _createElementVNode("td", null, _toDisplayString(_ctx.calculatedValues.run_own_stotar), 1)
          ]),
          _createElementVNode("tr", null, [
            _cache[3] || (_cache[3] = _createElementVNode("td", null, "Tomstånd", -1)),
            _createElementVNode("td", null, _toDisplayString(_ctx.calculatedValues.run_empty_stond), 1)
          ]),
          _createElementVNode("tr", null, [
            _cache[4] || (_cache[4] = _createElementVNode("td", null, "Partners stånd", -1)),
            _createElementVNode("td", null, _toDisplayString(_ctx.calculatedValues.run_partner_stond), 1)
          ]),
          _createElementVNode("tr", null, [
            _cache[5] || (_cache[5] = _createElementVNode("td", null, "Partners stötar", -1)),
            _createElementVNode("td", null, _toDisplayString(_ctx.calculatedValues.run_partner_stotar), 1)
          ])
        ])
      ]),
      _: 1
    })
  ]))
}