<template>
    <v-radio-group
        v-if="foundChase && renderableEntries"
        v-model="selected[input.scope]"
        style="margin-top: 0px !important;"
        :hide-details="hideDetails"
    >
        <v-radio
            v-for="(option) in renderableEntries"
            :key="option.id"
            :label="`Släpp ${option.id + 1} (${translation[option.results.gamekind]})`"
            :value="option.id"
            outlined
            color="info"
        />
    </v-radio-group>
    <evaluation-label
        v-else
        :input="{
            value: `Kunde inte hitta några slutförda drev för ${getTitleFromScope(input.scope)}`,
            class: 'warning'
        }"
    />
</template>

<script lang="ts">
import { useEvaluationsStore } from '@/stores/store';
import { defineComponent } from 'vue';

import EvaluationLabel from './EvaluationLabel.vue';

import skk from '../shared/translations/skk.json'

export default defineComponent({
    name: 'e-chase_select',
    components: {
        EvaluationLabel
    },
    props: {
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {}
        },
        input: {
            type: Object,
            default: () => {}
        },
        values: { 
            type: Array, 
            default: () => [] 
        },
    },
    data() {
        return {
            evaluationStore: useEvaluationsStore(),
            foundChase: true,
            entries: {
                paw: {
                    fox: [],
                    hare: [],
                    kanin: []
                },
                hoof: {
                    roe_deer: [],
                    deer: [],
                    wild_boar: []
                }
            } as { [key: string]: { [key: string]: any } },
        }
    },
    computed: {
        renderableEntries() {
            const vs = Object.values(this.entries[this.input.entry_type]).filter((v: any) => !!v.length);
            if (vs.length === 0) {
                return null;
            }
            return vs.flat();
        },

        translation() {
            return skk.translations as { [key: string]: string }
        }
    },
    mounted() {
        const chase = this.evaluationStore.evaluations.find((v: any) => v.doc_id === 'chase');

        if (!chase) {
            this.foundChase = false;
            return;
        }

        const chaseCopy = { ...chase };
        delete chaseCopy.done;
        delete chaseCopy.doc_id;
        delete chaseCopy.metadata;
        delete chaseCopy.path;
        delete chaseCopy.chase_day;

        const chaseValues = Object.values(chaseCopy);

        const entriesToCheck = Object.keys({ ...this.entries[this.input.entry_type] });

        entriesToCheck.forEach((entryName: string) => {
            const foundEntry = chaseValues.filter((cV: any) => cV.results.gamekind === entryName) as any;

            if (foundEntry.length) {
                foundEntry.forEach((entry: any) => {
                    if (entry.results.release_uptake.length > 1 && (entry.results.release_slag && entry.results.release_slag !== '')) {
                        this.entries[this.input.entry_type][entryName].push(entry);
                    }
                });
            }
        })
    },
    methods: {
        getTitleFromScope(scope: string) {
            switch (scope) {
                case 'best_chase_paw': return 'tassdjur'
                case 'best_chase_hoof': return 'klövdjur'
                default: return 'unknown';
            }
        }
    }
})

</script>
