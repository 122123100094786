import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { validateToken } from '@/api/Api';
import { useUserStore } from '@/stores/store';

import AuthView from '@/views/AuthView.vue';
import EventList from '@/views/EventList.vue';
import RegistrationList from '@/views/RegistrationList.vue';
import RegistrationView from '@/views/RegistrationView.vue';
import EvaluationView from '@/views/EvaluationView.vue';
import BloodtrackingView from '@/views/BloodtrackingView.vue';
import ChaseInput from '@/views/ChaseInput.vue';
import KennelvisitFollowUpView from '@/views/KennelvisitFollowUp.vue';

const authRoutes: Array<RouteRecordRaw> = [
    {
        path: '/event/',
        name: 'EventList',
        component: EventList,
    },
    {
        path: '/event/:eventId/registration/',
        name: 'RegistrationList',
        component: RegistrationList,
    },
    {
        path: '/event/:eventId/registration/:regId',
        name: 'RegistrationView',

        component: RegistrationView,
    },
    {
        path: '/event/:eventId/registration/:regId/evaluation/:type',
        name: 'evaluation',

        component: EvaluationView,
    },
    {
        path: '/event/:eventId/registration/:regId/bloodtracking',
        name: 'bloodtracking',

        component: BloodtrackingView,
    },
    {
        path: 'event/:eventId/registration/:regId/chase',
        name: 'chase',

        component: ChaseInput
    },
    {
        path: '/event/:eventId/registration/:regId/followup',
        name: 'kennelvisit_follow_up',

        component: KennelvisitFollowUpView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'auth',
            component: AuthView,
        },
        {
            path: '/',
            meta: { authRequired: true },
            children: authRoutes
        }
    ]
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.authRequired)) {
        if (!localStorage.getItem('jwtoken')) {
            next({
                name: 'auth'
            });
        } else {
            const userStore = useUserStore();
            if (userStore.getEndpoint && !['kk', 'kk_dev', 'kk_test', 'kk_local'].includes(userStore.getEndpoint)) {
                try {
                    await validateToken();
                } catch (error: any) {
                    // NOTE: Offline mode will not throw an error
                    if (error) {
                        if (error.status === 401 && useUserStore().getUser()) {
                            alert('Din session har gått ut. Logga in igen.');
                            useUserStore().destroy();
                        } else {
                            alert('Serverproblem, var vänlig försök igen');
                        }
                    }
                }
            }
            next();
        }
    } else if (to.fullPath === '/') {
        if (localStorage.getItem('jwtoken')) {
            next({
                name: 'EventList'
            })
        } else {
            next();
        }
    } else {
        next();
    }
    
})

export default router
