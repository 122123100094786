<template>
    <div class="padding max-height max-width">
        <div v-if="visitDeficiencies.length">
            <DeficiencyFormatter 
                :deficiencies="visitDeficiencies"
            />

            <v-textarea
                v-model="inputValues['compensation_plan']"
                :hide-details="true"
                outlined
                placeholder="Åtgärdsplan"
            />
        </div>

        <div>
            <div style="margin-top: 20px;">
                <FollowUpTitle>
                    Krävs Uppföljning?
                </FollowUpTitle>
                <v-radio-group
                    v-model="inputValues['need_follow_up']"
                    style="margin-top: 15px !important; margin-bottom: 10px !important;"
                    :hide-details="true"
                >
                    <v-radio
                        v-for="(option, value) in yesAndNoOptions"
                        :key="`need_follow_up_${value}`"
                        :value="option.value"
                        color="info"
                    >
                        <template #label>
                            <span>
                                {{ option.name }}
                            </span>
                        </template>
                    </v-radio>
                </v-radio-group>

                <div>
                    <v-textarea
                        v-model="inputValues['need_follow_up_answered_no_comment']"
                        :hide-details="true"
                        outlined
                        placeholder="Kommentar"
                    />
                </div>
            </div>

            <div
                v-if="addReasonFollowUp"
                style="margin-top: 20px;"
            >
                <FollowUpTitle>
                    Vill du lägga till anledning till Uppföljning?
                </FollowUpTitle>
                <v-radio-group
                    v-model="inputValues['add_reason_for_follow_up']"
                    style="margin-top: 15px !important; margin-bottom: 10px !important;"
                    :hide-details="true"
                >
                    <v-radio
                        v-for="(option, value) in yesAndNoOptions"
                        :key="`add_reason_for_follow_up_${value}`"
                        :value="option.value"
                        color="info"
                    >
                        <template #label>
                            <span>
                                {{ option.name }}
                            </span>
                        </template>
                    </v-radio>
                </v-radio-group>

                <div
                    v-if="reasonFollowUp"
                >
                    <v-textarea
                        v-model="inputValues['reason_for_follow_up']"
                        :hide-details="true"
                        outlined
                        placeholder="Anledning till uppföljning"
                    />
                </div>
            </div>
        </div>

        <div style="margin-top: 20px;">
            <FollowUpTitle>
                Vid frågor, vänligen kontakta SKKs Kansli på e-postadressen
            </FollowUpTitle>

            <h2 style="text-align: center; margin-top: 25px;">
                <EvaluationEmailLink
                    :input="{
                        email: 'kennelkonsulent@skk.se',
                    }"
                />
            </h2>
        </div>
        <v-alert
            v-model="showWarning"
            color="warning"
            width="460px"
            closable
            icon="mdi-alert"
        >
            {{ warningMessage }}
        </v-alert>

        <v-btn
            style="margin-top: 50px;"
            block
            color="success"
            @click="saveFollowUp"
        >
            Spara
        </v-btn>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import skk from '@/components/shared/translations/skk.json'

import DeficiencyFormatter from '@/components/registration/kennelvisit/follow_up/DeficiencyFormatter.vue';

import { useEvaluationsStore, useEventStore, useRegistrationsStore } from '@/stores/store';
import FollowUpTitle from '@/components/registration/kennelvisit/follow_up/FollowUpTitle.vue';
import EvaluationEmailLink from '@/components/input-types/EvaluationEmailLink.vue';
import { IStorageService } from '@/services/storageService';
import { getCurrentJsonnetFile } from '@/helpers/InputHelpers';

export default defineComponent({
    name: 'KennelvisitFollowUp',
    components: {
        DeficiencyFormatter,
        FollowUpTitle,
        EvaluationEmailLink
    },
    data() {
        return {
            deficiencies: [] as Array<{scope: string, value: string}>,
            visitDeficiencies: [] as any,
            eventStore: useEventStore(),
            registrationStore: useRegistrationsStore(),
            evaluationStore: useEvaluationsStore(),
            registrationId: '',
            inputValues: {} as any,
            kennelvisitJson: {} as any,
            yesAndNoOptions: [
                { name: 'Ja', value: 'yes' }, 
                { name: 'Nej', value: 'no' }
            ],
            showWarning: false,
            warningMessage: '',
        }
    },
    computed: {
        translations() {
            return skk.translations;
        },

        addReasonFollowUp() {
            return this.inputValues.need_follow_up === 'yes'
        },

        reasonFollowUp() {
            return this.inputValues.add_reason_for_follow_up === 'yes'
        }
    },
    async mounted() {
        const storageServ = (this as any).$storageServ as IStorageService;
        const kennelvisit = await getCurrentJsonnetFile(storageServ, this.$route.params.eventId.toString(), this.$route.params.regId.toString());

        if (!kennelvisit) {
            return;
        }

        this.kennelvisitJson = kennelvisit;

        this.registrationId = this.$route.params.regId.toString();
        this.deficiencies = this.kennelvisitJson.deficiencies;

        if (!this.eventStore.events.length) {
            await this.eventStore.init();
        }
        if (!this.registrationStore.registrations.length) {
            const event = this.eventStore.getEventByID(this.$route.params.eventId.toString());
            if (!event) {
                return;
            }
            await this.registrationStore.init(event)
        }
        if (!this.evaluationStore.evaluations.length) {
            const registration = this.registrationStore.getRegistrationByID(this.$route.params.regId as string);
            if (!registration) {
                return;
            }
            await this.evaluationStore.init(registration);
        }

        let allEvals = {} as { [key: string]: { 
            comment: string
            dynamic: any
            scope: any
            type: string
            value: string
            value_text: string
        }};

        this.evaluationStore.evaluations.forEach((v) => {
            delete v.id;
            delete v.done;
            delete v.metadata;
            delete v.path;

            allEvals = { ...allEvals, ...v };
        })

        this.deficiencies.forEach((v: {scope: string, value: string}) => {
            if (!allEvals[v.scope]) {
                return;
            }

            if (allEvals[v.scope].value === v.value) {
                const visitDeficency = {
                    key: allEvals[v.scope].type,
                    // @ts-ignore
                    name: this.translations[allEvals[v.scope].type] || allEvals[v.scope].type,
                    // @ts-ignore
                    value: this.translations[allEvals[v.scope].value_text],
                }
                this.visitDeficiencies.push(visitDeficency);
            }
        });

        const saveableDeficiencies = [
            {
                comment: '',
                dynamic: null,
                scope: null,
                type: 'deficiencies',
                value: this.visitDeficiencies,
                value_test: null,
            }
        ]

        this.registrationStore.addResult(
            this.registrationId,
            'follow_up',
            saveableDeficiencies
        )

        this.inputValues.compensation_plan = allEvals.compensation_plan?.value || null;
        this.inputValues.add_reason_for_follow_up = allEvals.add_reason_for_follow_up?.value || null;
        this.inputValues.need_follow_up = allEvals.need_follow_up?.value || null;
        this.inputValues.need_follow_up_answered_no_comment = allEvals.need_follow_up_answered_no_comment?.value || null;
        this.inputValues.reason_for_follow_up = allEvals.reason_for_follow_up?.value || null;
            
        const permissionLans = allEvals.permission_from_lansstyrelsen?.value;
        if (permissionLans === 'no' || permissionLans === 'has_applied') {
            this.inputValues.need_follow_up = 'yes';
            this.inputValues.add_reason_for_follow_up = this.inputValues.add_reason_for_follow_up || 'yes';
            this.inputValues.reason_for_follow_up = this.inputValues.reason_for_follow_up || 'Uppfödaren saknar tillstånd hos Länsstyrelsen';
        }
    },
    methods: {
        triggerWarning(message: string) {
            this.warningMessage = message;
            this.showWarning = true;
        },

        async saveFollowUp() {
            const savableArray = Object.entries(this.inputValues).map(([key, value]) => ({   
                comment: '',
                dynamic: null,
                scope: null,
                type: key,
                value,
                value_text: value, 
            })).filter((saveArray) => {
                switch(saveArray.type) {
                    case 'add_reason_for_follow_up':
                        return this.addReasonFollowUp;
                    case 'reason_for_follow_up':
                        return this.reasonFollowUp && this.addReasonFollowUp;
                    default:
                        return true;
                }
            });

            await this.registrationStore.addResult(
                this.registrationId,
                'follow_up',
                savableArray
            );

            const inputKeys = Object.keys(Object.fromEntries(Object.entries(this.inputValues).filter(([_, value]) => value && value !== '' )));
            
            const { need_follow_up } = this.inputValues;
            if (need_follow_up === 'yes' && !inputKeys.includes('need_follow_up_answered_no_comment')) {
                this.triggerWarning('Saknar kommentar för "Krävs uppföljning"');

                await this.registrationStore.MarkIncomplete(
                    this.$route.params.regId as string,
                    'follow_up'
                );
                return;
            }

            if (
                (this.inputValues.need_follow_up && this.inputValues.need_follow_up === 'yes') &&
                (!this.inputValues.add_reason_for_follow_up && this.inputValues.add_reason_for_follow_up === '')
            ) {
                this.triggerWarning('Saknar "Vill du lägga till anledning till Uppföljning?"');

                await this.registrationStore.MarkIncomplete(
                    this.$route.params.regId as string, 
                    'follow_up'
                );
                return;
            }

            if (
                (this.inputValues.need_follow_up && this.inputValues.need_follow_up === 'yes') &&
                (this.inputValues.add_reason_for_follow_up && this.inputValues.add_reason_for_follow_up === 'yes') &&
                (!this.inputValues.reason_for_follow_up || this.inputValues.reason_for_follow_up === '')
            ) {
                this.triggerWarning('Saknar anledning till uppföljning');

                await this.registrationStore.MarkIncomplete(
                    this.$route.params.regId as string, 
                    'follow_up'
                );
                return;
            }

            if (this.visitDeficiencies.length && (!this.inputValues.compensation_plan || this.inputValues.compensation_plan === '')) {
                this.triggerWarning('Saknar åtgärdsplan');

                await this.registrationStore.MarkIncomplete(
                    this.$route.params.regId as string, 
                    'follow_up'
                );
                return;
            }

            await this.registrationStore.MarkDone(
                this.$route.params.regId as string, 
                'follow_up'
            );

            this.$router.push({ name: 'RegistrationView' });
        }
    }
})

</script>

<style lang="less" scoped>

.v-alert {
  position: fixed;
  left: 50%;
  top: 100px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
.max-width {
    width: 100%;
}

.max-height {
    height: 100%;
}

.padding {
    padding: 20px;
}

</style>
