<template>
    <kennelvisit-view
        v-if="currentEvent.type === 'kennelvisit'"
        :curr-reg="currentRegistration"
    />
    <view-component
        v-else
        :reg="currentRegistration"
        :event="currentEvent"
    />
</template>

<script lang="ts">
import {
    TypeEvent, 
    useEventStore, 
    TypeRegistration, 
    useRegistrationsStore 
} from '@/stores/store';

import { defineComponent, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router';

import LoadingComponent from '@/components/LoadingComponent.vue';

// Views
import ViewComponent from '@/components/registration/ViewComponent.vue';

export default defineComponent({
    name: 'RegistrationInfo',
    components: {
        ViewComponent,
        KennelvisitView: defineAsyncComponent({
            loader: () => import('@/components/registration/kennelvisit/View.vue'),
            loadingComponent: LoadingComponent,
        })
    },
    props: {
        currentEvent: { type: Object, default: () => {} },
        currentRegistration: { type: Object, default: () => {} }
    },
})
</script>

<style lang="less">
.category-span {
    color: rgb(159,159,159);
    text-transform: uppercase;
}

.info-col {
    h4:not(:last-child) {
        margin-bottom: 10px;
    }
}
</style>
