import {
    getEvent,
    getRegistration
} from '@/helpers/Getters';

import { 
    TypeEvaluation, 
    useRegistrationsStore,
} from '@/stores/store';

import kennelvisit from '@/assets/jsonnet/kennelvisit.jsonnet';

import { IStorageService } from '@/services/storageService';

const checkRequirementsForMandatory = (block: any, savedValues: any, changes: any) => {
    if (!checkScopeRequirements(block, savedValues, changes)) {
        return false;
    }

    if (!block.requires) {
        return true;
    }

    const req = getRequiredValues(block, savedValues, changes);

    // @ts-ignore
    const reqFind = req?.find((r: any) => !r.requiredValue.includes(r.input.value));
    if (reqFind) {
        return false;
    }

    return true;
}

const getRequiredValues = (input: any, savedValues: any, changes: any) => {
    if (input.requires) {
        const split = input.requires.split(':');

        if (split.length > 2) {
            const requiredScope = [] as string[];
            const requiredValues = [] as string[];

            split.forEach((entry: any, idx: number) => {
                if ((idx % 2) === 0) {
                    requiredScope.push(entry);
                } else {
                    requiredValues.push(entry);
                }
            });

            const foundInput = requiredScope.map((reqScope: string, idx: number) => {
                let found = changes.find((c: any) => c.type === reqScope) as any;

                if (!found) {
                    if (!savedValues) {
                        return null;
                    }

                    found = savedValues.find((v: any) => v.type === reqScope) as any;
                    if (!found) {
                        return null;
                    }
                }

                return {
                    input: found,
                    requiredValue: requiredValues[idx].split('|')
                };
            }).filter((v) => v);

            return foundInput;
        }

        const requiredScope = split[0];
        const requiredValues = split[1].split('|');

        let found = changes.find((c: any) => c.type === requiredScope) as any;

        if (!found && savedValues) {
            found = savedValues.find((v: any) => v.type === requiredScope) as any;
        }

        if (found) {
            return [{
                input: found,
                requiredValue: requiredValues
            }];
        }

        return null;
    }
    return null;
}

export const checkScopeRequirements = (scope: any, savedValues: any, changes: any) => {
    if (!scope.requires) {
        return true;
    }
    const split = scope.requires.split(':');

    const checkIncludeGreaterThenOrLessThen = (reqValues: string[], input: any) => {
        const greaterThenPassed = [] as boolean[];
        reqValues.forEach((v, i) => {
            if (/>=?|=/.test(v)) {
                greaterThenPassed[i] = v.split('>')[1] <= (Array.isArray(input.value) ? input.value.length : input.value);
                return;
            }
            if (/<=?|=/.test(v)) {
                greaterThenPassed[i] = v.split('<')[1] >= (Array.isArray(input.value) ? input.value.length : input.value);
                return;
            }
            greaterThenPassed[i] = false;
        })
        return greaterThenPassed.every(Boolean);
    }

    const requiredScope = [] as string[];
    const requiredValues = [] as string[];

    split.forEach((entry: any, idx: number) => {
        if ((idx % 2) === 0) {
            requiredScope.push(entry);
        } else {
            requiredValues.push(entry);
        }
    });

    return requiredScope.every((reqScope: string, idx: number) => {
        const allChanges = [...savedValues || [], ...changes || []]
        const allChangesKeepLastOccurrence = allChanges.filter(
            (obj, index) =>
            allChanges.findLastIndex((item) => item.type === obj.type) === index && typeof obj === 'object'
        );

        return allChangesKeepLastOccurrence.some((c: any) => {
            if (c.type !== reqScope) {
                return false;
            }

            const reqValues = requiredValues[idx].split('|');
            const includesGreaterThenOrLessThen = reqValues.findIndex((v) => /[<>]=?|=/.test(v)) >= 0;

            if (includesGreaterThenOrLessThen) {
                return checkIncludeGreaterThenOrLessThen(reqValues, c);
            }

            return reqValues.includes(c.value.toString());
        })
    });
}

export const checkMandatoryInputs = 
    async (
        storageServ: IStorageService,
        eventId: string,
        regId: string,
        type: string,
        savedValues: any,
        changes: any
    ) => {
    let mandatoryInputs = [] as Array<TypeEvaluation>;
    let validForMark = true;

    const jsonnetObject = await getCurrentJsonnetFile(storageServ, eventId, regId);
    if (!jsonnetObject) {
        return { valid: false, missing: null };
    }

    if (jsonnetObject.type === 'working_test') {
        type = 'evaluations';
    }

    if (type === 'devidedFirst' || type === 'devidedSecond') {
        type = 'evaluation';
    }
    mandatoryInputs = jsonnetObject[type]
        .flatMap((block: any) =>
            block.inputs
                .filter((input: any) => input.mandatory === true && checkRequirementsForMandatory(block, savedValues, changes))
        );

    const missingInputs = [] as any;

    const pswmValues = (mandatoryInputs.find((v: any) => v.type === 'pswm') as any)?.values;
    if (pswmValues) {
        mandatoryInputs.push(...pswmValues);
    }

    const setNotValid = (val: any) => {
        validForMark = false;
        missingInputs.push(val);
    }

    mandatoryInputs.forEach((val: any) => {
        const cFound = changes.find((c: any) => c.type === val.scope) as any;

        if (cFound && Array.isArray(cFound.value)) {
            if (cFound.value.length === 0) {
                setNotValid(val);
            }


            if (cFound.type === 'litter') {
                cFound.value.forEach((v: any) => {
                    if (
                        v.litter_born === '' ||
                        v.litter_breed.length === 0 ||
                        (v.litter_num_female === 0 && v.litter_num_male === 0)
                    ) {
                        setNotValid(val);
                    }
                });
            }
        }

        if (!cFound) {
            if (!savedValues) {
                setNotValid(val);
                return;
            }

            const vFound = savedValues.find((v: any) => v.type === val.scope) as any;

            if (vFound && Array.isArray(vFound.value)) {
                if (vFound.value.length === 0) {
                    setNotValid(val);
                }

                if (vFound.type === 'litter') {
                    vFound.value.forEach((v: any) => {
                        if (
                            v.litter_born === '' ||
                            v.litter_breed.length === 0 ||
                            (v.litter_num_female === 0 && v.litter_num_male === 0)
                        ) {
                            setNotValid(val);
                        }
                    });
                }
            }

            if (!vFound || (vFound && vFound.value === '')) {
                setNotValid(val);
            }
        }

        if (cFound && cFound.value === '') {
            setNotValid(val);
        }
    })

    if (!validForMark) {
        return {
            valid: false, 
            missing: missingInputs
        };
    }

    return { valid: true, missing: [] };
}

export const getRawJsonnetFile = async (storageServ: IStorageService, eventId: string, regId: string): Promise<{[key: string]: any}> => {
    const event = getEvent(eventId);
    const registration = getRegistration(regId);

    if(!event || !registration) {
        throw new Error('Could not find event or registration');
    }

    const eventTypeToTestType: { [key: string]: string} = {
        'bird_skf': 'skf',
        'bird_fa': 'fa',
        'working_test': 'workingtest',
        'fa_highstatus': 'fa',
        'chase_basset': 'bassetchase',
        'chase_tax': 'taxchase',
        'chase_adb': 'adbchase',
        'hunting_tolling': 'huntingtolling',
        'hunting_tolling_practical': 'huntingtolling_practical',
        'hunting_spaniel_vatten': 'ssrkspan',
        'hunting_spaniel_nyborjare': 'ssrkspan',
        'hunting_spaniel_falt': 'ssrkspan',
        'hunting_spaniel_wt': 'ssrkspan',
        'hunting_retriever_a': 'ssrk',
        'hunting_retriever_b': 'ssrk',
        'hunting_mock_trial': 'huntingmock',
        'hunting_retriever_function': 'ssrkfunction',
        'hunting_tjtk': 'hunting_swk',
    };

    let json = null;
    const isSearch = event.categories.some((category) => category.value === 'Eftersök' && category.type === 'Provform')
    try {
        let testType = event.type
        // TEMP: While we dont supply it from kennelkonsulanterna backend
        if (testType === 'kennelvisit') {
            return kennelvisit;
        }
        if (eventTypeToTestType[event.type]) {
            testType = eventTypeToTestType[event.type];
        }
        if (event.type === 'bird_skf' && registration.class === 'SKL endast Breton' && !isSearch) {
            testType = 'fa';
        }
        const protocol = (await storageServ.getTestTypeByCode(testType))?.protocol;
        if (!protocol) {
            throw new Error('protocol not found');
        }
        json = JSON.parse(protocol);
    } catch (error: any) {
        const msg = error.message ? error.message : error;
        throw new Error(`Could not get current protocol: ${msg}`);
    }
    return json;
}

export const getEventJsonnetType = (json: any, eventId: string, regId: string) => {
    const event = getEvent(eventId);
    const registration = getRegistration(regId);

    if(!event || !registration) {
        throw new Error('Could not find event or registration');
    }
    const isSearch = event.categories.some((category) => category.value === 'Eftersök' && category.type === 'Provform')
    const deviationLibrary = {
        'bird_skf': () => {
            if (registration.class === 'SKL endast Breton' && !isSearch) {
                return json.highstatus.skl;
            }
            switch (registration.breed) {
                case 'BRAQUE FRANCAIS, TYPE PYRÉNÉES':
                case 'BRETON':
                case 'EPAGNEUL BRETON':
                    return json.breton
                case 'BRACCO ITALIANO':
                case 'SPINONE ITALIANO':
                case 'SPINONE':
                    if (registration.class.includes('EKL')) {
                        return json.brac_spin_ekl
                    }
                    return json.brac_spin
                case 'SLOVENSKÝ HRUBOSRSTY STAVAC (OHAR)':
                case 'GRIFFON D\'ARRET À POIL DUR/KORTHALS':
                case 'GRIFFON D\'ARRET A POIL DUR':
                case 'WEIMARANER, KORTHÅRIG':
                case 'WEIMARANER KURZHAARIG':
                case 'WEIMARANER, LÅNGHÅRIG':
                case 'WEIMARANER LANGHAARIG':
                    if (registration.class.includes('EKL') && event.categories.findIndex((categoryEntry) => categoryEntry.value.includes('Internationellt')) !== -1) {
                        return json.grif_weim_srhp_ekl_int;
                    } 
                    if (registration.class.includes('EKL') && event.categories.findIndex((categoryEntry) => categoryEntry.value.includes('Nationellt')) !== -1) {
                        return json.grif_weim_srhp_ekl_nat;
                    }
                    return json.grif_weim_srhp;
                default:
                    if (registration.class.includes('EKL')) {
                        return json.drent_gdh_stab_other_ekl;
                    }
                    return json.drent_gdh_stab_other;
            }
        },
        'bird_fa': () => {
            switch (registration.class) {
                case 'SKL endast Breton':
                case 'SKL':
                    return json.standard.skl;
                case 'UKL 9-24 mån': 
                    return json.standard.ukl;
                case 'Apport':
                    return json.standard.apport;
                case 'ÖKL över 9 mån':
                default:
                    return json.standard.okl;
            }
        },
        'fa_highstatus': () => {
            switch (registration.class) {
                case 'SKL Internationell':
                case 'SKL endast Breton':
                case 'SKL':
                    return json.highstatus.skl;
                case 'UKL 9-24 mån':
                default:
                    return json.highstatus.ukl;
            }
        },
        'hunting_retriever_a': () => {
            const test_type = event.categories.find((category) => category.type === 'Provslag')?.value;

            if (test_type === 'Nationellt') {
                switch (registration.class) {
                    case 'ÖKL':
                        return json.type_a.okl_nat;
                    case 'EKL':
                        return json.type_a.ekl_nat;
                    default:
                        return json.type_a.okl_nat;
                }
            }
            return json.type_a.ekl_int
        },
        'hunting_retriever_b': () => json.type_b,
        'hunting_spaniel_vatten': () => json.water,
        'hunting_spaniel_nyborjare': () => json.beginner,
        'hunting_spaniel_falt': () => {
            switch (registration.class) {
                case 'Öppenklass Enkelsläpp':
                    return json.okl
                case 'Öppenklass Parsläpp':
                    return json.okl_pair
                case 'Segrarklass Enkelsläpp':
                    return json.skl
                case 'Segrarklass Parsläpp':
                    return json.skl_pair
                default:
                    return json.skl;
            }
        },
        'hunting_spaniel_wt': () => json.working,
        'bloodtracking': () => json.evaluation,
        'chase': () => json.evaluation,
        'chase_tax': () => json.evaluation,
        'chase_basset': () => json.evaluation,
        'chase_adb': () => json.evaluation,
        'hunting_swk_hunting': () => {
            switch (registration.class) {
                case 'Öppen klass':
                    return json.okl
                default:
                    return json.other
            }
        },
    } as { [key: string]: () => { [key: string]: any } }

    if (deviationLibrary[event.type]) {
        return deviationLibrary[event.type]() as { [key: string]: any };
    }
    return json;
}

export const getCurrentJsonnetFile = async (storageServ: IStorageService, eventId: string, regId: string): Promise<{[key: string]: any}> => {
    const event = getEvent(eventId);
    const registration = getRegistration(regId);

    if(!event || !registration) {
        throw new Error('Could not find event or registration');
    }

    let json = null;
    try {
        json = await getRawJsonnetFile(storageServ, eventId, regId);
    } catch (error: any) {
        const msg = error.message ? error.message : error;
        throw new Error(`Could not get raw protocol: ${msg}`);
    }

    const currentEventJsonnet = getEventJsonnetType(json, eventId, regId);

    return currentEventJsonnet as { [key: string]: any };
}
