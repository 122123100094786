import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "info-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { style: {"text-transform":"capitalize"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_avatar, {
      color: "surface-variant",
      size: "100",
      style: {"margin-bottom":"20px","font-size":"50px"}
    }, {
      default: _withCtx(() => [
        (_ctx.showStartOrder)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.reg.start_order + 1), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.reg.title), 1),
    (_ctx.reg)
      ? (_openBlock(), _createBlock(_component_v_container, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  align: "center",
                  class: "info-col"
                }, {
                  default: _withCtx(() => [
                    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "category-span" }, "Prov-ID", -1)),
                    _createElementVNode("h4", null, "#" + _toDisplayString(_ctx.getEventId(_ctx.event)), 1),
                    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "category-span" }, "Plats", -1)),
                    _createElementVNode("h4", null, _toDisplayString(_ctx.getEventTitleInfo(_ctx.event.title).eventLocation), 1),
                    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "category-span" }, "Datum", -1)),
                    _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.getEventDate(_ctx.event.ts)), 1),
                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "category-span" }, "Kategorier", -1)),
                    _createElementVNode("h4", null, _toDisplayString(_ctx.getEventCategoriesString(_ctx.event.categories)), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  align: "center",
                  class: "info-col"
                }, {
                  default: _withCtx(() => [
                    _cache[6] || (_cache[6] = _createElementVNode("span", { class: "category-span" }, "Ras", -1)),
                    _createElementVNode("h4", null, _toDisplayString(_ctx.reg.breed), 1),
                    (_ctx.showClass)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "category-span" }, "Klass", -1)),
                          _createElementVNode("h4", null, _toDisplayString(_ctx.reg.class), 1)
                        ], 64))
                      : _createCommentVNode("", true),
                    _cache[7] || (_cache[7] = _createElementVNode("span", { class: "category-span" }, "Kön", -1)),
                    _createElementVNode("h4", null, _toDisplayString(_ctx.reg.gender === 'F' ? 'T' : 'H'), 1),
                    (_ctx.reg.chip)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "category-span" }, "Chipnummer", -1)),
                          _createElementVNode("h4", null, _toDisplayString(_ctx.reg.chip), 1)
                        ], 64))
                      : _createCommentVNode("", true),
                    _cache[8] || (_cache[8] = _createElementVNode("span", { class: "category-span" }, "Reg. nummer", -1)),
                    _createElementVNode("h4", null, _toDisplayString(_ctx.reg.regnum), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.showShooting)
              ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      align: "center",
                      cols: "6",
                      class: "info-col"
                    }, {
                      default: _withCtx(() => [
                        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "category-span" }, "GK SKOTTPRÖVNING GENOMFÖRD", -1)),
                        _createElementVNode("h4", null, _toDisplayString(_ctx.getWordValue(_ctx.reg.shooting?.test_approved)), 1)
                      ]),
                      _: 1
                    }),
                    (_ctx.showShootingInfo)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 0,
                          align: "center",
                          cols: "6",
                          class: "info-col"
                        }, {
                          default: _withCtx(() => [
                            _cache[10] || (_cache[10] = _createElementVNode("span", { class: "category-span" }, "DATUM", -1)),
                            _createElementVNode("h4", null, _toDisplayString(_ctx.reg.shooting?.test_date), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  align: "center",
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _cache[11] || (_cache[11] = _createElementVNode("span", { class: "category-span" }, "Ägare / förare", -1)),
                    _createElementVNode("h4", null, _toDisplayString(_ctx.reg.ownerName), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  align: "center",
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _cache[12] || (_cache[12] = _createElementVNode("span", { class: "category-span" }, "Mobilnummer", -1)),
                    _createElementVNode("h4", null, _toDisplayString(_ctx.reg.ownerPhoneNumber), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}