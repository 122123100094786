import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_ctx.currReg)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_btn, {
          color: _ctx.isNoShow ? 'error' : _ctx.getColor(_ctx.controlPassed >= 0 ? _ctx.controlPassed : _ctx.hasEval.control),
          "prepend-icon": _ctx.getIcon(_ctx.controlPassed >= 0 ? _ctx.controlPassed : _ctx.hasEval.control),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-control')))
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode(" FÖRHANDSKONTROLL ")
          ])),
          _: 1
        }, 8, ["color", "prepend-icon"]),
        _createVNode(_component_v_btn, {
          color: _ctx.getColor(_ctx.hasEval.conditions_tracks),
          "prepend-icon": _ctx.getIcon(_ctx.hasEval.conditions_tracks),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click-bed', 'conditions_tracks')))
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode(" FÖRUTSÄTTNINGAR - SPÅRLÄGGNING ")
          ])),
          _: 1
        }, 8, ["color", "prepend-icon"]),
        _createVNode(_component_v_btn, {
          color: _ctx.getColor(_ctx.hasEval.conditions_event),
          "prepend-icon": _ctx.getIcon(_ctx.hasEval.conditions_event),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('click-bed', 'conditions_event')))
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode(" FÖRUTSÄTTNINGAR - PROVTILLFÄLLE ")
          ])),
          _: 1
        }, 8, ["color", "prepend-icon"]),
        _createVNode(_component_v_btn, {
          disabled: _ctx.controlNotPassed,
          color: _ctx.getColor(_ctx.hasEval.steps),
          "prepend-icon": _ctx.getIcon(_ctx.hasEval.steps),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({
                name: 'bloodtracking'
            })))
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode(" STARTA SPÅR ")
          ])),
          _: 1
        }, 8, ["disabled", "color", "prepend-icon"]),
        _createVNode(_component_v_btn, {
          disabled: _ctx.controlNotPassed,
          color: _ctx.getColor(_ctx.hasEval.registration),
          "prepend-icon": _ctx.getIcon(_ctx.hasEval.registration),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('click-bed', 'registration')))
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode(" BEDÖMNING ")
          ])),
          _: 1
        }, 8, ["disabled", "color", "prepend-icon"])
      ]))
    : _createCommentVNode("", true)
}