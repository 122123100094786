import { BehaviorSubject } from 'rxjs';
import { getCurrentInstance } from 'vue';
import { ISQLiteService } from '@/services/sqliteService';
import { IDbVersionService } from '@/services/dbVersionService';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { TestTypeUpgradeStatements } from '@/upgrades/test_types.upgrade.statements';
import { TestType } from '@/models/TestType';

export interface IStorageService {
    initializeDatabase(): Promise<void>
    getTestTypes(): Promise<TestType[]>
    getTestTypeVersions(): Promise<{code: string, version: number}[]|null>
    getTestTypeByCode(code: string): Promise<TestType|null>
    addTestType(test_type: TestType): Promise<number>
    updateTestTypeByCode(code: string, protocol: any, version: number): Promise<void>
    getDatabaseName(): string,
    getDatabaseVersion(): number
};
class StorageService implements IStorageService {
    versionUpgrades = TestTypeUpgradeStatements;
    loadToVersion = TestTypeUpgradeStatements[TestTypeUpgradeStatements.length -1].toVersion;
    db!: SQLiteDBConnection;
    database: string = 'dogappdb';
    sqliteServ!: ISQLiteService;
    dbVerServ!: IDbVersionService;
    isInitCompleted = new BehaviorSubject(false);
    appInstance = getCurrentInstance();
    platform!: string;

    constructor(sqliteService: ISQLiteService, dbVersionService: IDbVersionService) {
        this.sqliteServ = sqliteService;
        this.dbVerServ = dbVersionService;
        this.platform = this.appInstance?.appContext.config.globalProperties.$platform;
    }

    getDatabaseName(): string {
        return this.database;
    }
    getDatabaseVersion(): number {
        return this.loadToVersion;
    }

    async initializeDatabase(): Promise<void> {
        try {
            await this.sqliteServ.addUpgradeStatement({
                database: this.database,
                upgrade: this.versionUpgrades
            });
            this.db = await this.sqliteServ.openDatabase(this.database, this.loadToVersion, false);
            this.dbVerServ.setDbVersion(this.database, this.loadToVersion);
            if (this.platform === 'web') {
                await this.sqliteServ.saveToStore(this.database);
            }
            this.isInitCompleted.next(true);
        } catch (error: any) {
            const msg = error.message ? error.message : error;
            throw new Error(`storageService.initializeDatabase: ${msg}`);
        }
    }

    async getTestTypes(): Promise<TestType[]> {
        return (await this.db.query('SELECT * FROM test_types;')).values as TestType[];
    }

    async getTestTypeVersions(): Promise<{code: string, version: number}[]|null> {
        const values = (await this.db.query('SELECT code, version FROM test_types;')).values;
        if (!values || !values.length) {
            return null;
        }
        return values;
    }

    async getTestTypeByCode(code: string): Promise<TestType|null> {
        const sql = `SELECT * FROM test_types WHERE code=?;`;
        const query = (await this.db.query(sql, [code])).values;
        if (query && query.length) {
            return query[0] as TestType;
        }
        return null;
    }

    async addTestType(test_type: TestType): Promise<number> {
        const sql = `INSERT INTO test_types (code, protocol, version) VALUES (?, ?, ?);`
        const res = await this.db.run(sql, [test_type.code, test_type.protocol, test_type.version]);
        if (res.changes !== undefined && res.changes.lastId !== undefined && res.changes.lastId > 0) {
            return res.changes.lastId;
        }
        throw new Error('storageService.addTestType: lastId not returned');
    }

    async updateTestTypeByCode(code: string, protocol: string, version: number): Promise<void> {
        const sql = `UPDATE test_types SET protocol=?, version=? WHERE code=?;`;
        await this.db.run(sql, [protocol, version, code]);
    }
}
export default StorageService;
