import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_btn, {
      color: _ctx.getColor(_ctx.hasEval.introduction, true),
      "prepend-icon": _ctx.getIcon(_ctx.hasEval.introduction),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-bed', 'introduction')))
    }, {
      default: _withCtx(() => _cache[8] || (_cache[8] = [
        _createTextVNode(" INTRODUKTION ")
      ])),
      _: 1
    }, 8, ["color", "prepend-icon"]),
    _createVNode(_component_v_btn, {
      disabled: _ctx.isNoShow.no_show,
      color: _ctx.getColor(_ctx.hasEval.dogs),
      "prepend-icon": _ctx.getIcon(_ctx.hasEval.dogs),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click-bed', 'dogs')))
    }, {
      default: _withCtx(() => _cache[9] || (_cache[9] = [
        _createTextVNode(" HUNDARNA OCH AVTALEN ")
      ])),
      _: 1
    }, 8, ["disabled", "color", "prepend-icon"]),
    _createVNode(_component_v_btn, {
      disabled: _ctx.isNoShow.no_show,
      color: _ctx.getColor(_ctx.hasEval.litter),
      "prepend-icon": _ctx.getIcon(_ctx.hasEval.litter),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('click-bed', 'litter')))
    }, {
      default: _withCtx(() => _cache[10] || (_cache[10] = [
        _createTextVNode(" VALPKULL ")
      ])),
      _: 1
    }, 8, ["disabled", "color", "prepend-icon"]),
    _createVNode(_component_v_btn, {
      disabled: _ctx.isNoShow.no_show,
      color: _ctx.getColor(_ctx.hasEval.indoor_spaces),
      "prepend-icon": _ctx.getIcon(_ctx.hasEval.indoor_spaces),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('click-bed', 'indoor_spaces')))
    }, {
      default: _withCtx(() => _cache[11] || (_cache[11] = [
        _createTextVNode(" INOMHUSUTRYMMEN ")
      ])),
      _: 1
    }, 8, ["disabled", "color", "prepend-icon"]),
    _createVNode(_component_v_btn, {
      disabled: _ctx.isNoShow.no_show,
      color: _ctx.getColor(_ctx.hasEval.restyard),
      "prepend-icon": _ctx.getIcon(_ctx.hasEval.restyard),
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('click-bed', 'restyard')))
    }, {
      default: _withCtx(() => _cache[12] || (_cache[12] = [
        _createTextVNode(" UTOMHUSUTRYMMEN ")
      ])),
      _: 1
    }, 8, ["disabled", "color", "prepend-icon"]),
    _createVNode(_component_v_btn, {
      disabled: _ctx.isNoShow.no_show,
      color: _ctx.getColor(_ctx.hasEval.other),
      "prepend-icon": _ctx.getIcon(_ctx.hasEval.other),
      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('click-bed', 'other')))
    }, {
      default: _withCtx(() => _cache[13] || (_cache[13] = [
        _createTextVNode(" ÖVRIGT ")
      ])),
      _: 1
    }, 8, ["disabled", "color", "prepend-icon"]),
    _createVNode(_component_v_btn, {
      disabled: _ctx.isNoShow.no_show && _ctx.isNoShow.hideRevisit,
      color: _ctx.getColor(_ctx.hasEval.follow_up, !_ctx.isNoShow.hideRevisit),
      "prepend-icon": _ctx.getIcon(_ctx.hasEval.follow_up),
      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$router.push({
            name: 'kennelvisit_follow_up'
        })))
    }, {
      default: _withCtx(() => _cache[14] || (_cache[14] = [
        _createTextVNode(" UPPFÖLJNING ")
      ])),
      _: 1
    }, 8, ["disabled", "color", "prepend-icon"]),
    _createVNode(_component_v_btn, {
      color: _ctx.getColor(_ctx.hasEval.visit, true),
      "prepend-icon": _ctx.getIcon(_ctx.hasEval.visit),
      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('click-bed', 'visit')))
    }, {
      default: _withCtx(() => _cache[15] || (_cache[15] = [
        _createTextVNode(" BESÖKET ")
      ])),
      _: 1
    }, 8, ["color", "prepend-icon"]),
    _createVNode(_component_v_divider, { class: "mt-2 mb-5" })
  ], 64))
}