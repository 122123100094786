import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReleasePanel = _resolveComponent("ReleasePanel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, { class: "pa-6" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_form, {
        ref: "letGosForm",
        "validate-on": "input",
        onSubmit: _withModifiers(_ctx.saveReleases, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_expansion_panels, {
            modelValue: _ctx.openPanel,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openPanel) = $event))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.letGos, (release, index) => {
                return (_openBlock(), _createBlock(_component_ReleasePanel, {
                  key: release.id,
                  release: release,
                  index: index,
                  "open-panel": _ctx.openPanel,
                  rules: _ctx.rules,
                  "loss-time": _ctx.lossTime,
                  animals: _ctx.getAnimals,
                  "event-type": _ctx.eventType,
                  "event-is-tax-chase": _ctx.eventIsTaxChase,
                  "convert-to-secs": _ctx.convertToSecs,
                  onRemoveLetGo: _ctx.removeLetGo,
                  onCheckIfEmpty: _ctx.checkIfEmpty,
                  onChangeUptake: _ctx.changeUptake
                }, null, 8, ["release", "index", "open-panel", "rules", "loss-time", "animals", "event-type", "event-is-tax-chase", "convert-to-secs", "onRemoveLetGo", "onCheckIfEmpty", "onChangeUptake"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_btn, {
            class: "mt-8",
            block: "",
            color: "info",
            onClick: _ctx.addLetGo
          }, {
            default: _withCtx(() => [
              _cache[2] || (_cache[2] = _createTextVNode(" Lägg till släpp ")),
              _createVNode(_component_v_icon, { class: "ml-1" }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" mdi-plus ")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_v_btn, {
            class: "mt-2",
            type: "submit",
            block: "",
            color: "success",
            loading: _ctx.validating
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" Spara ")
            ])),
            _: 1
          }, 8, ["loading"])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }))
}